// componente para seleccionar el suplidor

import { useEffect, useState } from "react";

// app icon import
import { AddDbIcon, Close } from "../icons/AppIcons.jsx";

// urls importation
import { urls } from "../../api-urls/api-urls.js";
import { useTranslation } from "react-i18next";




export const SearchSupplier = ({ onClose, CodSol = "", lang = "", token = "", onAddSupp }) => {

  // state definition
  const [state, setState] = useState({ isLoading: false, nombre: "" });
  const [controller, setController] = useState(null); //
  const [suppliers, setSuppliers] = useState([]); // Estado para cargar la lista de los suplidores

  // GET the react translation
  const [t] = useTranslation("global");


  const FilterSupplier = suppliers && suppliers.filter((supp) => supp.Nombre.toLowerCase().includes(state?.nombre.toLowerCase()));



  // funcion para agregar el suplidor
  const onAddSupplier = (Supp = 0) => {
    const FindSupp = suppliers && suppliers.find((supp) => supp.CodProveedor === Supp);

    onAddSupp(FindSupp?.CodProveedor, FindSupp?.Nombre);

    onClose();
  };


  // funcion para realizar el fetch de los datos
  const fetchDataServ = async () => {
    const abortContr = new AbortController();

    setController(abortContr);

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.orderDetail}/${CodSol}/search?language=${lang}&act=1`, {
        headers: {
          "Authorization": token,
          "Content-Type": "application/json"
        },
        signal: abortContr.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setSuppliers(data?.suppliers);
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };

  // useEffect para solicitar la data al servidor
  useEffect(() => {

    fetchDataServ();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [CodSol, lang]);


  return (
    <div className="background-modal" onClick={onClose}>
      <div className="container-select-customer" onClick={(event) => event.stopPropagation()}>
        <div className="modal-header">
          <h3>{t("LogisticViewComp.buscar_supp")}</h3>
          <button type="button" className="btn-close" onClick={onClose}>
            <Close />
          </button>
        </div>
        <div className="modal-body">
          {
            state.isLoading && (
              <strong>{t("PartModify.cargando_info")}</strong>
            )
          }
          <div className="row-flex">
            <div className="col-7">
              <strong>{t("serviceCard.nombre")}</strong>
            </div>
            <div className="col-80 flex-grow">
              <input
                className="input-text-control"
                type="text"
                name="nameSupp"
                value={state.nombre}
                onChange={({ target }) => setState((val) => ({ ...val, nombre: target.value }))}
                autoComplete="off" />
            </div>
          </div>

          {
            FilterSupplier && FilterSupplier.map((supp) => (
              <div key={supp.CodProveedor} className="row-flex hover-class border-bottom" onClick={() => onAddSupplier(supp.CodProveedor)} style={{ cursor: "pointer" }}>
                <div className="col-80 flex-grow">
                  <strong>{supp.Nombre}</strong>
                </div>
              </div>
            ))
          }
        </div>
        <div className="modal-footer j-content-space-around">
          <button type="button" className="btn-secondary" onClick={onClose}>{t("customerView.btnClose")}</button>
        </div>
      </div>
    </div>
  );
};