
const domain = process.env.NODE_ENV === "development" ? "http://localhost:4003" : "https://www.global-medica.net";

// const domain = "https://www.global-medica.net";

// http://localhost:4001
// https://global-medica.net


export const urls = {
  authRoute: `${domain}/api/v1/auth/login`,
  homePage: `${domain}/api/v1/home-general-info`,
  employees: `${domain}/api/v1/employees`,
  initialValCreateCustomer: `${domain}/api/v1/initial-values-create-customer`,
  getCustomer: `${domain}/api/v1/customers`,
  calls: `${domain}/api/v1/service`,
  equipments: `${domain}/api/v1/equipments`,
  contactsCustomers: `${domain}/api/v1/contact-customers`,
  contractsCustomers: `${domain}/api/v1/contract-customers`,
  serviceCallDetail: `${domain}/api/v1/service-call-detail/`,
  customerDetail: `${domain}/api/v1/customer-detail/`,
  customerOpenServiceCall: `${domain}/api/v1/customer-open-service-call`,
  equipmentsById: `${domain}/api/v1/equipments-open-service-call`,
  stepChangeCalls: `${domain}/api/v1/change-step-call`, // esta ruta es para cambiar las etapas de las llamadas
  logisticPrincipal: `${domain}/api/v1/principal-logistic`,
  solRepuestoById: `${domain}/api/v1/sol-repuesto-by-id`, // la estructura es /api/v1/sol-repuesto-by-id?solRepuesto=12526&idEtapa=13
  partDetail: `${domain}/api/v1/part-id`, //la estructura es /api/v1/part-id?solRepuesto=112554&codDetSolRepuesto=1
  orderDetail: `${domain}/api/v1/order-detail`, // la estructura es /api/v1/order-detail?solRepuesto=1255,
  requestDetail: `${domain}/api/v1/request-part`, // la estructura es /api/v1/request-part-detail?solRepuesto=12545
  orderTrackingUpdate: `${domain}/api/v1/order-tracking`,
  dataSupplier: `${domain}/api/v1/data-supplier`,
  getFollowUpOrders: `${domain}/api/v1/orders-follow-up`,
  logisticReports: `${domain}/api/v1/logistic-reports`,
  getPedingAdministraction: `${domain}/api/v1/administration-pending`,
  getLisProposal: `${domain}/api/v1/post-sales/proposals`,
  getWarehousePrincipal: `${domain}/api/v1/warehouse-principal`,
  getListQuotations: `${domain}/api/v1/quotations-principal`,
  getPurchaseOrderCommercial: `${domain}/api/v1/comercial/purcharse-order`,
  technicalApproval: `${domain}/api/v1/technical-approvals`,
  projectsCommercial: `${domain}/api/v1/commercial-projects`,
  fmi: `${domain}/api/v1/fmi`,
  commercialRequest: `${domain}/api/v1/request-commercial-orders`,
  tools: `${domain}/api/v1/warehouse-tools`,
  serviceReport: `${domain}/api/v1/service-report`,
  historyExpenses: `${domain}/api/v1/history-expenses`,
  divisions: `${domain}/api/v1/data/division`,
  serviceScheduling: `${domain}/api/v1/service-scheduling`,
  supportApproval: `${domain}/api/v1/zone-support-approval`,
  administrativeAppro: `${domain}/api/v1/administrative-approvals`,
  priceRequest: `${domain}/api/v1/price-request`,
  partArrivalWarehouse: `${domain}/api/v1/parts-arrival-warehouse`,
  dataShipTo: `${domain}/api/v1/ship-to-data`,
  notRetiro: `${domain}/api/v1/request-withdrawal`,
  commercialOrderProj: `${domain}/api/v1/commercial-orders-projects`,
  notaEntrega: `${domain}/api/v1/delivery-notes`,
  confirmRetiroPart: `${domain}/api/v1/confirm-withdrawal-part`,
  anticipo: `${domain}/api/v1/travel-expenses`,
  anticipoApprove: `${domain}/api/v1/travel-expenses-approve`,
  relacionGasto: `${domain}/api/v1/expense-report`,
  myTasks: `${domain}/api/v1/my-tasks`,
  myTasksStatistics: `${domain}/api/v1/my-tasks/statistics`,
  solDespacho: `${domain}/api/v1/request-delivery`,
  geneEquipDocs: `${domain}/api/v1/equipment-generate-docs`,
  getEqpSigned: `${domain}/api/v1/equipment-generate-docs/docs-signed`,
  createPOD: `${domain}/api/v1/create-pod-equipment`,
  createEquipment: `${domain}/api/v1/create-equipment`,
  devolParte: `${domain}/api/v1/return-service-part`,
  autorizaciones: `${domain}/api/v1/administration-authorizations`,
  permissions: `${domain}/api/v1/permissions-user`,
  getDataCA: `${domain}/api/v1/get-ca-data`,
  getCommercialReports: `${domain}/api/v1/commercial-reports`,
  paymentExpenses: `${domain}/api/v1/payment-expenses`,
  requestTools: `${domain}/api/v1/request-tools`,
  toolsApproval: `${domain}/api/v1/tools-approval`,
  toolExitWarehouse: `${domain}/api/v1/tool-exit-warehouse`,
  RHPendings: `${domain}/api/v1/human-resource-pendings`,
  vacations: `${domain}/api/v1/human-resource/vacations`,
  getVersion: `${domain}/api/v1/auth/version`,
  serviceCallObj: `${domain}/api/v1/service-call`,
  roomReserv: `${domain}/api/v1/room-reservation`
};