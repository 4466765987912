
// ESTA VISTA SE UTILIZA PARA CAMBIAR LAS ORDENES DE COMPRA CUANDO YA ESTAN EN SEGUIMIENTO DE ORDENES
import React, { useEffect, useState, useContext } from "react";

// REACT ROUTER DOM IMPORTATION
import { Link, useNavigate, useParams } from "react-router-dom";

// COMPONENTS IMPORTATION
import { ToastNotification } from "../../components/toast-notification/ToastNotification";
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";

// URLS IMPORTATIONS
import { urls } from "../../api-urls/api-urls";

// GLOBAL FUNCTIONS
import { getTheToken } from "../../global-functions/globalFunctions";

// React translation importation
import { useTranslation } from "react-i18next";


// context imports
import { NotificationContext } from "../../context/NotificationContext.jsx";

// custom hook import
import { useFetchData } from "../../components/customs/order-tracker/useFetchData.js";





export const OrderTracker = () => {
  // language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the react translation
  const [t] = useTranslation("global");

  // navigate
  const navigate = useNavigate();

  // notification context
  const { handleNotify } = useContext(NotificationContext);


  // GET the useParams
  const { orderNum, idEtapa } = useParams();


  const END_POINT = `${urls.orderTrackingUpdate}/${orderNum}/${idEtapa}`;

  // custom hook import
  const { zonas, state, form, partes, onHandleChangeCant, handleChangeForm, handleClearForm, handleChangeSend, handleReceiveStep, handleReloadData } = useFetchData(END_POINT, "GET", orderNum, idEtapa);


  // console.log(partsOrder)

  // objeto para generar los nombres de las etapas
  const STEP_NAME = {
    "30": t("RequestPartsView.acu_recibo"),
    "31": t("RequestPartsView.guia_desp"),
    "33": t("RequestPartsView.recep_miami"),
    "34": t("RequestPartsView.desp_clien"),
    "35": t("RequestPartsView.nacio_cliente"),
    "36": t("RequestPartsView.fecha_desp"),
    "37": t("RequestPartsView.transito"),
    "38": t("RequestPartsView.nacionali"),
    "48": t("RequestPartsView.transito_gmdh")
  };




  // ON SUBMIT ORDER
  const onSubmitUpdateOrderTracking = async (event) => {
    event.preventDefault();

    if (form.action.trim() === "") {
      return handleNotify(true, t("RequestPartsView.selec_accion"), true);
    }

    // Verificar que si la etapa es 36 y la acción es transito, la fecha no puede estar vacia
    if (form.action.trim() === "1" && idEtapa === "36" && !form.fechaDesp) {
      return handleNotify(true, t("RequestPartsView.indicar_fec_desp"), true);
    }

    if (form.action.trim() === "1" && idEtapa === "36" && !form.fecEstim) {
      return handleNotify(true, t("RequestPartsView.indicar_fec_esti"), true);
    }

    // Verificar que si la accion es 1 y la etapa es 30 el so no puede estar vacio
    if (idEtapa === "30" && form.action.trim() === "1" && !form.SO.trim()) {
      return handleNotify(true, "Debe indicar cual es el SO", true);
    }

    // Verificar que si la etapa es 30 y se quiere rechazar la parte debe tener una justificacion
    if (idEtapa === "30" && form.action.trim() === "101" && form.comentario.trim().length < 5) {
      return handleNotify(true, "Debe indicar una justificacion para rechazar la parte", true);
    }

    const END_POINT = `${urls.orderTrackingUpdate}/${orderNum}/${idEtapa}/search?language=${language}`;

    const finalObj = { form, partes };

    try {
      handleChangeSend(true);
      // enviar los datos al servidor para actualizar
      const response = await fetch(END_POINT, {
        method: "PUT",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(finalObj)
      });

      const data = await response.json();

      if (response.status === 200) {
        handleClearForm();
        handleNotify(true, data?.msg, false, true);
        handleReceiveStep(data?.etapa);
        handleReloadData(true);
      }

      handleChangeSend(false);

    } catch (error) {
      console.log(error);
    }
  };



  return (
    <>
      {
        state.isLoading && (<LoadingComp />)
      }
      <div className="container display-data">
        <form onSubmit={onSubmitUpdateOrderTracking}>
          <div className="m-y-small">
            <p><strong>{t("RequestPartsView.eta_actual")}:</strong><strong className="active-class"> {STEP_NAME[idEtapa] ?? "N/A"}</strong></p>
          </div>
          <div className="card-shadow">
            {/* FILA 1 */}
            <div className="row-flex">
              {/*  */}
              <div className="col-7 col-sm-30">
                <strong>{t("RequestPartsView.accion")}</strong>
              </div>
              <div className="col-4 col-sm-70-mod">
                <select
                  name="GuiaAereaEtapa"
                  value={form.action}
                  onChange={({ target }) => handleChangeForm("action", target.value)}>
                  <option value=""></option>
                  {
                    // guia aerea
                    (partes && partes[0]?.IdEtapa === 31) && (
                      <>
                        <option value="36">{t("RequestPartsView.fecha_desp")}</option>
                        <option value="30">{t("RequestPartsView.acu_recibo")}</option>
                      </>
                    )
                  }

                  {
                    // fecha de despacho
                    (partes && partes[0]?.IdEtapa === 36) && (
                      <>
                        <option value="1">{t("RequestPartsView.transito")}</option>
                        <option value="6">{t("RequestPartsView.guia_desp")}</option>
                      </>
                    )
                  }
                  {
                    // Nacionalizacion
                    (partes && partes[0]?.IdEtapa === 38) && (
                      <>
                        <option value="1">{t("RequestPartsView.por_lleg_alma")}</option>
                        <option value="6">
                          {partes[0]?.CodZona === zonas.GMDH ? t("RequestPartsView.transito_gmdh") : t("RequestPartsView.transito")}
                        </option>
                      </>
                    )
                  }

                  {
                    // Transito
                    (partes && partes[0]?.IdEtapa === 37) && (
                      <>
                        <option value="1">
                          {
                            partes && [zonas.CARIBE, zonas.GMDH].includes(partes[0]?.CodZona) && (
                              t("RequestPartsView.recep_miami")
                            )
                          }
                          {
                            partes && partes[0]?.CodZona === zonas.RD && (
                              t("RequestPartsView.nacionali")
                            )
                          }
                        </option>
                        <option value="6">{t("RequestPartsView.fec_despa_act")}</option>
                      </>
                    )
                  }

                  {
                    // Acuse de recibo
                    (partes && partes[0]?.IdEtapa === 30) && (
                      <>
                        <option value="1">{t("RequestPartsView.guia_desp")}</option>
                        <option value="101">{t("RequestPartsView.recha_parte")}</option>
                        <option value="2">{t("RequestPartsView.compra_local")}</option>
                        <option value="6">{t("RequestPartsView.orden_compra")}</option>
                      </>
                    )
                  }

                  {
                    // Etapa de Recepción en Miami
                    (partes && partes[0]?.IdEtapa === 33) && (
                      <>
                        <option value="1">
                          {partes[0]?.CodZona === zonas.GMDH ? t("RequestPartsView.transito_gmdh") : t("RequestPartsView.desp_clien")}
                        </option>
                        <option value="2">{t("RequestPartsView.transito")}</option>
                      </>
                    )
                  }
                  {
                    // Etapa de Despachado al cliente
                    (partes && partes[0]?.IdEtapa === 34) && (
                      <>
                        <option value="1">{t("RequestPartsView.nacio_cliente")}</option>
                        <option value="2">{t("RequestPartsView.recep_miami")}</option>
                      </>
                    )
                  }
                  {
                    // Etapa de Nacionalización cliente
                    (partes && partes[0]?.IdEtapa === 35) && (
                      <>
                        <option value="1">{t("RequestPartsView.rec_clien")}</option>
                        <option value="2">{t("RequestPartsView.desp_clien")}</option>
                      </>
                    )
                  }
                  {
                    // Etapa de Transito a GMDH
                    (partes && partes[0]?.IdEtapa === 48) && (
                      <>
                        <option value="1">{t("RequestPartsView.nacionali")}</option>
                        <option value="2">{t("RequestPartsView.recep_miami")}</option>
                      </>
                    )
                  }
                  <option value="100">{t("RequestPartsView.comentario")}</option>
                </select>
              </div>
            </div>

            {/* FILA 2 */}
            <div className="row-flex">
              {/* INSTRUCCIONES DE DESPACHO */}
              <div className="col-7 col-sm-30">
                <strong>{t("RequestPartsView.instruc_despa")}</strong>
              </div>
              <div className="col-6 col-sm-70-mod">
                <span style={{ color: 'var(--danger-colo)', fontWeight: 'bold' }}>{partes && partes[0]?.Despacho}</span>
              </div>
            </div>

            {
              // mostrar campos para la recepcion de warehouse si es Caribe
              (partes && [zonas.CARIBE, zonas.GMDH].includes(partes[0]?.CodZona)) && (
                <>
                  {
                    // si está en transito
                    (partes && partes[0]?.IdEtapa === 37) && (
                      <div className="row-flex">
                        <div className="col-7 col-sm-30">
                          <strong>{t("RequestPartsView.wr_numero")}</strong>
                        </div>
                        <div className="col-4 col-sm-70-mod">
                          <input
                            className="input-text-control"
                            type="text"
                            name="numWR"
                            value={form.numWR}
                            onChange={({ target }) => handleChangeForm("numWR", target.value)}
                            autoComplete="off" />
                        </div>

                        <div className="col-8">
                          <strong>{t("RequestPartsView.dimensiones")}</strong>
                        </div>
                        <div className="col-7">
                          <input
                            className="input-text-control"
                            type="text"
                            name="dimen"
                            value={form.dimen}
                            onChange={({ target }) => handleChangeForm("dimen", target.value)} />
                        </div>

                        <div className="col-9">
                          <strong>{t("RequestPartsView.peso")}</strong>
                        </div>
                        <div className="col-7 flex-auto">
                          <input
                            className="input-text-control"
                            type="text"
                            name="peso"
                            value={form.peso}
                            onChange={({ target }) => handleChangeForm("peso", target.value)} />
                        </div>
                      </div>
                    )
                  }
                  {
                    // Si esta en la etapa de Recepcion en Miami
                    (partes && partes[0]?.IdEtapa === 33) && (
                      <div className="row-flex">
                        <div className="col-7 col-sm-30">
                          <strong>
                            {
                              partes[0]?.CodZona === zonas.GMDH ? t("RequestPartsView.guia_desp_gmdh") : t("RequestPartsView.guia_desp_cli")
                            }
                          </strong>
                        </div>
                        <div className="col-4 col-sm-70-mod">
                          <input
                            className="input-text-control"
                            type="text"
                            name="guiaDespClien"
                            value={form.guiDespCli}
                            onChange={({ target }) => handleChangeForm("guiDespCli", target.value)}
                            autoComplete="off" />
                        </div>
                        <div className="col-8">
                          <strong>Carrier</strong>
                        </div>
                        <div className="col-7">
                          <input
                            className="input-text-control"
                            type="text"
                            name="carrierClient"
                            value={form.carrCli}
                            onChange={({ target }) => handleChangeForm("carrCli", target.value)} />
                        </div>
                        <div className="col-7">
                          <strong>{t("RequestPartsView.fecha_desp")}</strong>
                        </div>
                        <div className="col-7 flex-grow">
                          <input
                            className="input-date-styles"
                            type="date"
                            name="fecDesCli"
                            value={form.fecDespCli}
                            onChange={({ target }) => handleChangeForm("fecDespCli", target.value)} />
                        </div>
                      </div>
                    )
                  }
                </>
              )
            }

            {
              // Guia aerea
              (partes && partes[0]?.IdEtapa === 31) && (
                <div className="row-flex">
                  {/* GUIA DE DESPACHO */}
                  <div className="col-7 col-sm-30">
                    <strong>{t("RequestPartsView.guia_desp")}</strong>
                  </div>
                  <div className="col-4 col-sm-70-mod">
                    <input
                      className="input-text-control"
                      name="guiaInputGuiaAereaEtapa"
                      type="text"
                      value={form.guiaAerea}
                      onChange={({ target }) => handleChangeForm("guiaAerea", target.value)}
                      autoComplete="off" />
                  </div>
                  {/* CARRIER */}
                  <div className="col-7 col-sm-30">
                    <strong>Carrier</strong>
                  </div>
                  <div className="col-4 col-sm-70-mod">
                    <input
                      className="input-text-control"
                      name="carrierInputGuiaAereaEtapa"
                      type="text"
                      value={form.carrier}
                      onChange={({ target }) => handleChangeForm("carrier", target.value)}
                      autoComplete="off" />
                  </div>
                </div>
              )
            }

            {
              // Fecha de despacho
              (partes && partes[0]?.IdEtapa === 36) && (
                <div className="row-flex">
                  {/* FECHA DE DESPACHO */}
                  <div className="col-7 col-sm-30">
                    <strong>{t("RequestPartsView.fec_despa")}</strong>
                  </div>
                  <div className="col-4 col-sm-70-mod">
                    <input
                      className="input-date-styles"
                      type="date"
                      name="fecDesp"
                      value={form.fechaDesp}
                      onChange={({ target }) => handleChangeForm("fechaDesp", target.value)} />
                  </div>
                  {/* FECHA ESTIMADA LLEGADA */}
                  <div className="col-5 col-sm-30">
                    <strong>{t("RequestPartsView.fec_estim_lle")}</strong>
                  </div>
                  <div className="col-4 col-sm-70-mod">
                    <input
                      className="input-date-styles"
                      type="date"
                      name="fecEstiLle"
                      value={form.fecEstim}
                      onChange={({ target }) => handleChangeForm("fecEstim", target.value)} />
                  </div>
                </div>
              )
            }

            {
              // Acuse de recibo
              (partes && partes[0]?.IdEtapa === 30) && (
                <div className="row-flex">
                  {/* SO input */}
                  <div className="col-7 col-sm-30">
                    <strong># SO / # SOA</strong>
                  </div>
                  <div className="col-4 col-sm-70-mod">
                    <input
                      className="input-text-control"
                      type="text"
                      name="soInpNum"
                      value={form.SO}
                      onChange={({ target }) => handleChangeForm("SO", target.value)}
                      autoComplete="off" />
                  </div>
                </div>
              )
            }

            {/* Comentarios */}
            <div className="row-flex">
              {/* COMENTARIO */}
              <div className="col-7 col-sm-30">
                <strong>{t("RequestPartsView.comentario")}</strong>
              </div>
              <div className="col-83-87 col-sm-70-mod flex-grow">
                <textarea
                  className="textarea-description"
                  name="textareaComentarioGuiaAereaEtapa"
                  value={form.comentario}
                  onChange={({ target }) => handleChangeForm("comentario", target.value)}
                  placeholder={t("RequestPartsView.si_tiene_comen")}
                  autoComplete="off" />
              </div>
            </div>
          </div>

          {/* TABLA PARA EL DETALLE DE LAS PARTES */}
          <div className="table-container-mobile m-y-medium-table">
            <table className="table">
              <thead className="table-head">
                <tr>
                  <th>{t("TableParts.orden")}</th>
                  <th>{t("TableParts.canti")}</th>
                  <th>{t("TableParts.parte")}</th>
                  <th>{t("TableParts.descrip")}</th>
                  <th>{t("TableParts.suplidor")}</th>
                  <th>{t("TableParts.canti")}</th>
                </tr>
              </thead>
              <tbody className="table-body">
                {
                  partes && partes.map((parts) => (
                    <tr key={parts.CodDetSolRepuesto}>
                      <td className="txt-center col-9">
                        <Link className="link-tag" to={`/logistic/order-detail-reader/${parts.NumOrden}`}>{parts.NumOrden}</Link>
                      </td>
                      <td style={{ textAlign: 'center' }}>{parts.Cantidad}</td>
                      <td><Link className="link-tag" to={`/part-detail/${parts.CodDetSolRepuesto}/${parts.CodSolRepuesto}`}>{parts.NumParte}</Link></td>
                      <td>{parts.Descripcion}</td>
                      <td>{parts.NomProveedor}</td>
                      <td className="txt-center">
                        {(parts.IdEtapa < 38) && parts.Cantidad}
                        {
                          (parts.IdEtapa === 38) && (
                            <input
                              className="input-text-control txt-center"
                              type="text"
                              name={parts.CodDetSolRepuesto}
                              value={parts.CantLlego}
                              onChange={({ target }) => onHandleChangeCant(parts.CodDetSolRepuesto, parts.CodSolRepuesto, target.value)} />
                          )
                        }
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>

          {/* BOTONES DE Accion */}
          <div className="row-flex j-content-space-around m-y-medium">
            <button type={state.saving ? "button" : "submit"} className={`btn-primary ${state.saving ? "disabled" : ""}`}>
              {state.saving ? t("serviceCard.guardando") : t("RequestPartsView.guar")}
            </button>
            <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>
              {t("navigationMenu.backBtn")}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};