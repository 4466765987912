import React, { useState, useContext } from "react";

// IMPORTATION OF REACT ROUTER DOM
import { useParams, useNavigate, Link } from "react-router-dom";

// COMPONENTS IMPORTATION
import { LoadingComp } from "../../components/Loading-comp/LoadingComp.jsx";
import { ModalConfirm } from "../../components/modal-confirm/ModalConfirm.jsx";


// context importation
import { NotificationContext } from "../../context/NotificationContext.jsx";
import { LangContext } from "../../context/LangContext.jsx";


// URLS IMPORTATION
import { urls } from "../../api-urls/api-urls.js";

// GLOBAL FUNCTIONS
import { formatVal, getTheToken, renderDate, renderIdTipSolicitud } from "../../global-functions/globalFunctions.js";

// React translation
import { useTranslation } from "react-i18next";
import { formatCurrency } from "../../global-functions/globalFunctions.js";


// useFetch custom hook
import { useFetchData } from "../../components/customs/part-view/useFetchData.js";





export const PartView = () => {

  // GET the useParams
  const { codDetSolR, solRepuesto } = useParams();


  // GET the navigate
  const navigate = useNavigate();


  // notification context
  const { handleNotify } = useContext(NotificationContext);

  // language context
  const { lang } = useContext(LangContext);



  // GET the react translation
  const [t] = useTranslation("global");


  // fetch data
  const { state, form, otherInf, tblDetSegRepuesto, firstInfo, onHandleChangeForm, onSaveInfoModal, onCloseModal, handleSendServer, handleReloadData } = useFetchData(`${urls.partDetail}/${solRepuesto}/${codDetSolR}/search?language=${lang}&flag=0`, "GET");

  // DESTRUCTURING DATA SERVER

  // desabilitar el input si exportado es true 
  const INPUT_DISABLED = state.exportado && otherInf.prof;



  // handleSubmit
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      handleSendServer(true);
      const response = await fetch(`${urls.partDetail}/${solRepuesto}/${codDetSolR}/search?language=${lang}&flag=0`, {
        method: "PUT",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(form)
      });

      const data = await response.json();

      if (response.status === 200) {
        onCloseModal();
        handleNotify(true, data?.msg, false, true);
        handleReloadData(true);
      } else if (response.status === 404) {
        onCloseModal();
        handleNotify(true, data?.msg, true);
      }

      handleSendServer();

    } catch (error) {
      console.log(error);
    }
  };




  return (
    <>
      {/* Loading component */}
      {state.isLoading && (<LoadingComp />)}
      <div className="container display-data">
        {
          state.dataVisible && (
            <>
              <form onSubmit={handleSubmit}>
                {/* FILA 1 */}
                <div className="row-flex">
                  {/* ORDEN DE COMPRA */}
                  <div className="col-7 col-sm-30">
                    <strong>{t("TableParts.orden_compra")}</strong>
                  </div>
                  <div className="col-5 col-sm-70-mod">
                    <Link className="link-tag">
                      <h3 style={{ color: 'blue' }}>{firstInfo && firstInfo[0]?.NumOrden}</h3>
                    </Link>
                  </div>
                  {/* SOLICITUD */}
                  <div className="col-7 col-sm-30">
                    <strong>{t("TableParts.solicitud")}</strong>
                  </div>
                  <div className="col-7 col-sm-70-mod">
                    <Link className="link-tag" to={`/request-part-detail/${firstInfo && firstInfo[0]?.CodSolRepuesto}`}>
                      <h3 style={{ color: 'blue' }}>{firstInfo && firstInfo[0]?.CodSolRepuesto}</h3>
                    </Link>
                  </div>
                  {/* FECHA HORA */}
                  <div className="col-7 col-sm-30">
                    <strong>{t("RequestPartsView.fecha")}</strong>
                  </div>
                  <div className="col-5 col-sm-70-mod">
                    <span>{firstInfo && renderDate(firstInfo[0]?.FecIngreso, lang)}</span>
                  </div>
                </div>

                {/* FILA 2 */}
                <div className="row-flex">

                  {/* ID SERVICIO */}
                  <div className="col-7 col-sm-30">
                    <strong>{t("RequestPartsView.id_serv")}</strong>
                  </div>
                  <div className="col-7 col-sm-70-mod">
                    <Link className="link-tag" to={`/service/service-call/${firstInfo && firstInfo[0]?.CodServicio}`}>
                      <h3 style={{ color: 'blue' }}>{firstInfo && firstInfo[0]?.CodServicio}</h3>
                    </Link>
                  </div>
                  {/* EQUIPO */}
                </div>

                {/* FILA */}
                <div className="row-flex">
                  {/* CLIENTE */}
                  <div className="col-7 col-sm-30">
                    <strong>{t("RequestPartsView.cliente")}</strong>
                  </div>
                  <div className="col-80 col-sm-70-mod text-hidden-over">
                    <Link className="link-tag" to={`/customers/customer-detail/${firstInfo && firstInfo[0]?.CodCliente}`}>{firstInfo && firstInfo[0]?.NomCliente}</Link>
                  </div>
                </div>

                {/* EQUIPO */}
                <div className="row-flex">
                  <div className="col-7 col-sm-30">
                    <strong>{t("RequestPartsView.equip")}</strong>
                  </div>
                  <div className="col-80 col-sm-70-mod">
                    <Link className="link-tag" to={`/equipments/equipment-detail/${firstInfo && firstInfo[0]?.CodEquipo}`}>{firstInfo && firstInfo[0]?.Equipo}</Link>
                  </div>
                </div>

                {/* FILA 3 */}
                <div className="row-flex">
                  {/* APROBACION */}
                  <div className="col-7 col-sm-30">
                    <strong>{t("RequestPartsView.aprobacion")}</strong>
                  </div>
                  <div className="col-5 col-sm-70-mod">
                    <span>{firstInfo && renderIdTipSolicitud(firstInfo[0]?.IdTipSolicitud, lang)}</span>
                  </div>
                  {/* FDO */}
                  <div className="col-7 col-sm-30">
                    <strong>FDO</strong>
                  </div>
                  <div className="col-7 col-sm-70-mod"><span></span></div>
                  {/* PRIORIDAD */}
                  <div className="col-9 col-sm-30">
                    <strong>{t("RequestPartsView.prio")}</strong>
                  </div>
                  <div className="col-5 col-sm-70-mod"></div>
                </div>

                {/* FILA 4 */}

                {/* FILA 5 */}
                <div className="row-flex">
                  {/* SUPLIDOR */}
                  <div className="col-7 col-sm-30">
                    <strong>{t("RequestPartsView.supli")}</strong>
                  </div>
                  <div className="col-5 col-sm-70-mod"><span></span></div>
                  {/* SHIP TO */}
                  <div className="col-7 col-sm-30">
                    <strong>Ship To</strong>
                  </div>
                  <div className="col-7 col-sm-70-mod"><span></span></div>
                  {/* CONTACTO */}
                  <div className="col-9 col-sm-30">
                    <strong>{t("RequestPartsView.contact")}</strong>
                  </div>
                  <div className="col-4 col-sm-70-mod"><span></span></div>
                </div>

                {/* FILA 6 */}
                <div className="row-flex">
                  {/* TECNICO */}
                  <div className="col-7 col-sm-30">
                    <strong>{t("RequestPartsView.tecnico")}</strong>
                  </div>
                  <div className="col-5 col-sm-70-mod">
                    <span>{firstInfo && firstInfo[0]?.NomEmpleado}</span>
                  </div>
                  {/* ETAPA */}
                  <div className="col-7 col-sm-30">
                    <strong>{t("RequestPartsView.eta")}</strong>
                  </div>
                  <div className="col-3 col-sm-70-mod">
                    <span style={{ color: 'var(--green-color)', textDecoration: 'underline', fontWeight: 'bold' }}>{firstInfo && firstInfo[0]?.NomEtapa}</span>
                  </div>
                </div>

                {/* FILA 7 */}
                <div className="row-flex">
                  {/* OBSERVACION */}
                  <div className="col-7 col-sm-30">
                    <strong>{t("RequestPartsView.obser")}</strong>
                  </div>
                  <div className="col-80 col-sm-70-mod">
                    <span>{((firstInfo && firstInfo.length > 0 && firstInfo[0].IdEtapa === 13) ? firstInfo[0].ObsSolicitud : (firstInfo && firstInfo.length > 0) ? firstInfo[0].ObsOrden : "") || ""}</span>
                  </div>
                </div>

                {/* FILA 8 */}
                <div className="row-flex">
                  {/* QUE ES */}
                  <div className="col-7 col-sm-30">
                    <strong>{t("TableParts.que_es")}</strong>
                  </div>
                  <div className="col-5 col-sm-70-mod">
                    <span>{otherInf.queEs}</span>
                  </div>
                  {/* FUNCION */}
                  <div className="col-7 col-sm-30">
                    <strong>{t("TableParts.funcion")}</strong>
                  </div>
                  <div className="col-3 col-sm-70-mod">
                    <span>{otherInf.funcion}</span>
                  </div>
                </div>

                {/* FILA 9 */}
                <div className="row-flex">
                  {/* CARACTERISTICAS FISICAS */}
                  <div className="col-7 col-sm-30">
                    <strong>{t("TableParts.caract_fisi")}</strong>
                  </div>
                  <div className="col-5 col-sm-70-mod">
                    <span>{otherInf.carFisica}</span>
                  </div>
                  {/* CARACTERISTICAS TECNICAS */}
                  <div className="col-7 col-sm-30">
                    <strong>{t("TableParts.caract_tec")}</strong>
                  </div>
                  <div className="col-3 col-sm-70-mod">
                    <span>{otherInf.carTecnica}</span>
                  </div>
                </div>

                {/* FILA 10 */}
                <div className="row-flex">
                  {/* PIEZA POR DEVOLVER */}
                  <div className="col-7 col-sm-30">
                    <strong>{t("TableParts.pieza_devolve")}</strong>
                  </div>
                  <div className="col-5 col-sm-70-mod">
                    <input
                      className="input-control-checkbox"
                      type="checkbox"
                      name="devolver"
                      checked={form.devolver}
                      onChange={({ target }) => onHandleChangeForm("devolver", target.checked)} />
                  </div>
                </div>

                <hr className="m-y-small" />

                {/* FILA 11 */}
                <div className="row-flex">
                  {/* NRO. PARTE */}
                  <div className="col-7 col-sm-30">
                    <strong>{t("TableParts.parte")}</strong>
                  </div>
                  <div className="col-5 col-sm-70-mod">
                    <span style={{ textDEcoration: 'underline' }}>{otherInf.numParte}</span>
                  </div>
                  {/* DESCRIPCION */}
                  <div className="col-7 col-sm-30">
                    <strong>{t("TableParts.descrip")}</strong>
                  </div>
                  <div className="col-5 col-sm-70-mod">
                    <span>{otherInf.descripcion}</span>
                  </div>
                  {/* PRECIO */}
                  <div className="col-9 col-sm-30">
                    <strong>{t("TableParts.precio")}</strong>
                  </div>
                  <div className="col-5 col-sm-70-mod">
                    <span style={{ color: 'var(--money-color)', fontWeight: 'bold' }}>{formatCurrency(otherInf.precio, "USD")}</span>
                  </div>
                </div>

                {
                  [3, 6].includes(otherInf.prof) && (
                    <div className="row-flex">
                      {/* Colocar aqui */}
                      <div className="col-7 col-sm-30">
                        <strong>Tasa de liquidación</strong>
                      </div>
                      <div className="col-5 col-sm-70-mod">
                        <input
                          className="input-date-styles"
                          type="date"
                          name="exchangRate"
                          value={form.dateExchang}
                          onChange={({ target }) => onHandleChangeForm("dateExchang", target.value)} />
                      </div>
                      <div className="row-flex align-c">
                        <h5 style={{ color: "var(--money-color)" }}>{form.tasa}</h5>
                      </div>
                    </div>
                  )
                }

                <p className="p-data-part-v">
                  <strong className="strong-data-part-v">Datos de importación / Exportación</strong>
                </p>
                {/* FILA 12 */}
                <div className="row-flex gap-3-5">
                  <div className="col-5 col-sm-70-mod">
                    <strong>SO / SOA</strong>
                    <input
                      className="input-text-control"
                      disabled={INPUT_DISABLED}
                      type="text"
                      name="SOA"
                      value={form.so}
                      onChange={({ target }) => onHandleChangeForm("so", target.value)}
                      autoComplete="off" />
                  </div>
                  <div className="col-5 col-sm-70-mod">
                    <strong>{t("RequestPartsView.guia_desp")}</strong>
                    <input
                      className="input-text-control"
                      disabled={INPUT_DISABLED}
                      type="text"
                      name="guiaDespacho"
                      value={form.guiaDespa}
                      onChange={({ target }) => onHandleChangeForm("guiaDespa", target.value)}
                      autoComplete="off" />
                  </div>

                  <div className="col-5 col-sm-70-mod">
                    <strong>Carrier</strong>
                    <input
                      className="input-text-control"
                      disabled={INPUT_DISABLED}
                      type="text"
                      name="carrier"
                      value={form.carrier}
                      onChange={({ target }) => onHandleChangeForm("carrier", target.value)} />
                  </div>

                  <div className="col-5 col-sm-70-mod">
                    <strong>{t("RequestPartsView.fecha_desp")}</strong>
                    <input
                      className="input-date-styles"
                      disabled={INPUT_DISABLED}
                      type="date"
                      name="fecDespacho"
                      value={form.fecDespacho}
                      onChange={({ target }) => onHandleChangeForm("fecDespacho", target.value)} />
                  </div>

                </div>

                {/* fila para los nuevos inputs */}
                <div className="row-flex gap-3-5">
                  <div className="col-5 col-sm-70-mod">
                    <strong>Guia despacho a cliente</strong>
                    <input
                      className="input-text-control"
                      disabled={INPUT_DISABLED}
                      type="text"
                      name="guiaDespCliente"
                      value={form.guiDespCliente}
                      onChange={({ target }) => onHandleChangeForm("guiDespCliente", target.value)}
                      autoComplete="off" />
                  </div>

                  <div className="col-5 col-sm-70-mod">
                    <strong>Carrier al cliente</strong>
                    <input
                      className="input-text-control"
                      disabled={INPUT_DISABLED}
                      type="text"
                      name="carrCli"
                      value={form.carrClien}
                      onChange={({ target }) => onHandleChangeForm("carrClien", target.value)}
                      autoComplete="off" />
                  </div>

                  <div className="col-5 col-sm-70-mod">
                    <strong>Fecha despacho a cliente</strong>
                    <input
                      className="input-text-control"
                      disabled={INPUT_DISABLED}
                      type="date"
                      name="fecDespCliente"
                      value={form.fecDespCliente}
                      onChange={({ target }) => onHandleChangeForm("fecDespCliente", target.value)}
                      autoComplete="off" />
                  </div>

                  <div className="col-5 col-sm-70-mod">
                    <strong>Recepción WR</strong>
                    <input
                      className="input-text-control"
                      disabled={INPUT_DISABLED}
                      type="text"
                      name="WRNum"
                      value={form.numWR}
                      onChange={({ target }) => onHandleChangeForm("numWR", target.value)}
                      autoComplete="off" />
                  </div>

                </div>

                <div className="row-flex gap-3-5">
                  <div className="col-5 col-sm-70-mod">
                    <strong>Dimensiones</strong>
                    <input
                      className="input-text-control"
                      disabled={INPUT_DISABLED}
                      type="text"
                      name="dimen"
                      value={form.dimen}
                      onChange={({ target }) => onHandleChangeForm("dimen", target.value)}
                      autoComplete="off" />
                  </div>
                  <div className="col-5 col-sm-70-mod">
                    <strong>Peso</strong>
                    <input
                      className="input-text-control"
                      disabled={INPUT_DISABLED}
                      type="text"
                      name="peso"
                      value={form.peso}
                      onChange={({ target }) => onHandleChangeForm("peso", target.value)}
                      autoComplete="off" />
                  </div>
                  <div className="col-5 col-sm-70-mod">
                    <strong>{t("RequestPartsView.guia_export")}</strong>
                    <input
                      className="input-text-control"
                      disabled={INPUT_DISABLED}
                      type="text"
                      name="guiaExpor"
                      value={form.guiaExport}
                      onChange={({ target }) => onHandleChangeForm("guiaExport", target.value)}
                      autoComplete="off" />
                  </div>

                  <div className="col-5 col-sm-70-mod">
                    <strong>{t("RequestPartsView.fecha_export")}</strong>
                    <input
                      className="input-date-styles"
                      disabled={INPUT_DISABLED}
                      type="date"
                      name="fecExport"
                      value={form.fechaExport}
                      onChange={({ target }) => onHandleChangeForm("fechaExport", target.value)}
                      autoComplete="off" />
                  </div>

                </div>

                <p className="p-data-part-v">
                  <strong className="strong-data-part-v">Datos administrativos</strong>
                </p>

                {/* FILA 13 */}
                <div className="row-flex gap-3-5">

                  <div className="col-5 col-sm-70-mod">
                    <strong>{t("RequestPartsView.entrada_almacen")}</strong>
                    <input
                      className="input-text-control"
                      disabled={INPUT_DISABLED}
                      type="text"
                      name="entAlmac"
                      value={form.entrAlma}
                      onChange={({ target }) => onHandleChangeForm("entrAlma", target.value)}
                      autoComplete="off" />
                  </div>

                  <div className="col-5 col-sm-70-mod">
                    <strong>{t("RequestPartsView.salida_almacen")}</strong>
                    <input
                      className="input-text-control"
                      disabled={INPUT_DISABLED}
                      type="text"
                      name="salAlmac"
                      value={form.salidaAlma}
                      onChange={({ target }) => onHandleChangeForm("salidaAlma", target.value)}
                      autoComplete="off" />
                  </div>

                  <div className="col-9 col-sm-30 d-flex align-c gap-small">
                    <div className="d-flex">
                      <strong>{t("RequestPartsView.para_export")}</strong>
                    </div>
                    <div>
                      <input
                        className="input-control-checkbox"
                        type="checkbox"
                        name="paraExport"
                        checked={form.paraExport}
                        onChange={({ target }) => onHandleChangeForm("paraExport", target.checked)} />
                    </div>
                  </div>

                  <div className="col-9 col-sm-70-mod d-flex align-c gap-small">
                    <div className="d-flex">
                      <strong>{t("RequestPartsView.exportado")}</strong>
                    </div>
                    <div>
                      <input
                        className="input-control-checkbox"
                        type="checkbox"
                        name="exportado"
                        checked={form.exportado}
                        onChange={({ target }) => onHandleChangeForm("exportado", target.checked)} />
                    </div>
                  </div>

                </div>

                {
                  [3, 6].includes(otherInf.prof) && (
                    <>
                      <div className="row-flex gap-3-5">

                        <div className="col-5 col-sm-70-mod">
                          <strong>{t("TableParts.flete_import")}</strong>
                          <input
                            className="input-text-control"
                            type="text"
                            name="fleteImport"
                            value={formatVal(form.fleteImport)}
                            onChange={({ target }) => onHandleChangeForm("fleteImport", target.value)}
                            autoComplete="off" />
                        </div>

                        <div className="col-5 col-sm-70-mod">
                          <strong>{t("TableParts.flete_export")}</strong>
                          <input
                            className="input-text-control"
                            type="text"
                            name="fleteExport"
                            value={formatVal(form.fleteExport)}
                            onChange={({ target }) => onHandleChangeForm("fleteExport", target.value)}
                            autoComplete="off" />
                        </div>

                        <div className="col-5 col-sm-70-mod">
                          <strong>{t("TableParts.costo_mane")}</strong>
                          <input
                            className="input-text-control"
                            type="text"
                            name="fleteExport"
                            value={formatVal(form.handlingCost)}
                            onChange={({ target }) => onHandleChangeForm("handlingCost", target.value)}
                            autoComplete="off" />
                        </div>

                        <div className="col-5 col-sm-70-mod">
                          <strong>Gestión aduanal</strong>
                          <input
                            className="input-text-control"
                            type="text"
                            name="gestAdua"
                            value={formatVal(form.gestAdua)}
                            onChange={({ target }) => onHandleChangeForm("gestAdua", target.value)}
                            autoComplete="off" />
                        </div>
                      </div>

                      <div className="row-flex gap-3-5">
                        <div className="col-5 col-sm-70-mod">
                          <strong>DUA</strong>
                          <input
                            className="input-text-control"
                            type="text"
                            name="dua"
                            value={formatVal(form.dua)}
                            onChange={({ target }) => onHandleChangeForm("dua", target.value)}
                            autoComplete="off" />
                        </div>
                        <div className="col-5 col-sm-70-mod">
                          <strong>Entrega de documentos</strong>
                          <input
                            className="input-text-control"
                            type="text"
                            name="entrDoc"
                            value={formatVal(form.entDoc)}
                            onChange={({ target }) => onHandleChangeForm("entDoc", target.value)}
                            autoComplete="off" />
                        </div>
                      </div>
                    </>
                  )
                }

                {/* FILA 15 */}
                <div className="row-flex">
                  <div className="flex-auto col-sm-70-mod">
                    <strong>{t("TableParts.coment")}</strong>
                    <textarea
                      className="textarea-description"
                      name="comment"
                      value={form.comment}
                      onChange={({ target }) => onHandleChangeForm("comment", target.value)}
                      autoComplete="off"
                      placeholder={t("RequestPartsView.si_tiene_comen")} />
                  </div>
                </div>

                {/* BUTTONS */}
                <div className="row-flex m-y-small j-content-space-around">
                  <button type="button" className="btn-primary" onClick={onSaveInfoModal}>{t("RequestPartsView.guar")}</button>
                  <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>{t("navigationMenu.backBtn")}</button>
                </div>

                {/* TABLA */}
                <div className="table-container-mobile m-y-small seg-service-table">
                  <table className="table">
                    <thead className="table-head">
                      <tr>
                        <th>{t("RequestPartsView.fecha")}</th>
                        <th>{t("TableParts.etapa_actual")}</th>
                        <th>{t("TableParts.proxima_etapa")}</th>
                        <th>{t("TableParts.responsable_etapa")}</th>
                        <th className="col-6">{t("TableParts.coment")}</th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {
                        tblDetSegRepuesto && tblDetSegRepuesto.map((part) => (
                          <tr key={part.CodSegDetSolRepuesto}>
                            <td style={{ textAlign: 'center' }}>
                              {renderDate(part.FecIngreso, lang)}
                            </td>
                            <td>{part.NomEtapa}</td>
                            <td>{part.NomEtaProxima}</td>
                            <td>{part.NomEmpleado}</td>
                            <td>{part.Accion}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>

                {/* Modal para confirmar si está seguro de que quiere enviar el formulario */}
                {
                  state.showModal && (
                    <ModalConfirm onClose={onCloseModal} question={state.questionAct} sending={state.sending} />
                  )
                }
              </form>
            </>
          )
        }
      </div>
    </>
  );
};