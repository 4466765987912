
import React, { useState, useEffect, useContext } from "react";

/* --> Este componente es para crear los reportes de servicio que corresponden a las llamadas <-- */


// Components importation
import { LoadingComp } from "../../components/Loading-comp/LoadingComp.jsx";
import { Error403View } from "../../views/error-403-view/Error403View.jsx";

// REACT ROUTER DOM IMPORTATION
import { useLocation, useNavigate, useParams } from "react-router-dom";


// Urls importation
import { urls } from "../../api-urls/api-urls.js";


// Global function importation
import { getTheToken, renderDate, renderFormatTime } from "../../global-functions/globalFunctions.js";

// React translation
import { useTranslation } from "react-i18next";

// components import
import { ModalConfirm } from "../modal-confirm/ModalConfirm.jsx";
import { Add, TrashIcon } from "../icons/AppIcons.jsx";

// context import
import { LangContext } from "../../context/LangContext.jsx";
import { NotificationContext } from "../../context/NotificationContext.jsx";

// custom hook
import { useFetchServReport } from "../customs/service-report/useFetchServReport.js";





export const ServiceReport = ({ grupo }) => {

  // Get the context language
  const { lang } = useContext(LangContext);

  // GET the notification context
  const { handleNotify } = useContext(NotificationContext);

  const { idCall } = useParams();

  // End_POINT
  const EndPoint = `${urls.serviceReport}/${idCall}/search?language=${lang}&initialData=yes&grupo=${grupo}`;


  // custom hook to fetch data
  const { form, state, repState, handleChangeFormVal, handleCloseOpenModal, handleDelete, handlePerformAct, handleSendData, handleReloadData, onHandleCalHor } = useFetchServReport(EndPoint, getTheToken().token, lang, idCall);


  // React translation
  const [t] = useTranslation("global");

  // navigate
  const navigate = useNavigate();

  // useLocation()
  const location = useLocation();


  // Destructuring data server
  const { infoCustomer, serFechaReport, empRepServ, empRepServList } = repState && repState;



  // Funcion para crear los reportes de servicio
  const onCreateServiceReport = async (event) => {
    event.preventDefault();

    const empRepServi = repState.empRepServ
    const serFecReport = repState.serFechaReport

    const finalObj = { form, empRepServi, serFecReport };

    try {
      handleSendData(true);
      const response = await fetch(`${urls.serviceReport}/${idCall}/search?language=${lang}&initialData=no&action=${form.action}`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(finalObj)
      });

      const data = await response.json();

      if (response.status === 200) {
        if (data?.flag === "empl-add") { // se agrego un empleado
          handleChangeFormVal("ingInvolucrados", "");
        }

        if (data?.flag === "date-add") {
          handleChangeFormVal("fecDesde", "");
          handleChangeFormVal("fecHasta", "");
        }

        handleNotify(true, data?.msg, false, true);
        handleCloseOpenModal();

        handleReloadData(true);

        if (data?.codRepServ > 0) {
          navigate(`/service/service-report-detail/${data?.codRepServ}`);
        }
        // setState((val) => ({ ...val, showModal: false }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      }

      handleSendData();

    } catch (error) {
      console.log(error);
    }
  };



  return (
    <>
      {
        // Error 403 component
        state.unauthorized && (
          <Error403View />
        )
      }
      {
        // Loading components
        state.isLoading && (<LoadingComp />)
      }
      <div className="container display-data">
        {
          state.dataVisible && (
            <form onSubmit={onCreateServiceReport}>
              {/* FILA 2 */}
              <div className="row-flex m-y-small">
                {/* CLIENTE */}
                <div className="col-5 col-sm-30">
                  <strong>{t("serviceCard.customer")}</strong>
                </div>
                <div className="col-80 col-sm-70-mod text-hidden-over">
                  <span>{infoCustomer && infoCustomer[0]?.NomCliente}</span>
                </div>
              </div>

              {/* FLA 3 */}
              <div className="row-flex">
                {/* EQUIPO */}
                <div className="col-5 col-sm-30">
                  <strong>{t("serviceCard.equipment")}</strong></div>
                <div className="col-4 col-sm-70-mod text-hidden-over"><span>{infoCustomer && infoCustomer[0]?.Equipo}</span></div>
                {/* MODELO */}
                <div className="col-5 col-sm-30"><strong>{t("serviceCard.modelo")}</strong></div>
                <div className="col-3 col-sm-70-mod text-hidden-over"><span>{infoCustomer && infoCustomer[0]?.Modelo}</span></div>
              </div>
              <hr className="m-y-small" />

              {/* FILA 4 */}
              <div className="row-flex">
                {/* ESTADO DEL EQUIPO DESPUES DEL SERVICIO */}
                <div className="col-5 col-sm-30"><strong>{t("serviceCard.estado_equip")}</strong></div>
                <div className="col-75 col-sm-70-mod row-flex">
                  {/* Operativo */}
                  <div className="col-4 col-sm-80 row-flex gap-medium" style={{ alignItems: 'center' }}>
                    <div className="input-control bg-green circle-temp">
                      <input
                        type="radio"
                        value="0"
                        name="temperature"
                        checked={form.prioridad === "0"}
                        onChange={({ target }) => handleChangeFormVal("prioridad", target.value)} />
                    </div>
                    <div><span>{t("serviceCard.operativo")}</span></div>
                  </div>

                  {/* Operativo con fallas */}
                  <div className="col-3 col-sm-80 row-flex gap-medium" style={{ alignItems: 'center' }}>
                    <div className="input-control bg-yellow circle-temp">
                      <input
                        type="radio"
                        value="1"
                        name="temperature"
                        checked={form.prioridad === "1"}
                        onChange={({ target }) => handleChangeFormVal("prioridad", target.value)} />
                    </div>
                    <div><span>{t("serviceCard.operativo_fallas")}</span></div>
                  </div>

                  {/* Parado */}
                  <div className="col-4 col-sm-80 row-flex gap-medium" style={{ alignItems: 'center' }}>
                    <div className="input-control bg-red circle-temp">
                      <input
                        type="radio"
                        value="4"
                        name="temperature"
                        checked={form.prioridad === "4"}
                        onChange={({ target }) => handleChangeFormVal("prioridad", target.value)} />
                    </div>
                    <div><span>{t("serviceCard.equip_detenido")}</span></div>
                  </div>
                </div>
              </div>

              {/* FILA 5 */}
              <div className="row-flex m-y-small">
                {/* INGENIEROS INVOLUCRADOS */}
                <div className="col-5 col-sm-30"><strong>{t("serviceCard.ing_involucrados")}</strong></div>
                <div className="col-4 col-sm-50">
                  <select
                    name="ingenInvolucrados"
                    value={form.ingInvolucrados}
                    onChange={({ target }) => handleChangeFormVal("ingInvolucrados", target.value)}>
                    <option value=""></option>
                    {
                      empRepServList && empRepServList.map((emp) => (
                        <option key={emp.CodEmpleado} value={emp.CodEmpleado}>{emp.NomEmpleado}</option>
                      ))
                    }
                  </select>
                </div>
                <div className="col-10">
                  <button type="button" className="btn-add-date gap-small row-flex align-c" onClick={() => handlePerformAct("1")}>
                    <Add />
                  </button>
                </div>
              </div>

              {/* lista de los empleados que participaran en el reporte */}
              {
                empRepServ && empRepServ.length > 0 && (
                  <div className="row-flex m-y-small d-column">
                    {
                      empRepServ && empRepServ.map((emp) => (
                        <div key={emp.CodEmpleado} className="col-6">
                          <div className="row-flex gap-small align-c m-l-small">
                            <div>
                              <button type="button" className="btn-delete" onClick={() => handleDelete(emp.CodRepServicio, emp.CodEmpleado, emp.Temporal, "5")}>
                                <TrashIcon />
                              </button>
                            </div>
                            <div>
                              <span>{emp.NomEmpleado}</span>
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                )
              }

              {/* FILA 6 */}
              <div className="row-flex">
                {/* FECHA DEL SERVICIO (Desde) */}
                <div className="col-5 col-sm-30">
                  <strong>{t("serviceCard.fec_servicio")}</strong>
                </div>
                <div className="col-4 col-sm-50">
                  <input
                    className="input-date-styles"
                    type="datetime-local"
                    name="fecDesde"
                    value={form.fecDesde}
                    onChange={({ target }) => handleChangeFormVal("fecDesde", target.value)} />
                </div>
                {/* Fecha del servicio (Hasta) */}
                <div className="col-5 col-sm-30"><strong>{t("serviceCard.fec_hasta")}</strong></div>
                <div className="col-5 col-sm-50">
                  <input
                    className="input-date-styles"
                    type="datetime-local"
                    name="fecHasta"
                    value={form.fecHasta}
                    onChange={({ target }) => handleChangeFormVal("fecHasta", target.value)} />
                </div>
                <div className="col-7 col-100-sm flex-mob-btn-add-date">
                  <button type="button" className="btn-add-date gap-small row-flex align-c" onClick={() => handlePerformAct("2")}>
                    <Add />
                    {t("serviceCard.add_fec")}
                  </button>
                </div>
              </div>

              <hr className="m-y-small" />

              {/* Tabla para mostrar las fechas */}
              {
                serFechaReport && serFechaReport.length > 0 && (
                  <div className="table-container-mobile">
                    <table className="table">
                      <thead className="table-head">
                        <tr>
                          <th></th>
                          <th>{t("serviceCard.fec_ini")}</th>
                          <th>{t("serviceCard.fec_fin")}</th>
                          <th>{t("serviceCard.hor_normal")}</th>
                          <th>{t("serviceCard.hor_ext_diurnas")}</th>
                          <th>{t("serviceCard.hor_ext_nocturnas")}</th>
                          <th>{t("serviceCard.hor_ext_feriados")}</th>
                          <th>{t("serviceCard.total_hor")}</th>
                        </tr>
                      </thead>
                      <tbody className="table-body">
                        {
                          serFechaReport && serFechaReport.map((fec) => (
                            <tr key={fec.CodRepSerFecha}>
                              <td className="txt-center">
                                <button type="button" className="btn-del" onClick={() => handleDelete(fec.CodRepServicio, fec.CodRepSerFecha, fec.Temporal, 4)}>
                                  <TrashIcon heightP="18" widthP="18" />
                                </button>
                              </td>
                              <td>{renderDate(fec.FecInicio, lang)} / {renderFormatTime(fec.FecInicio)}</td>
                              <td>{renderDate(fec.FecFin, lang)} / {renderFormatTime(fec.FecFin)}</td>
                              <td className="txt-center">{fec.HorNormal}</td>
                              <td className="txt-center">{fec.HorExtras}</td>
                              <td className="txt-center">{fec.HorExtNocturna}</td>
                              <td className="txt-center">{fec.HorExtFeriado}</td>
                              <td className="txt-center">{fec.TotalHoras}</td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                )
              }

              {/* FILA 7 */}
              <div className="row-flex m-y-small">
                {/* DESCRIPCION DEL TRABAJO */}
                <div className="col-5">
                  <strong>{t("serviceCard.descrip_trabajo")}</strong>
                </div>
                <div className="col-80" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: '0.5rem' }}>
                  <textarea
                    className="textarea-description"
                    name="descripcionDelTrabajo"
                    value={form.descripcion}
                    onChange={({ target }) => handleChangeFormVal("descripcion", target.value)}
                    placeholder={lang === "en" ? "Describe the work done..." : "Describa el trabajo que realizó..."}
                    autoComplete="off" />
                  <strong className="txt-muted">{form.descripcion.length} / max 4000</strong>
                </div>
              </div>

              {/* FILA 8 */}
              <div className="row-flex">
                {/* NUMERO SERIAL DEL TUBO */}
                <div className="col-5 col-sm-50">
                  <strong>{t("serviceCard.num_serial")}</strong>
                </div>
                <div className="col-4 col-sm-50">
                  <input
                    className="input-text-control"
                    name="serialTubo"
                    type="text"
                    value={form.serialTuboInst}
                    onChange={({ target }) => handleChangeFormVal("serialTuboInst", target.value)}
                    placeholder={lang === "en" ? "Serial number tube if apply..." : "Número de Serial del tubo si aplica..."}
                    autoComplete="off" />
                </div>
                {/* Numero serial tubo desinstalado */}
                <div className="col-5 col-4 col-sm-50">
                  <strong>{t("serviceCard.num_serial_tub_desins")}</strong>
                </div>
                <div className="col-4 col-4 col-sm-50">
                  <input
                    className="input-text-control"
                    type="text"
                    name="serTubDes"
                    value={form.serialTuboDes}
                    onChange={({ target }) => handleChangeFormVal("serialTuboDes", target.value)}
                    autoComplete="off" />
                </div>
              </div>

              {/* FILA 9 */}
              <div className="row-flex m-y-small">
                {/* NUMERO SERIAL TRANSDUCTOR instalado */}
                <div className="col-5 col-4 col-sm-50">
                  <strong>{t("serviceCard.num_serial_trans")}</strong>
                </div>
                <div className="col-4 col-4 col-sm-50">
                  <input
                    className="input-text-control"
                    name="serialTransductor"
                    type="text"
                    value={form.serialTransInst}
                    onChange={({ target }) => handleChangeFormVal("serialTransInst", target.value)}
                    placeholder={lang === "en" ? "Serial number transducer if apply..." : "Número de Serial del transductor si aplica..."}
                    autoComplete="off" />
                </div>
                {/* Numero serial transductor desinstalado */}
                <div className="col-5 col-4 col-sm-50">
                  <strong>{t("serviceCard.num_serial_trans_desins")}</strong>
                </div>
                <div className="col-4 col-4 col-sm-50">
                  <input
                    className="input-text-control"
                    type="text"
                    name="serTransDes"
                    value={form.serialTransDes}
                    onChange={({ target }) => handleChangeFormVal("serialTransDes", target.value)}
                    autoComplete="off" />
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex flex-end">
                <div className="col-42-87 col-100-sm row-flex flex-end">
                  {/* Servicio normal */}
                  <div className="col-58">
                    <strong style={{ display: 'block', textAlign: 'right' }}>{t("serviceCard.servicio_norm")}</strong>
                  </div>
                  <div className="col-3">
                    <input
                      className="input-text-control"
                      type="text"
                      name="hours"
                      value={form.horas}
                      readOnly />
                  </div>
                  {/* Servicio H extra diurnas */}
                  <div className="col-58">
                    <strong style={{ display: 'block', textAlign: 'right' }}>{t("serviceCard.servicio_hor_ext_diurn")}</strong>
                  </div>
                  <div className="col-3">
                    <input
                      className="input-text-control"
                      type="text"
                      name="hExtras"
                      value={form.horExtras}
                      readOnly />
                  </div>
                  {/* Servicio H extra nocturnas */}
                  <div className="col-58">
                    <strong style={{ display: 'block', textAlign: 'right' }}>{t("serviceCard.servicio_hor_ext_noctur")}</strong>
                  </div>
                  <div className="col-3">
                    <input
                      className="input-text-control"
                      type="text"
                      name="hExtNoct"
                      value={form.horExtNocturna}
                      readOnly />
                  </div>
                  {/* Servicio H/Extra sabados, domingos y feriados */}
                  <div className="col-58">
                    <strong style={{ display: 'block', textAlign: 'right' }}>{t("serviceCard.servicio_hor_ext_sab_dom")}</strong>
                  </div>
                  <div className="col-3">
                    <input
                      className="input-text-control"
                      type="text"
                      name="hExtFeri"
                      value={form.horExtFeriado}
                      readOnly />
                  </div>
                  {/* Horas en Viajes */}
                  <div className="col-58">
                    <strong style={{ display: 'block', textAlign: 'right' }}>{t("serviceCard.en_viajes")}</strong>
                  </div>
                  <div className="col-3">
                    <input
                      className="input-text-control"
                      type="text"
                      name="horViaj"
                      value={form.horViaje}
                      onChange={({ target }) => onHandleCalHor(target.value)} />
                  </div>
                  {/* Total Horas */}
                  <div className="col-58">
                    <strong style={{ display: 'block', textAlign: 'right' }}>{t("serviceCard.total_hor")}</strong>
                  </div>
                  <div className="col-3">
                    <input
                      className="input-text-control wrapper"
                      type="text"
                      name="totalHor"
                      value={form.totHoras}
                      readOnly />
                  </div>
                </div>
              </div>

              {/* BOTONES DE ACCION */}
              <div className="row-flex j-content-space-around m-y-medium">
                {
                  serFechaReport && serFechaReport.length > 0 && (
                    <button
                      className="btn-primary"
                      type="button"
                      onClick={() => handlePerformAct("3")}>{t("serviceCard.guardar")}</button>
                  )
                }
                <button className="btn-secondary" type="reset" onClick={() => navigate(-1, { replace: true })}>{t("navigationMenu.backBtn")}</button>
              </div>

              {/* Modal para confirmar si está seguro de que quiere enviar el formulario */}
              {
                state.showModal && (
                  <ModalConfirm onClose={handleCloseOpenModal} question={state.question} sending={state.sending} />
                )
              }
            </form>
          )
        }
      </div>

      {/* { */}
      {/* state.shoModalConfirm && ( */}
      {/* <div className="background-modal" onClick={() => setState({ ...state, shoModalConfirm: false })}>
            <div className="container-modal-confirm" onClick={(event) => event.stopPropagation()}> */}
      {/* Question mark */}
      {/* <div className="row-flex m-y-small align-c justify-center">
                <div className="question-mark-container">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-lg" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14Z" />
                  </svg>
                </div>
              </div>
              <h5 style={{ textAlign: 'center', color: 'var(--first-color)' }}> */}
      {/* {state.questionAction} */}
      {/* </h5> */}
      {/* Botones de accion */}
      {/* <div className="row-flex m-y-medium j-content-space-around">
                <button type="button" className="btn-success" onClick={onDeleteSerFecha}>{t("RequestPartsView.si")}</button>
                <button type="button" className="btn-danger" onClick={onCloseDeleteSerFecha}>No</button>
              </div>
            </div>
          </div> */}
      {/* ) */}
      {/* }  */}

      {/* {
        state.showModalDelEmp && (
          <div className="background-modal" onClick={() => setState({ ...state, showModalDelEmp: false })}>
            <div className="container-modal-confirm" onClick={(event) => event.stopPropagation()}>
              <div className="row-flex m-y-small align-c justify-center">
                <div className="question-mark-container">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-lg" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14Z" />
                  </svg>
                </div>
              </div>
              <h5 style={{ textAlign: 'center', color: 'var(--first-color)' }}>
                {state.questionAction}
              </h5>
              <div className="row-flex m-y-medium j-content-space-around">
                <button type="button" className="btn-success" onClick={onDeleteEmployee}>{t("RequestPartsView.si")}</button>
                <button type="button" className="btn-danger" onClick={onCloseModalEmp}>No</button>
              </div>
            </div>
          </div>
        )
      } */}
    </>
  );
};