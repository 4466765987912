

// ESTE COMPONENTE ES PARA SELECCIONAR LAS PARTES Y PODER MODIFICARLAS
// ESTO FUNCIONARA COMO UN MODAL

import React, { useEffect, useState, useContext } from "react";

// URLS IMPORTATIONS
import { urls } from "../../api-urls/api-urls.js";

// import context
import { LangContext } from "../../context/LangContext.jsx";
import { NotificationContext } from "../../context/NotificationContext.jsx"


// REACT ROUTER DOM IMPORTATION
import { useLocation, useNavigate } from "react-router-dom";

// GLOBAL FUNCTIONS
import { getTheToken } from "../../global-functions/globalFunctions.js";

// usetranslation import
import { useTranslation } from "react-i18next";



export const PartDetailModify = ({ solRepuesto, codDetSolRepuesto, onClose, onReload }) => {


  // GET the react translation
  const [t] = useTranslation("global");

  // GET the location
  const location = useLocation();

  // GET the useNavigate
  const navigate = useNavigate();

  // lang context
  const { lang } = useContext(LangContext);

  // notification context
  const { handleNotify } = useContext(NotificationContext);


  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]);
  const [state, setState] = useState({ isLoading: false, updating: false });
  const [stateInputs, setStateInputs] = useState({
    cantidad: "", tipArticulo: "", parte: "", parteAnterior: "", descripcionEn: "", descripcionEs: "", preLista: 0, factor: 0,
    itbis: 0, multipli: 0, descuento: 0, preOrden: 0, stock: false, disponible: "", workFlow: "", infoDispo: "", queEs: "",
    funcion: "", caractFisicas: "", caractTecnicas: "", CodDYN: ""
  });

  // DESTRUCTURING STATE DEFINITION
  const { tblDetSolRepuesto, EmpPerm } = dataServer && dataServer;

  // PERFILES
  const OPER_DEPART = 3; // perfil de operaciones
  const ADMIN_SYS = 6; // Perfil administrador del sistema
  const DIRECTOR = 7; // perfil de director
  const EMPLEADO_VIA = 0; // viatico de empleado
  const SUPER_VIA = 1; // Viatico de supervisor
  const GEREN_VIA = 2; // Viatico de gerente
  const DIRECTOR_VIA = 3; // Viatico de director

  const PERFIL_ALLOWED = [OPER_DEPART, DIRECTOR, ADMIN_SYS];
  const VIAT_ALLOWED = [EMPLEADO_VIA, SUPER_VIA, GEREN_VIA, DIRECTOR_VIA];


  // funcion para calcular el precio de descuento y el precio de lista
  const onCalDiscount = (newVal) => {
    if (isNaN(newVal) || Number(newVal) > 100) {
      return;
    }
    const calc = (stateInputs.preLista - (stateInputs.preLista * newVal) / 100);

    setStateInputs((val) => ({ ...val, descuento: newVal, preOrden: calc.toFixed(2) }))
  };

  // funcion para calcular el precio de lista
  const onCalPriceList = (newVal) => {
    if (isNaN(newVal)) {
      return;
    }

    const calc = (newVal - (newVal * stateInputs.descuento) / 100);

    setStateInputs((val) => ({ ...val, preOrden: calc, preLista: newVal }));
  };


  // useEffect para traer la data del servidor
  useEffect(() => {
    try {
      const fetchDataServer = async () => {
        setState((val) => ({ ...val, isLoading: true }));
        const response = await fetch(`${urls.partDetail}/${solRepuesto}/${codDetSolRepuesto}/search?language=${lang}`, {
          headers: {
            "Authorization": getTheToken().token,
            "Content-Type": "application/json"
          }
        });

        const data = await response.json();

        if (response.status === 200) {
          setDataServer(data);
        } else if (response.status === 401) {
          localStorage.setItem("lastNav", location.pathname);
          localStorage.removeItem("userInfo");
          return navigate("/");
        }

        setState((val) => ({ ...val, isLoading: false }));
      };

      fetchDataServer();

    } catch (error) {
      console.log(error)
    }
  }, [solRepuesto, codDetSolRepuesto, lang]);

  // useEffect para cargar la data en el estado y poder modificarla
  useEffect(() => {
    if (tblDetSolRepuesto && tblDetSolRepuesto.length > 0) {
      setStateInputs({
        cantidad: tblDetSolRepuesto[0]?.Cantidad, tipArticulo: tblDetSolRepuesto[0]?.TipArticulo ?? "", parte: tblDetSolRepuesto[0]?.NumParte, parteAnterior: tblDetSolRepuesto[0]?.Alternativo, descripcionEn: tblDetSolRepuesto[0]?.Descripcion, descripcionEs: tblDetSolRepuesto[0]?.DesCotizacion,
        preLista: tblDetSolRepuesto[0]?.Lista, factor: tblDetSolRepuesto[0]?.Factor, itbis: tblDetSolRepuesto[0]?.Itbis, multipli: tblDetSolRepuesto[0]?.Multiplicador, descuento: tblDetSolRepuesto[0]?.Descuento, preOrden: tblDetSolRepuesto[0]?.Precio,
        stock: tblDetSolRepuesto[0]?.Stock, disponible: tblDetSolRepuesto[0]?.BackOrder ?? "", workFlow: tblDetSolRepuesto[0]?.WorkFlow ?? "", infoDispo: tblDetSolRepuesto[0]?.BackOrder ?? "", queEs: tblDetSolRepuesto[0]?.QueEs, funcion: tblDetSolRepuesto[0]?.Funcion,
        caractFisicas: tblDetSolRepuesto[0]?.CarFisica, caractTecnicas: tblDetSolRepuesto[0]?.CarTecnica, CodDYN: tblDetSolRepuesto[0]?.CodDYN
      });
    }
  }, [tblDetSolRepuesto]);



  // ACTUALIZAR LA INFORMACION EN LA BASE DE DATOS
  const onSaveInfo = async (event) => {
    event.preventDefault();


    if (parseFloat(stateInputs.cantidad) === 0) {
      return handleNotify(true, "La cantidad no puede ser 0", true);
    }


    try {
      setState((val) => ({ ...val, updating: true }));
      const response = await fetch(`${urls.partDetail}/${solRepuesto}/${codDetSolRepuesto}/search?language=${lang}&flag=1`, {
        method: "PUT",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(stateInputs)
      });

      const data = await response.json();

      if (response.status === 200) {
        handleNotify(true, data?.msg, false, true);
        // hay que recargar la informacion
        onReload(data?.reload);
      }

      setState((val) => ({ ...val, updating: false }));

    } catch (error) {
      console.log(error);
    }
  };


  return (
    <>
      <div className="background-modal">
        <form onSubmit={onSaveInfo} className="container-select-customer">
          <div className="modal-header">
            <h3>Detalles de la parte</h3>
            <button type="button" className="btn-close" onClick={() => onClose(0, 0)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
              </svg>
            </button>
          </div>
          {state.isLoading && (<div className="simple-spinner"><span></span></div>)}
          <div className="modal-body">
            {/* FILA 1 */}
            <div className="row-flex">
              {/* CANTIDAD */}
              <div className="col-7">
                <strong>{t("TableParts.canti")}</strong>
              </div>
              <div className="flex-auto">
                <input
                  className="input-text-control"
                  type="text"
                  name="cantidad"
                  value={stateInputs.cantidad}
                  onChange={({ target }) => {
                    if (isNaN(target.value)) {
                      return;
                    }
                    setStateInputs((val) => ({ ...val, cantidad: target.value }))
                  }} />
              </div>
            </div>

            {/* FILA 2 */}
            <div className="row-flex">
              {/* PARTE */}
              <div className="col-7">
                <strong>{t("TableParts.parte")}</strong>
              </div>
              <div className="col-3">
                <input
                  className="input-text-control"
                  type="text"
                  name="parte"
                  value={stateInputs.parte}
                  onChange={({ target }) => setStateInputs((val) => ({ ...val, parte: target.value }))} />
              </div>
              {/* NUMERO DE PARTE ANTERIOR */}
              <div className="col-7">
                <strong>{t("TableParts.num_part_anterior")}</strong>
              </div>
              <div className="flex-auto">
                <input
                  className="input-text-control"
                  type="text"
                  name="parteAnterior"
                  value={stateInputs.parteAnterior}
                  onChange={({ target }) => setStateInputs((val) => ({ ...val, parteAnterior: target.value }))} />
              </div>
            </div>

            {/* FILA 3 */}
            <div className="row-flex">
              {/* DESCRIPCION EN INGLES */}
              <div className="col-7">
                <strong>{t("TableParts.descripcion_ingl")}</strong>
              </div>
              <div className="flex-auto">
                <textarea
                  className="textarea-description"
                  name="descripIngles"
                  value={stateInputs.descripcionEn}
                  onChange={({ target }) => setStateInputs((val) => ({ ...val, descripcionEn: target.value }))} />
              </div>
            </div>

            {/* Solo pueden ver operaciones y directores desde acá */}
            {/* FILA 5 */}
            {
              (!!EmpPerm && PERFIL_ALLOWED.includes(EmpPerm[0]?.CodPerfil) && VIAT_ALLOWED.includes(EmpPerm[0]?.NivViatico)) && (
                <>
                  <div className="row-flex">
                    {/* Tipo de articulo */}
                    <div className="col-7">
                      <strong>{t("TableParts.tip_articulo")}</strong>
                    </div>
                    <div className="col-83-87 flex-grow">
                      <select
                        name="tipArt"
                        value={stateInputs.tipArticulo}
                        onChange={({ target }) => setStateInputs((val) => ({ ...val, tipArticulo: target.value }))}>
                        <option value=""></option>
                        {/* Si la empresa es global */}
                        {
                          stateInputs.CodDYN === "GLOBL" && (
                            <>
                              <option value="IMPLANTES">Implantes</option>
                              <option value="REPGE">Repuestos GE</option>
                              <option value="REPVYOT">Repuestos VARIAN y otros</option>
                              <option value="SERVGE">Servicios GE</option>
                              <option value="SERVOTR">Servicios otros</option>
                              <option value="SUMINISTRO">Suministros</option>
                              <option value="ULTRASONID">Ultrasonidos</option>
                              <option value="VENTAS DI">Ventas DI</option>
                            </>
                          )
                        }
                        {/* Si la empresa es trinidad */}
                        {
                          stateInputs.CodDYN === "GMDH" && (
                            <>
                              <option value="DISALE">DI Sales</option>
                              <option value="GEPART">GE Parts</option>
                              <option value="GESERV">GE Service</option>
                              <option value="OTHSER">Other services</option>
                              <option value="SUPSAL">Supply sales</option>
                              <option value="ULTSAL">Ultrasound sales</option>
                            </>
                          )
                        }
                      </select>
                    </div>
                  </div>
                  {/* FILA 4 */}
                  <div className="row-flex">
                    {/* DESCRIPCION EN ESPANOL */}
                    <div className="col-7">
                      <strong>{t("TableParts.descripcion_espa")}</strong>
                    </div>
                    <div className="flex-auto">
                      <textarea
                        className="textarea-description"
                        name="descripEspanol"
                        value={stateInputs.descripcionEs}
                        onChange={({ target }) => setStateInputs((val) => ({ ...val, descripcionEs: target.value }))} />
                    </div>
                  </div>

                  <div className="row-flex">
                    {/* PRECIO DE LISTA */}
                    <div className="col-7">
                      <strong>{t("TableParts.precio_lista")}</strong>
                    </div>
                    <div className="col-3">
                      <input
                        className="input-text-control"
                        type="text"
                        name="preLista"
                        value={stateInputs.preLista}
                        onChange={({ target }) => onCalPriceList(target.value)} />
                    </div>
                    {/* FACTOR */}
                    <div className="col-8">
                      <strong>Factor</strong>
                    </div>
                    <div className="col-8">
                      <input
                        className="input-text-control"
                        type="text"
                        name="factor"
                        value={stateInputs.factor}
                        onChange={({ target }) => setStateInputs((val) => ({ ...val, factor: target.value }))} />
                    </div>
                    {/* ITBIS */}
                    <div className="col-9">
                      <strong>ITBIS</strong>
                    </div>
                    <div className="col-8 flex-grow">
                      <input
                        className="input-text-control"
                        type="text"
                        name="itbis"
                        value={stateInputs.itbis}
                        onChange={({ target }) => setStateInputs((val) => ({ ...val, itbis: target.value }))} />
                    </div>
                  </div>

                  {/* FILA 6 */}
                  <div className="row-flex">
                    {/* MULTIPLICADOR */}
                    <div className="col-7">
                      <strong>{t("TableParts.multipli")}</strong>
                    </div>
                    <div className="col-3">
                      <input
                        className="input-text-control"
                        type="text"
                        name="multipl"
                        value={stateInputs.multipli}
                        onChange={({ target }) => setStateInputs((val) => ({ ...val, multipli: target.value }))} />
                    </div>
                    {/* DESCUENTO */}
                    <div className="col-8">
                      <strong>{t("TableParts.descuento")}</strong>
                    </div>
                    <div className="flex-auto">
                      <input
                        className="input-text-control"
                        type="text"
                        name="discount"
                        value={stateInputs.descuento}
                        onChange={({ target }) => onCalDiscount(target.value)} />
                    </div>
                  </div>

                  {/* FILA 7 */}
                  <div className="row-flex">
                    {/* PRECIO DE COMPRA */}
                    <div className="col-7">
                      <strong>{t("TableParts.precio_orden")}</strong>
                    </div>
                    <div className="col-3">
                      <input
                        className="input-text-control"
                        type="text"
                        name="preOrd"
                        value={stateInputs.preOrden}
                        onChange={({ target }) => setStateInputs((val) => ({ ...val, preOrden: target.value }))} />
                    </div>
                    {/* EN STOCK */}
                    <div className="col-8">
                      <strong>Stock</strong>
                    </div>
                    <div className="col-9">
                      <select
                        name="stockAction"
                        value={stateInputs.stock}
                        onChange={({ target }) => setStateInputs((val) => ({ ...val, stock: target.value }))}>
                        <option value=""></option>
                        <option value="">Si</option>
                        <option value="">No</option>
                      </select>
                    </div>
                    {/* DISPONIBLE */}
                    <div className="col-8">
                      <strong>{t("TableParts.disponible")}</strong>
                    </div>
                    <div className="col-9 flex-grow">
                      <select
                        name="stockAction"
                        value={stateInputs.disponible}
                        onChange={({ target }) => setStateInputs((val) => ({ ...val, disponible: target.value }))}>
                        <option value=""></option>
                        <option value="">Si</option>
                        <option value="">No</option>
                      </select>
                    </div>
                  </div>

                  {/* FILA 8 */}
                  <div className="row-flex">
                    {/* WORKFLOW */}
                    <div className="col-7">
                      <strong>WorkFlow</strong>
                    </div>
                    <div className="flex-auto">
                      <input
                        style={{ width: '100%' }}
                        className="input-text-control"
                        type="text"
                        name="workflow"
                        value={stateInputs.workFlow}
                        onChange={({ target }) => setStateInputs((val) => ({ ...val, workFlow: target.value }))} />
                    </div>
                  </div>

                  {/* FILA 9 */}
                  <div className="row-flex">
                    {/* INFORMACION DISPONIBILIDAD */}
                    <div className="col-7">
                      <strong>{t("TableParts.infor_disponi")}</strong>
                    </div>
                    <div className="flex-auto">
                      <input
                        style={{ width: '100%' }}
                        className="input-text-control"
                        type="text"
                        name="infoDis"
                        value={stateInputs.infoDispo}
                        onChange={({ target }) => setStateInputs((val) => ({ ...val, infoDispo: target.value }))}
                        placeholder={lang === "en" ? "Write the availability information of the supplier." : "Coloque aquí la información de disponibilidad en el suplidor."} />
                    </div>
                  </div>
                </>
              )
            }

            {/* Hasta acá */}

            {/* FILA 10 */}
            <div className="row-flex">
              <div className="col-7">
                <strong>{t("TableParts.que_es")}</strong>
              </div>
              <div className="flex-auto">
                <input
                  className="input-text-control"
                  type="text"
                  name="queEs"
                  value={stateInputs.queEs}
                  onChange={({ target }) => setStateInputs((val) => ({ ...val, queEs: target.value }))} />
              </div>
            </div>

            {/* FILA 11 */}
            <div className="row-flex">
              {/* SU FUNCION, QUE HACE */}
              <div className="col-7">
                <strong>{t("TableParts.funcion")}</strong>
              </div>
              <div className="flex-auto">
                <input
                  className="input-text-control"
                  type="text"
                  name="funcion"
                  value={stateInputs.funcion}
                  onChange={({ target }) => setStateInputs((val) => ({ ...val, funcion: target.value }))}
                  placeholder={lang === "en" ? "Write the functionality here, this is very helpful for customs." : "Coloque cuál es la funcionalidad de esta parte, esto ayuda para la nacionalización..."} />
              </div>
            </div>

            {/* FILA 12 */}
            <div className="row-flex">
              {/* CARACTERISTICAS FISICAS */}
              <div className="flex-auto">
                <strong>{t("TableParts.caract_fisi")}</strong>
              </div>
            </div>

            {/* FILA 13 */}
            <div className="row-flex">
              {/* INPUT */}
              <div className="flex-auto">
                <input
                  className="input-text-control"
                  type="text"
                  name="carFis"
                  value={stateInputs.caractFisicas}
                  onChange={({ target }) => setStateInputs((val) => ({ ...val, caractFisicas: target.value }))} />
              </div>
            </div>

            {/* FILA 14 */}
            <div className="row-flex">
              {/* CARACTERISTICAS TECNICAS */}
              <div className="flex-auto">
                <strong>{t("TableParts.caract_tec")}</strong>
              </div>
            </div>

            {/* FILA 15 */}
            <div className="row-flex">
              {/* INPUT */}
              <div className="flex-auto">
                <input
                  className="input-text-control"
                  type="text"
                  name="carTec"
                  value={stateInputs.caractTecnicas}
                  onChange={({ target }) => setStateInputs((val) => ({ ...val, caractTecnicas: target.value }))} />
              </div>
            </div>
          </div>
          {/* BOTONES DE ACCION */}
          <div className="modal-footer j-content-space-around">
            <button
              type={state.updating ? "button" : "submit"}
              disabled={state.updating}
              className={`btn-primary ${state.updating ? "disabled" : ""}`}>{state.updating ? t("serviceCard.guardando") : t("TableParts.guard")}</button>
            <button type="reset" className="btn-secondary" onClick={() => onClose(0, 0)}>{t("TableParts.cerrar")}</button>
          </div>
        </form>
      </div>
    </>
  );
};