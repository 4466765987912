// componente para la actualización de las ordenes

import { useState, useContext } from "react";

// components imports
import { Close } from "../icons/AppIcons.jsx";


import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


// notification context import
import { NotificationContext } from "../../context/NotificationContext.jsx";
import { LangContext } from "../../context/LangContext.jsx";






export const ModalUpdateOrder = ({ onClose, orderUpdate = [], urls = {}, token = "", sendServer, handleChangePartVal, onCloseBtn, setNewOrderUpd, state, zonas, changeUpdate, setNewStateParts }) => {


  // state definition
  const [form, setForm] = useState({ soNumber: "", action: "", comment: "", fecDespa: "", fecLlega: "", guiaDesp: "", carrier: "", guiDespCli: "", carrCli: "", numWR: "", dimen: "", peso: "", fecDespCli: new Date().toLocaleDateString("en-CA") }); // estado para manejar los datos del formulario


  // GET the react translation
  const [t] = useTranslation("global");


  // Etapas que se pueden actualizar
  const STEP_UPDATE = [20, 30, 31, 32, 33, 34, 35, 36, 37, 38, 48];

  // GET the notifiy context
  const { handleNotify } = useContext(NotificationContext);


  // GET the language context
  const { lang } = useContext(LangContext);



  // funcion para actualizar los valores del formulario
  const handleChangeFormVal = (name = "", newVal = "") => {
    setForm((val) => ({ ...val, [name]: newVal }));
  };



  // Funcion para el manejo del formulario para actualizar las piezas
  const onUpdateOrder = async (event) => {
    event.preventDefault();

    if (form.action === "") {
      return handleNotify(true, t("TableParts.seleccionar_accion"), true);
    }

    const finalObj = {
      form,
      orderUpdate
    };

    try {
      sendServer(true);
      const response = await fetch(`${urls.getFollowUpOrders}?language=${lang}`, {
        method: "PUT",
        headers: {
          "Authorization": token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(finalObj)
      });

      const data = await response.json();

      if (response.status === 200) {

        onCloseBtn();
        setNewOrderUpd();// actualizar el array de las ordenes a actualizar
        onClose();
        setForm((val) => ({ ...val, action: "", comment: "", fecDespa: "", fecLlega: "", guiaDesp: "", carrier: "", numWR: "", dimen: "", peso: "" }));
        handleNotify(true, data?.msg, false, true);
        if (form.action === "0") {
          setNewStateParts();
        } else {
          changeUpdate(true);
          setNewStateParts();
        }
      } else if ([403, 404].includes(response.status)) {
        handleNotify(true, data?.msg, true);
      }

      sendServer(false);

    } catch (error) {
      console.log(error);
    }
  };




  return (
    <div className="background-modal">
      {
        STEP_UPDATE.includes(orderUpdate[0]?.IdEtapa) && ( // esto es para que no se actualice una parte que no esté en la etapa de orden de compra
          <form className="container-select-customer" onSubmit={onUpdateOrder}>
            <div className="modal-header">
              <h5 className="txt-center m-y-small" style={{ color: 'var(--first-color)' }}>{t("TableParts.partes_actualizar")}</h5>
              <button type="button" className="btn-close" onClick={onClose}>
                <Close widthP="23" heightP="23" />
              </button>
            </div>
            {/* FILA */}
            <div className="modal-body">
              <div className="row-flex">
                <div className="col-4">
                  <strong>{t("RequestPartsView.accion")}</strong>
                  <select
                    name="action"
                    value={form.action}
                    onChange={({ target }) => handleChangeFormVal("action", target.value)}>
                    <option value=""></option>
                    <option value="0">{t("RequestPartsView.comentario")}</option>
                    {
                      orderUpdate[0]?.IdEtapa === 36 && ( // fecha de despacho
                        <>
                          <option value="1">{t("RequestPartsView.transito")} {t("RequestPartsView.siguiente")}</option>
                          <option value="2">{t("RequestPartsView.guia_desp")} {t("RequestPartsView.anterior")}</option>
                        </>
                      )
                    }
                    {
                      // Etapa de Despacho al cliente
                      (orderUpdate[0]?.IdEtapa === 34) && (
                        <>
                          <option value="1">{t("RequestPartsView.nacio_cliente")} {t("RequestPartsView.siguiente")}</option>
                          <option value="2">{t("RequestPartsView.recep_miami")} {t("RequestPartsView.anterior")}</option>
                        </>
                      )
                    }
                    {
                      // Etapa de nacionalizacion cliente
                      (orderUpdate[0]?.IdEtapa === 35) && (
                        <>
                          <option value="1">{t("RequestPartsView.rec_clien")} {t("RequestPartsView.siguiente")}</option>
                          <option value="2">{t("RequestPartsView.desp_clien")} {t("RequestPartsView.anterior")}</option>
                        </>
                      )
                    }
                    {
                      orderUpdate[0]?.IdEtapa === 38 && ( // nacionalizacion
                        <>
                          <option value="1">{t("RequestPartsView.por_lleg_alma")} {t("RequestPartsView.siguiente")}</option>
                          <option value="2">
                            {orderUpdate[0]?.CodZona === zonas.GMDH ? t("RequestPartsView.transito_gmdh") : t("RequestPartsView.transito")} {t("RequestPartsView.anterior")}
                          </option>
                        </>
                      )
                    }
                    {
                      orderUpdate[0]?.IdEtapa === 30 && ( // acuse de recibo
                        <>
                          <option value="1">{t("RequestPartsView.guia_desp")} {t("RequestPartsView.siguiente")}</option>
                          <option value="2">{t("RequestPartsView.recha_parte")}</option>
                          <option value="3">{t("RequestPartsView.compra_local")}</option>
                        </>
                      )
                    }
                    {
                      orderUpdate[0]?.IdEtapa === 31 && ( // guia de despacho
                        <>
                          <option value="1">{t("RequestPartsView.fecha_desp")} {t("RequestPartsView.siguiente")}</option>
                          <option value="2">{t("RequestPartsView.acu_recibo")} {t("RequestPartsView.anterior")}</option>
                        </>
                      )
                    }
                    {
                      orderUpdate[0]?.IdEtapa === 33 && ( // Etapa de Recepcion en Miami
                        <>
                          <option value="1">
                            {orderUpdate[0]?.CodZona === zonas.GMDH ? t("RequestPartsView.transito_gmdh") : t("RequestPartsView.desp_clien")} {t("RequestPartsView.siguiente")}
                          </option>
                          <option value="2">{t("RequestPartsView.transito")} {t("RequestPartsView.anterior")}</option>
                        </>
                      )
                    }
                    {
                      orderUpdate[0]?.IdEtapa === 37 && ( // en transito
                        <>
                          <option value="1">
                            {
                              [zonas.CARIBE, zonas.GMDH].includes(orderUpdate[0]?.CodZona) && (
                                t("RequestPartsView.recep_miami")
                              )
                            }
                            {
                              orderUpdate[0]?.CodZona === zonas.RD && (
                                t("RequestPartsView.nacionali")
                              )
                            }
                            {t("RequestPartsView.siguiente")}
                          </option>
                          <option value="2">{t("RequestPartsView.fecha_desp")} {t("RequestPartsView.anterior")}</option>
                        </>
                      )
                    }
                    {
                      // Etapa de transito a GMDH
                      (orderUpdate[0]?.IdEtapa === 48) && (
                        <>
                          <option value="1">{t("RequestPartsView.nacionali")} {t("RequestPartsView.siguiente")}</option>
                          <option value="2">{t("RequestPartsView.recep_miami")} {t("RequestPartsView.anterior")}</option>
                        </>
                      )
                    }
                  </select>
                </div>
              </div>

              {
                [zonas.CARIBE, zonas.GMDH].includes(orderUpdate[0]?.CodZona) && (
                  <>
                    {
                      // Si la etapa es en transito
                      orderUpdate[0]?.IdEtapa === 37 && (
                        <div className="row-flex">
                          <div className="col-4">
                            <strong>{t("RequestPartsView.wr_numero")}</strong>
                            <input
                              className="input-text-control"
                              type="text"
                              name="numWR"
                              value={form.numWR}
                              onChange={({ target }) => handleChangeFormVal("numWR", target.value)}
                              autoComplete="off" />
                          </div>
                          <div className="col-4">
                            <strong>{t("RequestPartsView.dimensiones")}</strong>
                            <input
                              className="input-text-control"
                              type="text"
                              name="dimen"
                              value={form.dimen}
                              onChange={({ target }) => handleChangeFormVal("dimen", target.value)} />
                          </div>
                          <div className="col-4">
                            <strong>{t("RequestPartsView.peso")}</strong>
                            <input
                              className="input-text-control"
                              type="text"
                              name="peso"
                              value={form.peso}
                              onChange={({ target }) => handleChangeFormVal("peso", target.value)}
                              autoComplete="off" />
                          </div>
                        </div>
                      )
                    }
                    {
                      // Si la etapa es en recibido en Miami
                      orderUpdate[0]?.IdEtapa === 33 && (
                        <div className="row-flex">
                          <div className="col-4">
                            <strong>
                              {orderUpdate[0]?.CodZona === zonas.GMDH ? t("RequestPartsView.guia_desp_gmdh") : t("RequestPartsView.guia_desp_cli")}
                            </strong>
                            <input
                              className="input-text-control"
                              type="text"
                              name="guiaDespClien"
                              value={form.guiDespCli}
                              onChange={({ target }) => handleChangeFormVal("guiDespCli", target.value)}
                              autoComplete="off" />
                          </div>
                          <div className="col-4">
                            <strong>Carrier</strong>
                            <input
                              className="input-text-control"
                              type="text"
                              name="carrierCli"
                              value={form.carrCli}
                              onChange={({ target }) => handleChangeFormVal("carrCli", target.value)} />
                          </div>
                          <div className="col-4">
                            <strong>{t("RequestPartsView.fecha_desp")}</strong>
                            <input
                              className="input-text-control"
                              type="date"
                              name="fecDesCli"
                              value={form.fecDespCli}
                              onChange={({ target }) => handleChangeFormVal("fecDespCli", target.value)} />
                          </div>
                        </div>
                      )
                    }
                  </>
                )
              }
              {
                // Fila condicional
                orderUpdate[0]?.IdEtapa === 31 && ( // guia de despacho
                  <>
                    {/* FILA */}
                    <div className="row-flex">
                      {/* Guia de despacho */}
                      <div className="col-4">
                        <strong>{t("RequestPartsView.guia_desp")}</strong>
                        <input
                          className="input-text-control"
                          type="text"
                          name="tracking-num"
                          value={form.guiaDesp}
                          onChange={({ target }) => handleChangeFormVal("guiaDesp", target.value)} />
                      </div>
                      <div className="col-4">
                        <strong>Carrier</strong>
                        <input
                          className="input-text-control"
                          type="text"
                          name="carrier"
                          value={form.carrier}
                          onChange={({ target }) => handleChangeFormVal("carrier", target.value)} />
                      </div>
                    </div>
                  </>
                )
              }

              {/* FILA condicional */}
              {
                orderUpdate[0]?.IdEtapa === 30 && ( // acuse de recibido
                  <div className="row-flex">
                    {/* SO */}
                    <div className="col-4">
                      <strong>SO / SOA</strong>
                      <input
                        className="input-text-control"
                        type="text"
                        name="so"
                        value={form.soNumber}
                        onChange={({ target }) => handleChangeFormVal("soNumber", target.value)}
                        autoComplete="off" />
                    </div>
                  </div>
                )
              }

              {/* FILA Condicional */}
              {
                orderUpdate[0]?.IdEtapa === 36 && ( // fecha de despacho
                  <>
                    {/* FILA */}
                    <div className="row-flex">
                      {/* Fecha de despacho */}
                      <div className="col-4">
                        <strong>{t("RequestPartsView.fecha_desp")}</strong>
                        <input
                          className="input-date-styles"
                          type="date"
                          name="ship-date"
                          value={form.fecDespa}
                          onChange={({ target }) => handleChangeFormVal("fecDespa", target.value)} />
                      </div>
                      <div className="col-4">
                        <strong>{t("RequestPartsView.fec_estim_lle")}</strong>
                        <input
                          className="input-date-styles"
                          type="date"
                          name="arrival-date"
                          value={form.fecLlega}
                          onChange={({ target }) => handleChangeFormVal("fecLlega", target.value)} />
                      </div>
                    </div>
                  </>
                )
              }

              {/* FILA */}
              <div className="row-flex">
                {/* Comentario */}
                <div className="flex-grow">
                  <strong>{t("RequestPartsView.comentario")}</strong>
                  <textarea
                    className="textarea-description"
                    name="comment"
                    value={form.comment}
                    onChange={({ target }) => handleChangeFormVal("comment", target.value)}
                    autoComplete="off" />
                </div>
              </div>

              {/* Tabla para mostrar las partes */}
              <div className="table-container-mobile m-y-small">
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th>{t("TableParts.orden")}</th>
                      <th>{t("TableParts.canti")}</th>
                      <th>{t("TableParts.parte")}</th>
                      <th>{t("TableParts.descrip")}</th>
                      <th>{t("equipmentView.canti")}</th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {
                      orderUpdate && orderUpdate.map((ord, index) => (
                        <tr key={index + 1}>
                          <td className="txt-center">{ord.NumOrden}</td>
                          <td className="txt-center">{ord.Cantidad}</td>
                          <td>
                            <Link className="link-tag">{ord.NumParte}</Link>
                          </td>
                          <td>{ord.Descripcion}</td>
                          <td>
                            <input
                              className="input-text-control txt-center"
                              type="text"
                              name={`cantSep-${ord.CodSolRepuesto}-${ord.CodDetSolRepuesto}`}
                              value={ord.CantMover}
                              onChange={({ target }) => handleChangePartVal(target.value, ord.CodSolRepuesto, ord.CodDetSolRepuesto)} />
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>


            {/* Botones de acción */}
            <div className="modal-footer j-content-space-around m-y-medium">
              <button
                type="submit"
                className="btn-primary"
                disabled={state.loadingData}>{state.loadingData ? t("serviceCard.guardando") : t("RequestPartsView.guar")}</button>
              <button type="reset" className="btn-secondary" onClick={onClose}>{t("RequestPartsView.cerrar")}</button>
            </div>

          </form>
        )
      }
    </div>
  );
};