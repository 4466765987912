
// custom hook para traer el detalle de las notas de entrega

import { useEffect, useState } from "react";

// imports
import { urls } from "../../../api-urls/api-urls";
import { getTheToken } from "../../../global-functions/globalFunctions";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";





export const useFetchNotsEntrega = (CodSol = "", language = "") => {

  // state definition
  const [form, setForm] = useState({ formaEnvio: "", tecnicoEnv: "", enviadoA: "", codZona: 0, codServicio: 0, comment: "", primaryAction: "" });
  const [controller, setController] = useState(null);
  const [state, setState] = useState({ isLoading: false, dataVisible: false, showModal: false, question: "" });
  const [partes, setPartes] = useState([]);
  const [employees, setEmployees] = useState([]); // estado para cargar los empleados
  const [stateSol, setStateSol] = useState({});

  // GET the react translation
  const [t] = useTranslation("global");

  // GET the useNavigate
  const navigate = useNavigate();


  const onHandleSendModal = () => {
    setForm((val) => ({ ...val, primaryAction: "1" }));

    // Verificar que la forma de envio, enviado a y tecnico a enviar estén seleccionado
    if (form.formaEnvio === "") {
      // return setNotification({ show: true, msg: t("RequestPartsView.forma_envio"), error: true, great: false, success: false });
    }

    if (form.enviadoA === "") {
      // return setNotification({ show: true, msg: t("RequestPartsView.selec_envia"), error: true, great: false, success: false });
    }

    setState((val) => ({ ...val, showModal: true, question: t("RequestPartsView.enviar_conf_tecni") }));

  };


  // MENSAJES AUTOMATICOS
  const FORM_ENV_PERSONAL = form.codZona === 1 ? "Entregado personalmente " : "Delivered personally ";
  const FORM_ENV_TRANS_INT = form.codZona === 1 ? "Enviado por transporte interno " : "Shipped by internal transport ";
  const FORM_ENV_TRANS_EXT = form.codZona === 1 ? "Enviado por transporte externo " : "Shipped by external transport ";
  const FORM_ENV_TAXI = form.codZona === 1 ? "Enviado por taxi " : "Shipped by taxi ";

  const ENVIADO_A_CLIENTE = form.codZona === 1 ? "Directo al cliente." : "Direct customer.";


  // funcion para cambiar el valor del formulario
  const handleChangeForm = (name = "", newVal = "") => {
    setForm((val) => ({ ...val, [name]: newVal }));
  };

  // onClose modal
  const onCloseModal = () => {
    setState((val) => ({ ...val, showModal: !state.showModal }));
  };


  // funcion para cambiar el serial
  const handleChangeSerial = (codDet = 0, codSol = 0, newVal = "") => {
    setPartes((val) => {
      return val.map((part) => {
        if (codDet === part.CodDetSolRepuesto && codSol === part.CodSolRepuesto) {
          return { ...part, Serial: newVal };
        }
        return part;
      });
    });
  };



  const fetchDataServer = async () => {

    const abortController = new AbortController();

    setController(abortController);

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.notaEntrega}/${CodSol}/search?language=${language}&print=0`, {
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        signal: abortController.signal
      });

      const { solRep, detSolRep, employees } = await response.json();

      if (response.status === 200) {
        setStateSol(solRep[0]);
        setPartes(detSolRep);
        setEmployees(employees);
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 401) {
        // localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 400) {
        // bad request
      } else if (response.status === 403) {
        setState((val) => ({ ...val, dataVisible: false, unauthorized: true }));
      } else if (response.status === 500) {
        // ocurrio un error interno en el servidor
      }

      setState((val) => ({ ...val, isLoading: false }));
    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para cuando el enviadoa cambie
  useEffect(() => {
    if (form.enviadoA === "0") {
      const employeeFound = employees && employees.find((emp) => emp.CodEmpleado === Number(form.tecnicoEnv));


      setForm((val) => ({ ...val, comment: `${form.comment} ${employeeFound?.NomEmpleado}` }));
    } else {

    }
  }, [form.enviadoA]);

  // useEffect para cuando cambie la forma de envio
  useEffect(() => {
    let MENSAJE = "";

    switch (form.formaEnvio) {
      case "0": // Personal
        MENSAJE = FORM_ENV_PERSONAL;
        break;
      case "1": // Transporte interno
        MENSAJE = FORM_ENV_TRANS_INT;
        break;
      case "2": // Transporte externo
        MENSAJE = FORM_ENV_TRANS_EXT;
        break;
      case "3": // Taxi
        MENSAJE = FORM_ENV_TAXI;
        break;
    }

    setForm((val) => ({ ...val, comment: MENSAJE }));

    if (form.formaEnvio === "") {
      setForm((val) => ({ ...val, enviadoA: "" }));
    }

  }, [form.formaEnvio]);

  useEffect(() => {

    if (stateSol) {
      setForm((val) => ({ ...val, tecnicoEnv: stateSol?.CodEmpIngreso, codZona: stateSol?.CodZona }));
    }

  }, [stateSol]);


  // useEffect para solicitar la data
  useEffect(() => {

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [CodSol, language]);


  // retornar el objeto
  return {
    state,
    form,
    partes,
    employees,
    stateSol,
    handleChangeForm,
    handleChangeSerial,
    onCloseModal,
    onHandleSendModal
  }
};