
// REACT IMPORTATIONS
import { useState } from "react";

// USER CONTEXT IMPORTATION
import { UserContext } from "./UserContext.jsx";

export const UserProvider = ({ children }) => {

  // STATE DEFINITION
  const [user, setUser] = useState(null);
  
  const setUserContext = (user) => {
    setUser(user);
  };


  return (
    <UserContext.Provider value={{ user, setUserContext }}>
      {children}
    </UserContext.Provider>
  );

};