// useFetch aprobacion administrativa

// imports
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";






export const useFetchAdminisAppro = (CodSol = "", urls = {}, token = "", lang = "") => {


  // state definition
  const [state, setState] = useState({ isLoading: false, dataVisible: false, question: "", showModal: false, error404: false, sending: false });
  const [form, setForm] = useState({ tipApro: "", action: "0", comment: "", workflow: "", primaryAction: "" });
  const [notify, setNotify] = useState({ show: false, msg: "", error: false, great: false, succes: false });
  const [controller, setController] = useState(null);
  const [partes, setPartes] = useState([]); // controlador para guardar las partes
  const [solState, setSolState] = useState({});

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the react translation
  const [t] = useTranslation("global");


  // funcion para las notificaciones
  const handleNotify = (msgP = "", errorP = false, greatP = false, successP = false) => {
    setNotify((val) => ({ ...val, show: true, msg: msgP, error: errorP, great: greatP, succes: successP }));
  };

  // funcion para actualizar el enviar
  const handleSendServer = (newVal = false) => {
    setState((val) => ({ ...val, sending: newVal }));
  };

  // clear notify
  const handleClearNotify = () => {
    setNotify((val) => ({ ...val, show: false, msg: "", error: false, great: false, succes: false }));
  };

  // funcion para cambiar los valores del formulario
  const handleChangeForm = (name = "", newVal = "") => {
    setForm((val) => ({ ...val, [name]: newVal }));
  };

  // funcion para abrir el cerrar el modal de confirmar
  const handleCloseOpenModal = () => {
    setState((val) => ({ ...val, showModal: !state.showModal }));
  };

  // funcion para realizar un comentario
  const handleComment = () => {
    setState((val) => ({ ...val, question: t("RequestPartsView.no_selec_accion"), showModal: true }));
    setForm((val) => ({ ...val, primaryAction: "1" }));
  };

  // funcion para devolver la solicitud
  const handleReturnRequest = () => {
    setForm((val) => ({ ...val, primaryAction: "3" }));
    setState((val) => ({ ...val, showModal: true, question: t("RequestPartsView.seguro_enviar_apro_tecnica") }));
  };

  // funcion para guardar la informacion
  const handleSaveInfo = () => {
    if (!form.tipApro && form.action === "0") {
      return handleNotify(t("RequestPartsView.selec_tip_apro_accion"), true);
    } else if (form.action === "0") {
      return handleNotify(t("RequestPartsView.sele_accion"), true);
    } else if (form.action !== "0" && !form.tipApro) {
      return handleNotify(t("RequestPartsView.selec_tip_apro"), true);
    }

    if (!form.tipApro && form.action !== "0") {
      return handleNotify(t("RequestPartsView.selec_tip_apro"), true);
    }

    setForm((val) => ({ ...val, primaryAction: "2" }));
    setState((val) => ({ ...val, showModal: true, question: t("RequestPartsView.seguro_enviar_etapa_sele") }));
  };

  // funcion para limpiar el formulario
  const handleClearForm = () => {
    setForm((val) => ({ ...val, tipApro: "", action: "0", comment: "", workflow: "" }))
  };


  // funcion para cambiar los valores
  const handleChangeValParts = (CodSol = 0, CodDetSol = 0, newVal, action = 0) => {
    setPartes((val) => {
      return val.map((part) => {
        if (CodSol === part.CodSolRepuesto && CodDetSol === part.CodDetSolRepuesto) {
          if (action === 1) {
            return { ...part, Devolver: newVal };
          }
          return { ...part, Aprobar: newVal };
        }
        return part;
      });
    });
  };


  const fetchDataServ = async () => {
    const abortController = new AbortController();

    setController(abortController);
    try {
      setState((val) => ({ ...val, isLoading: true }));

      const response = await fetch(`${urls.administrativeAppro}/${CodSol}/search?language=${lang}`, {
        headers: {
          "Authorization": token,
          "Content-Type": "application/json"
        }
      });

      const { solRep, detSolRep } = await response.json();


      if (response.status === 200) {
        // colocar la data en el estado
        setSolState(solRep[0]);
        setPartes(detSolRep);
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 400) {
        // Si hay un bad request
      } else if (response.status === 401) {
        // localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        setState((val) => ({ ...val, dataVisible: false, unauthorized: true }));
      } else if (response.status === 404) {
        setState((val) => ({ ...val, dataVisible: false, error404: true }));
        console.log("Holaaa, no hay nada")
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para traer los datos del servidor
  useEffect(() => {

    fetchDataServ();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [CodSol, lang]);

  // useEffect para recargar la data
  useEffect(() => {
    if (notify.succes) {
      fetchDataServ();

      return () => {
        if (controller) {
          controller.abort();
        }
      };
    }
  }, [notify.succes]);



  return {
    state,
    form,
    partes,
    solState,
    notify,
    handleChangeForm,
    handleCloseOpenModal,
    handleComment,
    handleSaveInfo,
    handleReturnRequest,
    handleChangeValParts,
    handleNotify,
    handleClearNotify,
    handleClearForm,
    handleSendServer
  };
};