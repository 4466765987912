// custom hook para trabajar la data de la aprobacion de soporte

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";


export const useFetchAproSoporte = (CodSolRep = "", token = "", urls = {}, lang = "") => {

  // state definition
  const [state, setState] = useState({ isLoading: false, dataVisible: false, showModal: false, fileModal: false, empSolicita: 0, question: "", unauthorized: false, serverError: false, sending: false });
  const [controller, setController] = useState(null); // controlador para cancelar la data al servidor
  const [form, setForm] = useState({ comment: "", primaryAction: "" });
  const [partes, setPartes] = useState([]); // Estado para las partes
  const [solState, setSolState] = useState({}); // Estado para la solicitud
  const [empPerm, setEmpPerm] = useState([]); // Estado para los permisos de usuarios
  const [notify, setNotify] = useState({ show: false, msg: "", error: false, great: false, succes: false });

  const navigate = useNavigate();

  // funcion para manejar las notificaciones
  const handleNotify = (showP = false, msgP = "", errorP = false, greatP = false, succesP = false) => {
    setNotify((val) => ({ ...val, show: showP, msg: msgP, error: errorP, great: greatP, succes: succesP }));
  };


  // funcion para cambiar los valores en el formulario
  const handleChangeForm = (name = "", newVal = "") => {
    setForm((val) => ({ ...val, [name]: newVal }));
  };

  // funcion para abrir y cerrar el modal de subir archivos
  const handleOpenCloseFilesModal = () => {
    setState((val) => ({ ...val, fileModal: !state.fileModal }));
  };

  // funcion para abrir el modal de enviar a la siguiente etapa
  const handleSendModal = () => {
    setState((val) => ({ ...val, showModal: true, question: "Está seguro que desea enviar a orden de compra?" }));
    setForm((val) => ({ ...val, primaryAction: "1" }));
  };

  // funcion para abrir el modal y retornar etapa
  const handleReturnModal = () => {
    setState((val) => ({ ...val, showModal: true, question: "Está seguro que desea enviar a aprobación técnica?" }));
    setForm((val) => ({ ...val, primaryAction: "2" }));
  };

  // funcion para bloquear el boton cuando se envie al servidor
  const handleUpdateData = (newVal = false) => {
    setState((val) => ({ ...val, sending: newVal }));
  };

  // funcion para abrir o cerrar el modal de confirmacion
  const handleOpenCloseModal = () => {
    setState((val) => ({ ...val, showModal: !state.showModal }));
    setForm((val) => ({ ...val, primaryAction: "" }));
  };

  // funcion para limpiar el formulario
  const handleClearForm = () => {
    setForm((val) => ({ ...val, comment: "", primaryAction: "" }));
  };


  const fetchDataServer = async () => {
    const abortController = new AbortController();

    setController(abortController);

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.supportApproval}/${CodSolRep}/search?language=${lang}`, {
        headers: {
          "Authorization": token,
          "Content-Type": "application/json"
        },
        signal: abortController.signal
      });

      const { solRep, detSolRep, empPerm } = await response.json();

      if (response.status === 200) {
        setState((val) => ({ ...val, dataVisible: true }));
        setSolState(solRep[0]);
        setPartes(detSolRep);
        setEmpPerm(empPerm);
        setState((val) => ({ ...val, empSolicita: solRep[0]?.CodEmpIngreso }));
      } else if (response.status === 400) {
        // bad request
      } else if (response.status === 401) {
        // localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        setState((val) => ({ ...val, unauthorized: true, dataVisible: false }));
      } else if (response.status === 500) {
        setState((val) => ({ ...val, serverError: true }));
      }

      setState((val) => ({ ...val, isLoading: false }));
    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para traer la data del servidor
  useEffect(() => {

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [CodSolRep, lang]);


  return {
    state,
    form,
    notify,
    partes,
    solState,
    empPerm,
    handleChangeForm,
    handleSendModal,
    handleReturnModal,
    handleUpdateData,
    handleOpenCloseModal,
    handleOpenCloseFilesModal,
    handleClearForm,
    handleNotify
  }
};