// modal para confirmar el envio del formulario al servidor

import { useTranslation } from "react-i18next";

// icons importation
import { QuestionIcon } from "../icons/AppIcons.jsx";






export const ModalConfirm = ({ onClose, question = "", sending = false }) => {

  // GET the react translation
  const [t] = useTranslation("global");

  return (
    <div className="background-modal" onClick={onClose}>
      <div className="container-modal-confirm" onClick={(event) => event.stopPropagation()}>
        {/* Question mark */}
        <div className="row-flex m-y-small align-c justify-center">
          <div className="question-mark-container">
            <QuestionIcon />
          </div>
        </div>
        <h5 style={{ textAlign: 'center', color: 'var(--first-color)' }}>
          {question}
        </h5>
        {/* Botones de accion */}
        <div className="row-flex m-y-medium j-content-space-around">
          <button type={sending ? "button" : "submit"} className="btn-success">
            {
              sending ? t("serviceCard.guardando") : t("RequestPartsView.si")
            }
          </button>
          <button type="button" className="btn-danger" onClick={onClose}>No</button>
        </div>
      </div>
    </div>
  );
};