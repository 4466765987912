import React, { useState, useEffect, useContext } from "react";

/*
---- Este componente es para ver el detalle de la solicitud y poder agregar las partes
*/

// REACT ROUTER DOM IMPORTATION
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

// COMPONENTS
import { LoadingComp } from "../../components/Loading-comp/LoadingComp.jsx";
import { AddPartComp } from "../../components/logistic-components/AddPartComp.jsx";
import { ModalConfirm } from "../../components/modal-confirm/ModalConfirm.jsx";
import { Add, Close } from "../../components/icons/AppIcons.jsx";
import { TemperSelect } from "../../components/serviceComponents/TemperSelect.jsx";

// USE TRANSLATION IMPORTATION
import { useTranslation } from "react-i18next";

// URLS IMPORTATION
import { urls } from "../../api-urls/api-urls.js";

// GLOBAL FUNCTIONS
import { getTheToken, renderDate } from "../../global-functions/globalFunctions.js";


// custom hook imports
import { useFetchSolRep } from "../../components/customs/service/useFetchSolRep.js";


// context import
import { LangContext } from "../../context/LangContext.jsx";
import { NotificationContext } from "../../context/NotificationContext.jsx";





export const SolRepuestoDetailView = () => {

  const [t] = useTranslation("global");


  // GET the lang context
  const { lang } = useContext(LangContext);

  // GET the notify context
  const { handleNotify } = useContext(NotificationContext);



  const { solRepuestoId } = useParams();

  // GET the custom hook
  const { state, form, solState, parts, EmpPerm, handlePerformAct, handleOpenCloseAddPart, handleChangeFormVal, handleOpenCloseModal, handleUpdateFromChild, handleSendServ, handleChangeTemp } = useFetchSolRep(solRepuestoId, getTheToken().token, urls, lang);


  // NAVIGATE
  const navigate = useNavigate();

  // GET the useLocation()
  const location = useLocation();



  // Etapas
  const SOL_REP = 10; // Solicitud de repuesto
  const ORDEN_COMPRA = 20; // Orden de compra




  // OnSubmit request para enviar a aprobacion tecnica
  const onSubmitRequest = async (event) => {
    event.preventDefault();

    try {
      handleSendServ(true);
      const response = await fetch(`${urls.partDetail}/${solRepuestoId}/search?language=${lang}`, {
        method: "PUT",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(form)
      });

      const data = await response.json();

      if (response.status === 200) {
        handleNotify(true, data?.msg, false, true);
        // hay que hacer un update
        handleUpdateFromChild(true);
        // setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true, newUpdate: true }));
        handleOpenCloseModal();
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        handleNotify(true, data?.msg, true);
      }

      handleSendServ();

    } catch (error) {
      console.log(error);
    }
  };


  return (
    <>
      {
        // loading component
        state.isLoading && (<LoadingComp />)
      }

      <div className="container display-data">
        {
          state.dataVisible && (
            <form onSubmit={onSubmitRequest}>
              {/* FILA 1 */}
              <div className="row-flex">
                {/* SOLICITUD */}
                <div className="col-9 col-sm-20">
                  <strong>{t("LogisticViewComp.request")}</strong>
                </div>
                <div className="col-8 col-sm-30">
                  <h5>{solRepuestoId}</h5>
                </div>
                {/* ID SERVICIO */}
                <div className="col-9 col-sm-20">
                  <strong>{t("LogisticViewComp.serviceID")}</strong>
                </div>
                <div className="col-8 col-sm-30">
                  <h5>
                    <Link className="link-tag" to={`/service/service-call/${solState?.CodServicio}`}>{solState?.CodServicio}</Link>
                  </h5>
                </div>
                {/* MODELO */}
                <div className="col-10 col-sm-20">
                  <strong>{t("LogisticViewComp.model")}</strong>
                </div>
                <div className="col-7 col-sm-30">
                  <span>{solState?.Modelo}</span>
                </div>
                {/* SERIAL */}
                <div className="col-10 col-sm-20">
                  <strong>Serial</strong>
                </div>
                <div className="col-sm-30">
                  <span>{solState?.Serial}</span>
                </div>
              </div>

              {/* FILA 2 */}
              <div className="row-flex">
                {/* FECHA/HORA */}
                <div className="col-9 col-sm-20">
                  <strong>{t("LogisticViewComp.dateTime")}</strong>
                </div>
                <div className="col-8 col-sm-30">
                  <span>{renderDate(solState?.FecIngreso, lang)}</span>
                </div>
                {/* SUPLIDOR */}
                <div className="col-9 col-sm-20">
                  <strong>{t("LogisticViewComp.supplier")}</strong>
                </div>
                <div className="col-3 col-sm-30">
                  <span>{solState?.NomProveedor}</span>
                </div>
                {/* INSTALACION */}
                <div className="col-10 col-sm-20">
                  <strong>{t("LogisticViewComp.instalation")}</strong>
                </div>
                <div className="col-sm-30">
                  <span></span>
                </div>
              </div>

              {/* FILA 3 */}
              <div className="row-flex">
                {/* TIPO DE SOLICITUD */}
                <div className="col-9 col-sm-20">
                  <strong>{t("TipSol.tip_sol")}</strong>
                </div>
                <div className="col-3 col-sm-80">
                  <select
                    name="tipSol"
                    value={form.tipSolicitud}
                    onChange={({ target }) => handleChangeFormVal("tipSolicitud", target.value)}>
                    <option value="0">{t("TipSol.coti")}</option>
                    <option value="3">Missing in Shipment</option>
                    <option value="1">{t("TipSol.garan")}</option>
                    <option value="4">Dead on Arrival</option>
                    <option value="2">{t("TipSol.contra_perfo")}</option>
                    <option value="5">{t("TipSol.ord_compr")}</option>
                  </select>
                </div>
                {/* GON */}
                <div className="col-9 col-sm-20">
                  <strong>GON</strong>
                </div>
                <div className="col-8 col-sm-30">
                  <span></span>
                </div>
                {/* O/C */}
                <div className="col-9 col-sm-20">
                  <strong>O/C</strong>
                </div>
                <div className="col-sm-30">
                  <span></span>
                </div>
              </div>

              {/* FILA 4 */}
              <div className="row-flex">
                {/* CLIENTE */}
                <div className="col-9 col-sm-20">
                  <strong>{t("LogisticViewComp.customer")}</strong>
                </div>
                <div className="col-3 col-sm-80 text-hidden-over">
                  <Link className="link-tag" to={`/customers/customer-detail/${solState?.CodCliente}`}>{solState?.NomCliente}</Link>
                </div>
                {/* CONTRATO */}
                <div className="col-9 col-sm-20">
                  <strong>{t("LogisticViewComp.contract")}</strong>
                </div>
                <div className="col-8 col-sm-80">
                  <span>{solState?.NomContrato}</span>
                </div>
                {/* PRIORIDAD */}
                <div className="col-9 col-sm-20">
                  <strong>{t("LogisticViewComp.priority")}</strong>
                </div>
                <div className="col-7 col-sm-80">
                  <TemperSelect updateParent={handleChangeTemp} value={form.priority} />
                </div>
              </div>

              {/* FILA 5 */}
              <div className="row-flex">
                {/* FALLA */}
                <div className="col-9 col-sm-20">
                  <strong>{t("LogisticViewComp.failure")}</strong>
                </div>
                <div className="col-3 col-sm-80">
                  <span style={{ color: 'var(--danger-color)', textDecoration: 'underline' }}>{solState?.Falla}</span>
                </div>
                {/* EQUIPO */}
                <div className="col-9 col-sm-20">
                  <strong>{t("LogisticViewComp.equipment")}</strong>
                </div>
                <div className="col-43 col-sm-80">
                  <Link className="link-tag" to={`/equipments/equipment-detail/${solState?.CodEquipo}`}>{solState?.Equipo}</Link>
                </div>
              </div>

              {/* FILA 6 */}
              <div className="row-flex">
                {/* INGENIERO */}
                <div className="col-9 col-sm-20">
                  <strong>{t("LogisticViewComp.tecnicalEng")}</strong>
                </div>
                <div className="col-3 col-sm-80">
                  <span>{solState?.NomEmpIngreso}</span>
                </div>
                {/* ETAPA */}
                <div className="col-9 col-sm-20">
                  <strong>{t("LogisticViewComp.etapa")}</strong>
                </div>
                <div className="col-sm-80">
                  <span style={{ color: 'var(--green-color)', fontWeight: 'bold', textDecoration: 'underline' }}>{solState?.NomEtapa}</span>
                </div>
              </div>

              {/* Boton para agregar las partes */}
              {
                solState?.IdEtapa <= ORDEN_COMPRA && (
                  <div className="row-flex">
                    <div className="col-9 col-sm-20">
                      <strong>{t("LogisticViewComp.agregar_part")}</strong>
                    </div>
                    <div className="col-9 col-sm-80">
                      <button type="button" className="btn-add" onClick={handleOpenCloseAddPart}>
                        <Add />
                      </button>
                    </div>
                  </div>
                )
              }

              {/* TABLA PARA MOSTRAR LAS PARTES QUE TIENE ESTA SOLICITUD */}
              {
                parts && parts.length > 0 ? (
                  <div className="table-container-mobile m-y-medium-table">
                    <table className="table">
                      <thead className="table-head">
                        <tr>
                          <th></th>
                          <th>#</th>
                          <th>{t("TableParts.canti")}</th>
                          <th>{t("TableParts.parte")}</th>
                          <th>{t("TableParts.descrip")}</th>
                          <th>{t("TableParts.eta")}</th>
                        </tr>
                      </thead>
                      <tbody className="table-body">
                        {
                          parts && parts.map((det) => (
                            <tr key={det.CodDetSolRepuesto}>
                              <td className="txt-center">
                                {solState?.IdEtapa <= ORDEN_COMPRA && (
                                  <button type="button" className="btn-delete" onClick={() => handlePerformAct(3, det.CodDetSolRepuesto, det.CodSolRepuesto)}>
                                    <Close />
                                  </button>
                                )}
                              </td>
                              <td className="txt-center">
                                <Link className="link-tag">{det.CodDetSolRepuesto}</Link>
                              </td>
                              <td className="txt-center">{det.Cantidad}</td>
                              <td>
                                <Link className="link-tag" to={`/part-detail/${det.CodDetSolRepuesto}/${det.CodSolRepuesto}`}>{det.NumParte}</Link>
                              </td>
                              <td>{det.Descripcion}</td>
                              <td style={{ color: det.IdEtapa === 99 && "green", textAlign: "center" }}>{det.NomEtapa}</td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                ) : (<div className="m-y-medium-table"><strong className="required" style={{ textDecoration: 'underline' }}>{t("PartModify.no_partes")}</strong></div>)
              }

              {/* BOTONES */}
              <div className="row-flex m-y-medium j-content-space-around">
                {
                  solState?.IdEtapa === SOL_REP && (<button type="button" className="btn-primary" onClick={() => handlePerformAct(1)}>{t("RequestPartsView.enviar")}</button>)
                }
                {/* Boton de eliminar */}
                {
                  solState && (solState?.IdEtapa <= ORDEN_COMPRA || (EmpPerm[0]?.Coordinador && EmpPerm[0]?.CodPerfil === 3) || EmpPerm[0]?.CodPerfil === 6) && (
                    <button type="button" className="btn-danger" onClick={() => handlePerformAct(2)}>{t("RequestPartsView.eliminar")}</button>
                  )
                }
                <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>{t("navigationMenu.backBtn")}</button>
              </div>

              {
                state.showModal && (
                  <ModalConfirm onClose={handleOpenCloseModal} question={state.question} sending={state.sending} />
                )
              }
            </form>
          )
        }


        {/* Componente para agregar partes */}
        {state.addPartComp && (<AddPartComp solRepuesto={solRepuestoId} onClose={handleOpenCloseAddPart} employeePerm={EmpPerm} onUpdate={handleUpdateFromChild} />)}

      </div >
    </>
  );
};