

// provider para el notificacion

// notification context import
import { useEffect, useState } from "react";

import { NotificationContext } from "./NotificationContext.jsx";

// Toast notification
import { ToastNotification } from "../components/toast-notification/ToastNotification.jsx";




export const NotificationProvider = ({ children }) => {

  const [notify, setNotify] = useState({ show: false, msg: "", error: false, great: false });

  // funcion para cambiar el estado
  const handleNotify = (showP = false, msgP = "", errorP = false, greatP = false) => {
    setNotify((val) => ({ ...val, show: showP, msg: msgP, error: errorP, great: greatP }));
  };

  // funcion para cerrar la notificacion
  const handleClose = () => {
    setNotify((val) => ({ ...val, show: false }));
  };


  // useEffect para limpiar las notificaciones
  useEffect(() => {
    if (notify.show) {
      const timer = setTimeout(() => {
        setNotify((val) => ({ ...val, show: false, msg: "", error: false, great: false, success: false }));
      }, 6000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [notify.show]);



  return (
    <NotificationContext.Provider value={{ handleNotify, handleClose }}>
      {
        notify.show && (
          <ToastNotification success={notify.great} error={notify.error} msg={notify.msg} />
        )
      }
      {children}
    </NotificationContext.Provider>
  );
};