import React, { useContext } from "react";

// icons importation
import { Close } from "../icons/AppIcons.jsx";


// notification context
import { NotificationContext } from "../../context/NotificationContext.jsx";



export const ToastNotification = ({ success, error, msg }) => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  const { handleClose } = useContext(NotificationContext);

  return (
    <>
      {/* Success toast */}
      {
        success && (
          <div className="container-toast">
            <div className="bg-right-success"></div>
            <div className="icon-toast row-flex justify-center align-c">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="var(--green-color)" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </svg>
            </div>
            <div className="body-toast flex-auto">
              <h5>{language === "en" ? "Success" : "Exitoso"}</h5>
              <strong>{msg}</strong>
              <button type="button" className="btn-close btn-p-abso" onClick={handleClose}>
                <Close fillP="var(--first-color)" />
              </button>
            </div>
          </div>
        )
      }
      {/* Error toast */}
      {
        error && (
          <div className="container-toast">
            <div className="bg-right-error"></div>
            <div className="icon-toast row-flex justify-center align-c">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="var(--danger-color)" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
              </svg>
            </div>
            <div className="body-toast flex-auto">
              <h5 style={{ color: 'var(--danger-color)' }}>Error</h5>
              <strong>{msg}</strong>
              <button type="button" className="btn-close btn-p-abso" onClick={handleClose}>
                <Close fillP="var(--first-color)" />
              </button>
            </div>
          </div>
        )
      }
    </>
  );
};