import React, { useEffect, useState, useContext } from "react";

// ---->Styles importation
import "../SideBarMenu/SideBarMenu.css";

// ---->Image importation
import logoGlobal from "../../assets/logo-global.png";
import logoGMDH from "../../assets/GMDHC - PNG.png";


// icons importation
import { Warehouse } from "../../components/icons/AppIcons.jsx";

// ----> React Router DOM Importation
import { NavLink, useLocation, useNavigate } from "react-router-dom";

// ---> Importations of useTranslation()
import { useTranslation } from "react-i18next";


// context importation language
import { LangContext } from "../../context/LangContext.jsx";
import { NotificationContext } from "../../context/NotificationContext.jsx";








export const SideBarMenu = ({ handleClick, dataSer }) => {

  // -----> useState() to open the list containers
  const [equipment, setEquipment] = useState(false);
  const [customer, setCustomer] = useState(false);
  const [service, setService] = useState(false);
  const [logistic, setLogistics] = useState(false);
  const [sales, setSales] = useState(false);
  const [travelExpen, setTravelExpen] = useState(false);
  const [hr, setHr] = useState(false);
  const [wareHouse, setWareHouse] = useState(false);
  const [administration, setAdministration] = useState(false);
  const [commercial, setCommercial] = useState(false);
  const [dataGIS, setDataGIS] = useState(false);


  // language context
  const { handleChangeLang, lang } = useContext(LangContext);

  // notification context
  const { handleNotify } = useContext(NotificationContext);

  // VERSIONADO
  const version = {
    x: "2",
    y: "0",
    z: "0"
  };


  // rutas para los pendientes
  const RouteName = {
    1: "/my-tasks/service",
    3: "/my-tasks/service",
    // 3: "/my-tasks/operation",
    10: "/my-tasks/site-planning",
    12: "/my-tasks/commercial",
  };

  const location = useLocation();

  const navigate = useNavigate();

  const onNavigateHome = () => {
    navigate("/home");
    handleClick();
  };


  // ----> useTranslation() hook importation
  const [t, i18n] = useTranslation("global");


  const onChangeLanguage = (newVal = "") => {
    
    const FIN_TXT = newVal === "en" ? "The language is English (en)" : "El idioma es Español (es)";

    i18n.changeLanguage(newVal);
    handleChangeLang(newVal);
    localStorage.setItem("language", newVal);
    handleNotify(true, FIN_TXT, false, true);

  };


  // funcion para navegar a pendientes y cerrar el modal del sidebar
  const onNaviPendings = () => {
    navigate(RouteName[dataSer[0]?.CodPerfil] ?? "/home");
    handleClick();
  };

  // funcion para navegar a las reservaciones
  const onNaviReservations = () => {
    navigate("/reservations");
    handleClick();
  };


  // ----> Logout to the App
  const onLogOut = () => {
    localStorage.setItem("lastNav", location.pathname);
    localStorage.removeItem("userInfo");
    navigate("/");
  };


  // GET THE USER INFO string format
  const userInfo = localStorage.getItem("userInfo");

  const userInfoParsed = JSON.parse(userInfo);

  return (
    <aside className="fixed-sidebar" onClick={(event) => event.stopPropagation()}>
      <div className="logo-global-menu">
        <img src={dataSer && (dataSer[0]?.CodEmpresa === "1" ? logoGlobal : logoGMDH)} alt="Logo Global" />
      </div>
      <button
        style={{
          borderRadius: '50%',
          fontWeight: 'bold',
          position: 'absolute',
          left: '100%',
          top: '1rem',
          cursor: 'pointer',
          padding: '0.3rem 0.5rem'
        }}
        onClick={handleClick}>X</button>

      <div className="navbar-container">
        <div className="item-container">
          <button className={`header-button ${location.pathname === '/home' ? 'button-active-class' : ''}`} onClick={onNavigateHome}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house" viewBox="0 0 16 16">
              <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5ZM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5 5 5Z" />
            </svg>
            {t("navigationMenu.home")}
          </button>
        </div>

        {/* ----> Sesion para mis pendientes <---- */}
        <div className="item-container">
          <button className={`header-button ${location.pathname === "/my-tasks" ? "button-active-class" : ""}`} onClick={onNaviPendings}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-ui-checks-grid" viewBox="0 0 16 16">
              <path d="M2 10h3a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1zm9-9h3a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-3a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zm0 9a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-3zm0-10a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h3a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2h-3zM2 9a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h3a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H2zm7 2a2 2 0 0 1 2-2h3a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2v-3zM0 2a2 2 0 0 1 2-2h3a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm5.354.854a.5.5 0 1 0-.708-.708L3 3.793l-.646-.647a.5.5 0 1 0-.708.708l1 1a.5.5 0 0 0 .708 0l2-2z" />
            </svg>
            {t("navigationMenu.pending")}
          </button>
        </div>
        <div className="item-container">
          <button className={`header-button ${location.pathname === "/reservations" ? "button-active-class" : ""}`} onClick={onNaviReservations}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar2-week-fill" viewBox="0 0 16 16">
              <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5m9.954 3H2.545c-.3 0-.545.224-.545.5v1c0 .276.244.5.545.5h10.91c.3 0 .545-.224.545-.5v-1c0-.276-.244-.5-.546-.5M8.5 7a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zM3 10.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5m3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z" />
            </svg>
            {t("navigationMenu.reserva")}
          </button>
        </div>
        {/* ----> Customers Section <---- */}
        <div className="item-container">
          <button className={`header-button ${location.pathname === '/customers' ? 'button-active-class' : ''}`} onClick={() => setCustomer(!customer)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" className="bi bi-people-fill" viewBox="0 0 16 16">
              <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
            </svg>
            {t("navigationMenu.customer")}
          </button>
        </div>
        {
          customer && (
            <div className="drop-down-items">
              <ul>
                <li>
                  <NavLink className="navbar-link" to="/customers/new-customer" onClick={handleClick}>
                    {t("customerView.newCustomer")}
                  </NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/customers" onClick={handleClick}>
                    {t("navigationMenu.customerList")}
                  </NavLink>
                </li>
              </ul>
            </div>
          )
        }
        {/* ----> Another Section <---- */}
        <div className="item-container">
          <button className={`header-button ${location.pathname === '/equipments' ? 'button-active-class' : ''}`} onClick={() => setEquipment(!equipment)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pc-display-horizontal" viewBox="0 0 16 16">
              <path d="M1.5 0A1.5 1.5 0 0 0 0 1.5v7A1.5 1.5 0 0 0 1.5 10H6v1H1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-5v-1h4.5A1.5 1.5 0 0 0 16 8.5v-7A1.5 1.5 0 0 0 14.5 0h-13Zm0 1h13a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5ZM12 12.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm2 0a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0ZM1.5 12h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1ZM1 14.25a.25.25 0 0 1 .25-.25h5.5a.25.25 0 1 1 0 .5h-5.5a.25.25 0 0 1-.25-.25Z" />
            </svg>
            {t("navigationMenu.equipment")}
          </button>
        </div>
        {
          equipment && (
            <div className="drop-down-items">
              <ul>
                <li>
                  <NavLink className="navbar-link" to="/equipments/create-equipment/equipment" onClick={handleClick}>
                    {t("navigationMenu.newEquipment")}
                  </NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/equipments" onClick={handleClick}>
                    {t("navigationMenu.showEquipments")}
                  </NavLink>
                </li>
              </ul>
            </div>
          )
        }
        {/* ----> Service Section <---- */}
        <div className="item-container">
          <button className={`header-button ${service ? 'button-active-class' : ''}`} onClick={() => setService(!service)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-tools" viewBox="0 0 16 16">
              <path d="M1 0 0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.27 3.27a.997.997 0 0 0 1.414 0l1.586-1.586a.997.997 0 0 0 0-1.414l-3.27-3.27a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3c0-.269-.035-.53-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814L1 0Zm9.646 10.646a.5.5 0 0 1 .708 0l2.914 2.915a.5.5 0 0 1-.707.707l-2.915-2.914a.5.5 0 0 1 0-.708ZM3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026L3 11Z" />
            </svg>
            {t("navigationMenu.service")}
          </button>
        </div>
        {
          service && (
            <div className="drop-down-items">
              <ul>
                <li>
                  <NavLink className="navbar-link" to="/service/scheduling" onClick={handleClick}>{t("navigationMenu.schedule")}</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/service" onClick={handleClick}>
                    {t("navigationMenu.service")}
                  </NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/new-call" onClick={handleClick}>
                    {t("navigationMenu.newCall")}
                  </NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/service/fmi" onClick={handleClick}>{t("navigationMenu.fmiStb")}</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/warehouse/technical-approval" onClick={handleClick}>{t("navigationMenu.technicalApproval")}</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/warehouse/administrative-approval" onClick={handleClick}>{t("navigationMenu.adminApproval")}</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/service/reports" onClick={handleClick}>
                    {t("navigationMenu.reports")}
                  </NavLink>
                </li>
              </ul>
            </div>
          )
        }
        {/* ----> Logistic Section <----- */}
        <div className="item-container">
          <button className={`header-button ${logistic ? 'button-active-class' : ''}`} onClick={() => setLogistics(!logistic)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-truck" viewBox="0 0 16 16">
              <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
            </svg>
            {t("navigationMenu.logistic")}
          </button>
        </div>
        {
          logistic && (
            <div className="drop-down-items">
              <ul>
                <li>
                  <NavLink className="navbar-link" to="/logistic-principal" onClick={handleClick}>
                    {t("navigationMenu.pending")}
                  </NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/logistic-follow-up" onClick={handleClick}>{t("navigationMenu.monitoring")}</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/supplier-data" onClick={handleClick}>{t("navigationMenu.supplier")}</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/ship-to" onClick={handleClick}>Ship To</NavLink>
                </li>
                <li><NavLink className="navbar-link" to="/logistic-reports-view" onClick={handleClick}>{t("navigationMenu.reports")}</NavLink></li>
              </ul>
            </div>
          )
        }
        {/* ----> Post Venta Section */}
        <div className="item-container">
          <button className={`header-button ${sales ? 'button-active-class' : ''}`} onClick={() => setSales(!sales)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cash-coin" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z" />
              <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z" />
              <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z" />
              <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z" />
            </svg>
            {t("navigationMenu.sales")}
          </button>
        </div>
        {
          sales && (
            <div className="drop-down-items">
              <ul>
                <li>{t("navigationMenu.pending")}</li>
                <li>
                  <NavLink className="navbar-link" to="/post-sales/proposal" onClick={handleClick}>{t("navigationMenu.proposal")}</NavLink>
                </li>
                <li>{t("navigationMenu.contract")}</li>
                <li>
                  <NavLink className="navbar-link" to="/post-sales/quotations" onClick={handleClick}>{t("navigationMenu.quotation")}</NavLink>
                </li>
                <li>{t("navigationMenu.reports")}</li>
              </ul>
            </div>
          )
        }
        {/* ----> Commercial Section <---- */}
        <div className="item-container">
          <button className={`header-button ${commercial ? 'button-active-class' : ''}`} onClick={() => setCommercial(!commercial)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-gear" viewBox="0 0 16 16">
              <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
              <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
            </svg>
            {t("navigationMenu.commercial")}
          </button>
        </div>
        {
          commercial && (
            <div className="drop-down-items">
              <ul>
                <li><NavLink className="navbar-link" to="/commercial/principal" onClick={handleClick}>{t("navigationMenu.pending")}</NavLink></li>
                <li>
                  <NavLink className="navbar-link" to="/commercial/projects" onClick={handleClick}>{t("navigationMenu.projects")}</NavLink>
                </li>
                <li><NavLink className="navbar-link" to="/commercial/request-orders" onClick={handleClick}>{t("navigationMenu.request")}</NavLink></li>
                <li>Aprobación Director</li>
                <li>
                  <NavLink className="navbar-link" to="/commercial/purchase-order" onClick={handleClick}>{t("navigationMenu.purchaseOrder")}</NavLink>
                </li>
                <li>Seguimiento</li>
                <li>
                  <NavLink className="navbar-link" to="/commercial/service" onClick={handleClick}>{t("navigationMenu.service")}</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/commercial/service/new-call" onClick={handleClick}>{t("navigationMenu.newCall")}</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/commercial/reports-view" onClick={handleClick}>{t("navigationMenu.reports")}</NavLink>
                </li>
              </ul>
            </div>
          )
        }

        {/* Viaticos */}
        <div className="item-container">
          <button className={`header-button ${travelExpen ? "button-active-class" : ""}`} onClick={() => setTravelExpen(!travelExpen)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-currency-exchange" viewBox="0 0 16 16">
              <path d="M0 5a5.002 5.002 0 0 0 4.027 4.905 6.46 6.46 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05c0-.046 0-.093.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.46 3.46 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98c-.003.046-.003.097-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5zm16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0zm-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787H8.25zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674l.077.018z" />
            </svg>
            {t("navigationMenu.viaticos")}
          </button>
        </div>
        {
          travelExpen && (
            <div className="drop-down-items">
              <ul>
                <li>
                  <NavLink className="navbar-link" to="/travel-expenses" onClick={handleClick}>{t("navigationMenu.anticipo")}</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/travel-expenses/expense-report" onClick={handleClick}>{t("navigationMenu.rela_gastos")}</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/travel-expenses/approvals" onClick={handleClick}>{t("navigationMenu.aprobacion")}</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" onClick={handleClick}>{t("navigationMenu.historial")}</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/administration/payment-expenses" onClick={handleClick}>
                    {t("navigationMenu.payouts")}
                  </NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" onClick={handleClick}>{t("navigationMenu.data")}</NavLink>
                </li>
              </ul>
            </div>
          )
        }

        {/* ----> Human Resources Section <---- */}
        <div className="item-container">
          <button className={`header-button ${hr ? 'button-active-class' : ''}`} onClick={() => setHr(!hr)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-badge-fill" viewBox="0 0 16 16">
              <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm4.5 0a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM8 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm5 2.755C12.146 12.825 10.623 12 8 12s-4.146.826-5 1.755V14a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-.245z" />
            </svg>
            {t("navigationMenu.humanResource")}
          </button>
        </div>
        {
          hr && (
            <div className="drop-down-items">
              <ul>
                <li>
                  <NavLink className="navbar-link" to="/human-capital/pendings" onClick={handleClick}>{t("navigationMenu.pending")}</NavLink>
                </li>
                {
                  dataSer && [6, 9].includes(dataSer[0]?.CodPerfil) && (
                    <li>
                      <NavLink className="navbar-link" to="/human-capital/employees/new-employee" onClick={handleClick}>
                        {t("navigationMenu.newEmployeeLabel")}
                      </NavLink>
                    </li>
                  )
                }
                <li>
                  <NavLink className="navbar-link" to="/human-capital/employees" onClick={handleClick}>
                    {t("navigationMenu.employees")}
                  </NavLink>
                </li>
                <li>{t("navigationMenu.overTime")}</li>
                <li>
                  <NavLink className="navbar-link" to="/human-capital/vacations/request" onClick={handleClick}>{t("navigationMenu.vacations")}</NavLink>
                </li>
                <li>{t("navigationMenu.trainingAndDevs")}</li>
                <li>{t("navigationMenu.evaluation")}</li>
              </ul>
            </div>
          )
        }
        {/* ----> Ware House Section */}
        <div className="item-container">
          <button className={`header-button ${wareHouse ? 'button-active-Class' : ''}`} onClick={() => setWareHouse(!wareHouse)}>
            <Warehouse />
            {t("navigationMenu.wareHouse")}
          </button>
        </div>
        {
          wareHouse && (
            <div className="drop-down-items">
              <ul>
                <li>
                  <NavLink className="navbar-link" to="/warehouse/principal" onClick={handleClick}>{t("navigationMenu.pending")}</NavLink>
                </li>
                <li>
                  {t("navigationMenu.dispatchRequest")}
                </li>
                <li>
                  {t("navigationMenu.exitApproval")}
                </li>
                <li>
                  {t("navigationMenu.confirmation")}
                </li>
                <li>
                  {t("navigationMenu.parts")}
                </li>
                <li>
                  <NavLink className="navbar-link" to="/warehouse/tools" onClick={handleClick}>{t("navigationMenu.tools")}</NavLink>
                </li>
              </ul>
            </div>
          )
        }
        {/* ----> Administration Section <---- */}
        <div className="item-container">
          <button className={`header-button ${administration ? 'button-active-class' : ''}`} onClick={() => setAdministration(!administration)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bank" viewBox="0 0 16 16">
              <path d="m8 0 6.61 3h.89a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v7a.5.5 0 0 1 .485.38l.5 2a.498.498 0 0 1-.485.62H.5a.498.498 0 0 1-.485-.62l.5-2A.501.501 0 0 1 1 13V6H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 3h.89L8 0ZM3.777 3h8.447L8 1 3.777 3ZM2 6v7h1V6H2Zm2 0v7h2.5V6H4Zm3.5 0v7h1V6h-1Zm2 0v7H12V6H9.5ZM13 6v7h1V6h-1Zm2-1V4H1v1h14Zm-.39 9H1.39l-.25 1h13.72l-.25-1Z" />
            </svg>
            {t("navigationMenu.administration")}
          </button>
        </div>
        {
          administration && (
            <div className="drop-down-items">
              <ul>
                <li>
                  <NavLink className="navbar-link" to="/administration/pending" onClick={handleClick}>{t("navigationMenu.pending")}</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/administration/authorizations" onClick={handleClick}>{t("navigationMenu.autorizaciones")}</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/administration/consumption-request" onClick={handleClick}>{t("navigationMenu.sol_consumo")}</NavLink>
                </li>
                <li>{t("navigationMenu.expenses")}</li>
                <li>{t("navigationMenu.advance")}</li>
                <li>{t("navigationMenu.billing")}</li>
                <li>{t("navigationMenu.accountPayable")}</li>
                <li>{t("navigationMenu.quotation")}</li>
                <li>
                  <NavLink className="navbar-link" to="/administration/expenses/history" onClick={handleClick}>
                    {t("navigationMenu.history")}
                  </NavLink>
                </li>
                <li>
                  {t("navigationMenu.payouts")}
                </li>
                <li>{t("navigationMenu.reports")}</li>
              </ul>
            </div>
          )
        }
        {/* ----> DATA Section <---- */}
        <div className="item-container">
          <button className={`header-button ${dataGIS ? 'button-active-class' : ''}`} onClick={() => setDataGIS(!dataGIS)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard2-data-fill" viewBox="0 0 16 16">
              <path d="M10 .5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5.5.5 0 0 1-.5.5.5.5 0 0 0-.5.5V2a.5.5 0 0 0 .5.5h5A.5.5 0 0 0 11 2v-.5a.5.5 0 0 0-.5-.5.5.5 0 0 1-.5-.5Z" />
              <path d="M4.085 1H3.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1h-.585c.055.156.085.325.085.5V2a1.5 1.5 0 0 1-1.5 1.5h-5A1.5 1.5 0 0 1 4 2v-.5c0-.175.03-.344.085-.5ZM10 7a1 1 0 1 1 2 0v5a1 1 0 1 1-2 0V7Zm-6 4a1 1 0 1 1 2 0v1a1 1 0 1 1-2 0v-1Zm4-3a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0V9a1 1 0 0 1 1-1Z" />
            </svg>
            {t("navigationMenu.data")}
          </button>
        </div>
        {
          dataGIS && (
            <div className="drop-down-items">
              <ul>
                <li>
                  <NavLink className="navbar-link" to="/data/division" onClick={handleClick}>{t("navigationMenu.division")}</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/data/line" onClick={handleClick}>{t("navigationMenu.line")}</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/data/modality" onClick={handleClick}>{t("navigationMenu.modality")}</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/data/equipment-type" onClick={handleClick}>{t("navigationMenu.equipType")}</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/data/model" onClick={handleClick}>{t("navigationMenu.model")}</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/data/target" onClick={handleClick}>Target</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/data/area" onClick={handleClick}>{t("navigationMenu.zone")}</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/data/region" onClick={handleClick}>{t("navigationMenu.region")}</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/data/city" onClick={handleClick}>{t("navigationMenu.city")}</NavLink>
                </li>
              </ul>
            </div>
          )
        }

        {/* Aquí van los mensajes de notificaciones */}

      </div>

      {/* Configuraciones */}
      <div className="config-side-bar">
        <h5 className="h5-config-sect">Configuraciones</h5>
        <hr />
        <div className="user-detail">
          <h6 style={{ color: 'var(--first-color)' }}>{userInfoParsed.name}</h6>
          <span style={{ color: 'var(--first-color)' }}>{userInfoParsed.email}</span>
        </div>
        {/* Cambio de idioma */}
        <div style={{
          marginBottom: '0.3rem'
        }} className="language-sect">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-translate" viewBox="0 0 16 16">
            <path d="M4.545 6.714 4.11 8H3l1.862-5h1.284L8 8H6.833l-.435-1.286H4.545zm1.634-.736L5.5 3.956h-.049l-.679 2.022H6.18z" />
            <path d="M0 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm7.138 9.995c.193.301.402.583.63.846-.748.575-1.673 1.001-2.768 1.292.178.217.451.635.555.867 1.125-.359 2.08-.844 2.886-1.494.777.665 1.739 1.165 2.93 1.472.133-.254.414-.673.629-.89-1.125-.253-2.057-.694-2.82-1.284.681-.747 1.222-1.651 1.621-2.757H14V8h-3v1.047h.765c-.318.844-.74 1.546-1.272 2.13a6.066 6.066 0 0 1-.415-.492 1.988 1.988 0 0 1-.94.31z" />
          </svg>
          <select
            style={{ border: 'none' }}
            name="lang"
            className="select-lang"
            value={lang}
            onChange={({ target }) => onChangeLanguage(target.value)}>
            <option value="es">Español (es)</option>
            <option value="en">English (en)</option>
          </select>
        </div>
        {/* Logout button */}
        <hr />
        {/* Logout Button */}
        <div className="btn-logout-sect">
          <button className="btn-logout" onClick={onLogOut}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-in-left" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M10 3.5a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 1 1 0v2A1.5 1.5 0 0 1 9.5 14h-8A1.5 1.5 0 0 1 0 12.5v-9A1.5 1.5 0 0 1 1.5 2h8A1.5 1.5 0 0 1 11 3.5v2a.5.5 0 0 1-1 0v-2z" />
              <path fillRule="evenodd" d="M4.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H14.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z" />
            </svg>
            {t("navigationMenu.logout")}
          </button>
        </div>

        {/* VERISIONADO */}
        <div className="version-container">
          <strong className="version-text">V</strong>
          <strong className="version">{version.x}.{version.y}.{version.z}</strong>
        </div>



      </div>
    </aside>
  );
};

