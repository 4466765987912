// componente para manejar la data del service report

import { useState, useEffect, useContext } from "react";


// context importation
import { NotificationContext } from "../../../context/NotificationContext.jsx";

import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";




export const useFetchServReport = (END_POINT = "", token = "", lang = "", CodId = "", Method = "POST") => {

  // state definition
  const [state, setState] = useState({ isLoading: false, dataVisible: false, showModal: false, question: "", sending: false, reload: false });
  const [form, setForm] = useState({ prioridad: "", ingInvolucrados: "", descripcion: "", serialTuboInst: "", serialTransInst: "", serialTuboDes: "", serialTransDes: "", fecDesde: "", fecHasta: "", action: "", horas: 0, horExtras: 0, horExtNocturna: 0, horExtFeriado: 0, horViaje: 0, totHoras: 0, codRerServ: 0, codRepSerFec: 0, codEmp: 0, temporal: 0, totHorExtras: 0 });
  const [controller, setController] = useState(null); // controlador para cancelar la peticion al servidor
  const [repState, setRepState] = useState({});

  const { HorasTotales } = repState && repState;

  // react translation
  const [t] = useTranslation("global");


  // GET the useNavigate
  const navigate = useNavigate();


  // GET the notification context
  const { handleNotify } = useContext(NotificationContext);


  // funcion para recargar la data cuando se envie al servidor
  const handleReloadData = (newVal = false) => {
    setState((val) => ({ ...val, reload: newVal }));
  };


  // funcion para manejar diferentes acciones
  const handlePerformAct = (act = "") => {
    let Question = "";

    if (act === "1") { // agregar un empleado

      if (form.ingInvolucrados === "") {
        return handleNotify(true, t("serviceCard.seleccionar_ing_involu"), true);
      }

      Question = "Está seguro que desea agregar este empleado al reporte?";
    }

    if (act === "2") { // agregar fecha

      if (form.fecDesde === "" || form.fecHasta === "") {
        return handleNotify(true, "Debe indicar las fechas del servicio", true);
      }

      Question = "Está seguro que desea agregar esta fecha?";

    }

    if (act === "3") { // guardar o crear el reporte de servicio

      if (form.prioridad === "") {
        return handleNotify(true, t("serviceCard.seleccionar_tempe"), true);
      }

      if (form.descripcion.trim() === "") {
        return handleNotify(true, t("serviceCard.seleccionar_traba_reali"), true);
      }

      if (form.descripcion.trim().length < 5) {
        return handleNotify(true, t("serviceCard.seleccionar_descrip_valida"), true);
      }

      Question = t("serviceCard.guardar_reporte");

    }

    setState((val) => ({ ...val, showModal: true, question: Question }));
    setForm((val) => ({ ...val, action: act }));
  };


  // funcion para abrir y cerrar el modal
  const handleCloseOpenModal = () => {
    setState((val) => ({ ...val, showModal: !state.showModal }));
  };

  // funcion para realizar diferentes acciones: eliminar empleado, eliminar fecha
  const handleDelete = (CodRep = 0, CodId = 0, Temp = 0, act = "") => {
    let name = "";
    let Question = "";
    if (act === "4") { // eliminar la fecha
      name = "codRepSerFec";
      Question = t("serviceCard.eliminar_fec");
    } else if (act === "5") {
      name = "codEmp";
      Question = t("serviceCard.eliminar_empleado");
    }


    setState((val) => ({ ...val, showModal: true, question: Question }));
    setForm((val) => ({ ...val, [name]: CodId, codRerServ: CodRep, temporal: Temp, action: act }));
  };


  // funcion para eliminar un empleado


  // funcion para enviar la data al servidor
  const handleSendData = (newVal = false) => {
    setState((val) => ({ ...val, sending: newVal }));
  };


  // funcion para cambiar los valores del formulario
  const handleChangeFormVal = (name = "", newVal = "") => {
    setForm((val) => ({ ...val, [name]: newVal }));
  };

  // funcion para actualizar el formulario con multiples objetos
  const handleChangeForm = (newObj = {}) => {
    setForm((val) => ({ ...val, ...newObj }));
  };

  // Funcion para calcular el total de horas
  const onHandleCalHor = (newVal = "") => {
    if (isNaN(newVal)) {
      return;
    }
    const totalHoras = Number(form.horas) + Number(form.horExtras) + Number(form.horExtNocturna) + Number(form.horExtFeriado) + Number(newVal);

    setForm((val) => ({ ...val, horViaje: newVal, totHoras: totalHoras }));
  };



  // Funcion para solicitar la data al servidor
  const fetchDataServer = async () => {
    const abortController = new AbortController();

    setController(abortController);

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(END_POINT, {
        method: Method,
        headers: {
          "Authorization": token,
          "Content-Type": "application/json"
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        // setDataServer(data);
        handleReloadData();
        setRepState(data);
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 400) {
        setState((val) => ({ ...val, dataVisible: false, badRequest: true }));
      } else if (response.status === 401) {
        // localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        setState((val) => ({ ...val, dataVisible: false, notFound: true }));
      } else if (response.status === 403) {
        setState((val) => ({ ...val, dataVisible: false, unauthorized: true }));
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      handleNotify(true, t("General_Comp.error_comunic_serv"), true);
      // console.log(error);
      console.clear();
      setState((val) => ({ ...val, isLoading: false }));
    }
  };


  // useEffect para solicitar la data al servidor
  useEffect(() => {

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [lang, CodId]);


  // useEffect para recargar la data
  useEffect(() => {

    if (state.reload) {
      fetchDataServer();

      return () => {
        if (controller) {
          controller.abort();
        }
      };
    }

  }, [state.reload]);


  // useEffect para cargar las horas
  useEffect(() => {
    if (HorasTotales && HorasTotales.length > 0) {
      setForm((val) => ({ ...val, horas: HorasTotales[0]?.THorNormal, totHoras: HorasTotales[0]?.THoras, horExtFeriado: HorasTotales[0]?.THorFeriado, horExtNocturna: HorasTotales[0]?.THorNocturna, horExtras: HorasTotales[0]?.THorExtras }));
    }
  }, [HorasTotales]);





  return {
    form,
    state,
    repState,
    handleChangeFormVal,
    handleCloseOpenModal,
    handleSendData,
    handleDelete,
    handlePerformAct,
    handleReloadData,
    onHandleCalHor,
    handleChangeForm
  };
};