// ----> React importation
import React, { useEffect, useState, useContext } from "react";

// ----> Import for translation Hook
import { useTranslation } from "react-i18next";

// ----> Imports for styles
import "../../components/serviceComponents/NewCall.css";

// ----> inline styles
import { newCallStyles } from "../../inline-styles/generalStyles";


// ----> URLS
import { urls } from "../../api-urls/api-urls.js";

// ----> GLOBAL FUNCTIONS
import { getTheToken } from "../../global-functions/globalFunctions.js";

// REACT ROUTER DOM IMPORTATION
import { useLocation, useNavigate } from "react-router-dom";


import { TemperSelect } from "./TemperSelect.jsx";


// notification context
import { NotificationContext } from "../../context/NotificationContext.jsx";





export const NewCall = ({ grupo }) => {

  const navigate = useNavigate();

  const { handleNotify } = useContext(NotificationContext);

  // LANGUAGE
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the useLocation
  const location = useLocation();

  // Destructuring useTranslation() Hook
  const [t] = useTranslation("global");


  // ----> STATES DECLARATION
  const [openCall, setOpenCall] = useState({
    customer: "", codCliente: "", temperature: "", equipment: "", responsible: "", problem: "", reportedBy: "", serviceType: "", idStep: 1,
    nombreContacto: "", correoContacto: "", departamentoContacto: "", codCelularContact: "", celularContacto: "", codTelefonoContact: "", telefonoContacto: "", correo2: ""
  });
  const [dataServerCustomers, setDataServerCustomers] = useState([]);
  const [dataServerRes, setDataServerRes] = useState([]); // se recibe el resto de la data
  const [showModal, setShowModal] = useState(false); // esto es para abrir el modal
  const [isLoading, setIsLoading] = useState(false);

  // DESTRUCTURING DATA SERVER
  const { customers } = dataServerCustomers && dataServerCustomers;
  const { equipments, contacts, employees } = dataServerRes && dataServerRes;


  // labels declarations
  const CUSTOMER_NOT_EMPTY = language === "en" ? "You must select a customer." : "Debe seleccionar un cliente.";
  const TEMP_NOT_EMPTY = language === "en" ? "You must to select a temperature." : "Debe seleccionar una temperatura.";
  const EQUIP_NOT_EMPTY = language === "en" ? "The equipment cannot be empty." : "El equipo no puede estar vacío.";
  const RESP_NOT_EMPTY = language === "en" ? "The responsible cannot be empty." : "El responsable no puede estar vacío.";
  const SERVICE_TYPE_NOT_EMPTY = language === "en" ? "You must select the service type." : "Debe seleccionar el tipo de servicio.";
  const PROBLEM_NOT_EMPTY = language === "en" ? "You must indicate what is the issue with the equipment." : "Debe indicar cual es el problema que presenta el equipo.";


  // funcion para validar los formularios
  const ValidateForm = (openCall.customer && openCall.temperature && openCall.equipment && openCall.responsible && openCall.serviceType && openCall.problem.length >= 3);



  // DECLARACIONES DE FUNCIONES

  // funcion para agregar el codigo de cliente al estado
  const onAddCustomer = (codCl) => {
    setOpenCall((val) => ({ ...val, codCliente: codCl }));
  };


  // funcion para modificar los valores del formulario
  const handleChangeTemp = (value = "") => {
    setOpenCall((val) => ({ ...val, temperature: value }));
  };


  const handleSelectContact = ({ target }) => {
    if (target.value !== "") {
      const contact = contacts && contacts.find(contact => contact.CodContacto.toString() === target.value);
      setOpenCall((val) => ({ ...val, reportedBy: target.value, nombreContacto: contact.Nombre, correoContacto: contact.Correo, departamentoContacto: contact.Departamento, codCelularContact: contact.CodTelefono, celularContacto: contact.Telefono }));
    } else {
      setOpenCall((val) => ({ ...val, nombreContacto: "", correoContacto: "", departamentoContacto: "", codCelularContact: "", celularContacto: "", reportedBy: "" }));
    }
  };



  // SEARCH A CUSTOMER BY TYPING THE NAME
  const customerFound = customers && customers.filter(customer => customer.Nombre?.toLowerCase().includes(openCall.customer?.toLowerCase()));

  // ----> HANDLE SUBMIT
  const handleSubmit = async (event) => {
    event.preventDefault();


    if (!openCall.codCliente) {
      // console.log(isNaN(openCall.customer));
      handleNotify(true, CUSTOMER_NOT_EMPTY, true);
    } else if (openCall.temperature.length === 0) {
      handleNotify(true, TEMP_NOT_EMPTY, true);
    } else if (!openCall.equipment) {
      handleNotify(true, EQUIP_NOT_EMPTY, true);
    } else if (!openCall.responsible) {
      handleNotify(true, RESP_NOT_EMPTY, true);
    } else if (!openCall.serviceType) {
      handleNotify(true, SERVICE_TYPE_NOT_EMPTY, true);
    } else if (!openCall.problem.trim()) {
      handleNotify(true, PROBLEM_NOT_EMPTY, true);
    } else {

      try {
        setIsLoading(true);
        const response = await fetch(`${urls.calls}/search?language=${language}&grupo=${grupo}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": getTheToken().token
          },
          body: JSON.stringify(openCall)
        });

        console.clear();

        const data = await response.json();

        // VERIFICAR SI HUBO ALGUN ERROR
        if (response.status === 201) {
          // ACA SE CREO LA LLAMADA
          handleNotify(true, data?.msg, false, true);

          if (grupo === 1) {
            navigate(`/service/service-call/${data?.idCall}`);
          } else {
            navigate(`/commercial/service/service-call/${data?.idCall}`);
          }

        } else if (response.status === 401) {
          localStorage.setItem("lastNav", location.pathname);
          localStorage.removeItem("userInfo");
          return navigate("/");
        } else if (response.status === 500) {
          handleNotify(true, language === "en" ? "There was an errror in the server, please contact the admin" : "Ocurrió un error en el servidor, por favor contacte al administrador.", true);
        }


      } catch (error) {
        // Manejo de errores
        console.log(error);
      }

    }

  };


  // useEffect para controlar los setTimeOut
  // useEffect(() => {
  //   if (notification.serviceCall > 0 && notification.show) {
  //     const timer = setTimeout(() => {


  //     }, 5500);

  //     return () => {
  //       clearTimeout(timer);
  //       setNotification({ serviceCall: 0, show: false, msg: "", errorNoti: false, great: false });
  //     }
  //   }
  // }, [notification.serviceCall]);



  // useEffect para limpiar las notificacion
  // useEffect(() => {

  //   const timer = setTimeout(() => {
  //     setNotification({ serviceCall: 0, show: false, msg: "", errorNoti: false, great: false });
  //   }, 6000);

  //   return () => {
  //     clearTimeout(timer);
  //   }
  // }, [notification.show]);



  // PARA CUANDO CAMBIE EL ESTADO EN EL CLIENTE
  useEffect(() => {
    setOpenCall((val) => ({ ...val, equipment: "", responsible: "", temperature: "" }));
  }, [openCall.customer]);

  // SET THE TITLE IN THE DOCUMENT HTML
  useEffect(() => { document.title = language === "es" ? "Abrir Servicio - GIS APP" : "Open Call ID - GIS APP" }, [language]);



  // funcion para realizar el fetch de la data de customer
  const fetchDataServer = async () => {
    try {
      const response = await fetch(urls.calls, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        }
      });

      // console.clear();

      const data = await response.json();

      if (response.status === 200) {
        setDataServerCustomers(data);
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 500) {
        // ocurrio un error en el servidor
      }

    } catch (error) {
      console.log(error);
    }
  };



  // useEffect para traer los clientes
  useEffect(() => {

    fetchDataServer();

  }, []);

  // console.log(dataServerCustomers)

  // useEffect para traer los equipos y los empleados luego de haber colocado el cliente
  useEffect(() => {
    try {
      const fetchDataServerRes = async () => {
        const response = await fetch(`${urls.equipmentsById}/${openCall.codCliente}`, {
          headers: { "Authorization": getTheToken().token }
        });

        const data = await response.json();

        if (response.status === 200) {
          setDataServerRes(data);
        } else if (response.status === 401) {
          localStorage.removeItem("userInfo");
          return navigate("/");
        } else if (response.status === 500) {
          // ocurrio un error en el servidor
        }
      };

      if (openCall.codCliente) {
        fetchDataServerRes();
      }

    } catch (error) {
      console.log(error);
    }
  }, [openCall.codCliente]);


  // useEffect para cambiar el nombre del cliente en el input
  useEffect(() => {
    if (customers && customers.length > 0 && openCall.customer !== "") {
      const custoChange = customers && customers.find((cus) => cus.CodCliente === openCall.codCliente);
      setOpenCall((val) => ({ ...val, customer: custoChange?.Nombre }));
      setShowModal(false);
    }
  }, [openCall.codCliente]);



  return (
    <>
      <div className="display-data">

        <form onSubmit={handleSubmit}>
          <div style={newCallStyles}>

            {/* FILA 1 */}
            <div className="row-flex m-y-small">
              <div className="col-9 col-sm-8"><strong>Customer</strong></div>
              {/* INPUT PARA EL CLIENTE */}
              <div className="col-3 col-70-sm">
                <input
                  className={`input-text-control ${openCall.customer === "" && "bg-required"}`}
                  style={{ width: '100%' }}
                  type="text"
                  name="custoSearch"
                  readOnly
                  value={openCall.customer}
                  onFocus={() => setShowModal(true)}
                  autoComplete="off"
                  placeholder={language === "en" ? "Search a customer..." : "Busque un cliente..."} />
              </div>

              {/* TEMPERATURA */}
              <div className="col-9 col-sm-8">
                <strong>{t("newCallComp.temperature")}</strong>
              </div>
              <TemperSelect updateParent={handleChangeTemp} />
              {/* ETAPA ACTUAL */}
              <div className="col-9 col-sm-15">
                <strong className="p-small">
                  {t("filterService.steps")}
                </strong>
              </div>
              <div className="col-sm-8 text-hidden-over">
                <span style={{ backgroundColor: 'var(--primary-color)', borderRadius: '0.28rem', color: 'var(--white-color)', fontSize: '0.8rem', padding: '0.1rem 0.3rem 0.1rem 0.3rem' }}>{t("serviceCallSteps.callReception")}</span>
              </div>

            </div>

            {/* FILA 2 */}
            <div className="row-flex m-y-small">
              {/* SELECCIONAR EL EQUIPO */}
              <div className="col-9 col-sm-8">
                <strong style={{ marginRight: '0.3rem' }}>{t("serviceCard.equipment")}</strong>
              </div>
              <div className="col-3 col-70-sm">
                <select
                  name="equipment"
                  className={`selectEquip ${openCall.equipment === "" && "bg-required"}`}
                  value={openCall.equipment}
                  onChange={({ target }) => setOpenCall((val) => ({ ...val, equipment: target.value }))}>
                  <option value=""></option>
                  {
                    equipments && equipments.map(equipment => (
                      <option key={equipment.CodEquipo} value={equipment.CodEquipo}>{equipment.TipEquipo} - {equipment.Modelo} - {equipment.Serial}</option>
                    ))
                  }
                </select>
                {openCall.equipment}
              </div>
              <div className="col-9 col-sm-8">
                <strong style={{ marginRight: '0.3rem' }}>{t("newCallComp.technician")}</strong>
              </div>
              <div className="col-3 col-70-sm">
                {/* Selección del técnico */}
                <select
                  name="responsi"
                  className={`${openCall.responsible === "" && "bg-required"}`}
                  value={openCall.responsible}
                  onChange={({ target }) => setOpenCall((val) => ({ ...val, responsible: target.value }))}>
                  <option value=""></option>
                  {
                    employees && employees.map((emplo) => (
                      <option key={emplo.CodEmpleado} value={emplo.CodEmpleado}>{emplo.NomEmpleado}</option>
                    ))
                  }
                </select>
              </div>
            </div>

            {/* FILA 3 */}
            <div className="row-flex">
              {/* SELECCIONAR EL TIPO DE SERVICIO */}
              <div className="col-9 col-sm-8">
                <strong style={{ marginRight: '0.5rem' }}>{t("serviceCallSteps.serviceType")}</strong>
              </div>
              <div className="col-3 col-70-sm">
                <select
                  name="selectTipServ"
                  className={`selectRespon ${openCall.serviceType === "" && "bg-required"}`}
                  value={openCall.serviceType}
                  onChange={({ target }) => setOpenCall((val) => ({ ...val, serviceType: target.value }))}>
                  <option value=""></option>
                  <option value="1">{t("serviceCallSteps.correctiveMaint")}</option>
                  <option value="2">{t("serviceCallSteps.preventMaint")}</option>
                  <option value="4">FMI</option>
                  <option value="9">{t("serviceCallSteps.project")}</option>
                  <option value="10">{t("serviceCallSteps.preInstall")}</option>
                  <option value="3">{t("serviceCallSteps.install")}</option>
                  <option value="5">{t("serviceCallSteps.applications")}</option>
                  <option value="6">{t("serviceCallSteps.workshop")}</option>
                  <option value="7">{t("serviceCallSteps.stockRepo")}</option>
                  <option value="8">{t("serviceCallSteps.internalSupport")}</option>
                </select>
              </div>
              {/* INDICAR EL PROBLEMA */}
              <div className="col-9 col-sm-8">
                <strong>{t("serviceCard.problem")}</strong>
              </div>
              <div className="col-3 col-70-sm">
                <textarea
                  className={`textarea-description ${openCall.problem === "" && "bg-required"}`}
                  name="problem"
                  value={openCall.problem}
                  onChange={({ target }) => setOpenCall((val) => ({ ...val, problem: target.value }))} />
              </div>
            </div>

            <h3 style={{ color: 'var(--first-color)' }}>{t("serviceCallSteps.customerData")}</h3>
            <hr />
            {/* ----> DATOS DEL CLIENTE <---- */}

            {/* FILA 4 */}
            <div className="row-flex m-y-small">
              {/* SELECCIONAR EL CONTACTO */}
              <div className="col-9 col-sm-8"><strong>{t("customerView.contact")}</strong></div>
              <div className="col-3 col-70-sm">
                <select
                  name="selectContact"
                  value={openCall.reportedBy}
                  onChange={handleSelectContact} >
                  <option value="">Select contact</option>
                  {
                    contacts && contacts.map(contact => (
                      <option key={contact.CodContacto} value={contact.CodContacto}>{contact.Nombre}</option>
                    ))
                  }
                </select>
              </div>
              {/* CORREO */}
              <div className="col-9 col-sm-8">
                <strong>{t("createCustomerComp.email")}</strong>
              </div>
              <div className="col-3 col-70-sm">
                <input
                  className={openCall.reportedBy.length > 3 ? 'disabled-class input-text-control' : 'input-text-control'}
                  type="text"
                  name="emailCont"
                  value={openCall.correoContacto || ""}
                  onChange={({ target }) => setOpenCall((val) => ({ ...val, correoContacto: target.value }))} />
              </div>
            </div>

            <div className="row-flex">
              <div className="col-9 col-sm-8"><strong>Correo 2</strong></div>
              <div className="col-3 col-70-sm">
                <input
                  type="text"
                  className="input-text-control"
                  name="email2"
                  value={openCall.correo2}
                  onChange={({ target }) => setOpenCall((val) => ({ ...val, correo2: target.value }))}
                  autoComplete="off" />
              </div>
            </div>

            {/* FILA 5 */}
            <div className="row-flex">
              {/* NOMBRE DEL CONTACTO */}
              <div className="col-9 col-sm-8">
                <strong>{t("newCallComp.name")}</strong>
              </div>
              <div className="col-3 col-70-sm">
                <input
                  className={openCall.reportedBy.length > 3 ? 'disabled-class input-text-control' : 'input-text-control'}
                  type="text"
                  name="nomCont"
                  value={openCall.nombreContacto || ""}
                  onChange={({ target }) => setOpenCall((val) => ({ ...val, nombreContacto: target.value }))} />
              </div>
              {/* CELULAR DEL CONTACTO */}
              <div className="col-9 col-sm-8">
                <strong>{t("newCallComp.cell")}</strong>
              </div>

              <div className="col-70-sm row-flex">
                <div style={{ width: '3.4rem' }}>
                  <input
                    className="input-text-control"
                    type="text"
                    name="codCelCont"
                    value={openCall.codCelularContact || ""}
                    onChange={({ target }) => setOpenCall((val) => ({ ...val, codCelularContact: target.value }))} />
                </div>
                <div style={{ width: '4.3rem' }}>
                  <input
                    className="input-text-control"
                    type="text"
                    name="cellCont"
                    value={openCall.celularContacto}
                    onChange={({ target }) => setOpenCall((val) => ({ ...val, celularContacto: target.value }))} />
                </div>
              </div>

              {/* TELEFONO DEL CONTACTO */}
              <div className="col-10 col-sm-8">
                <strong>{t("newCallComp.telephone")}</strong>
              </div>
              <div style={{ width: '3.4rem' }}>
                <input
                  className="input-text-control"
                  type="text"
                  name="codTeleCont"
                  value={openCall.codTelefonoContact || ""}
                  onChange={({ target }) => setOpenCall((val) => ({ ...val, codTelefonoContact: target.value }))} />
              </div>
              <div style={{ width: '4.3rem' }}>
                <input
                  className="input-text-control"
                  type="text"
                  name="teleCont"
                  value={openCall.telefonoContacto || ""}
                  onChange={({ target }) => setOpenCall((val) => ({ ...val, telefonoContacto: target.value }))} />
              </div>
            </div>

            {/* FILA 6 */}
            <div className="row-flex m-y-small">
              {/* DEPARTAMENTO DEL CONTACTO */}
              <div className="col-9 col-sm-8">
                <strong>{t("newCallComp.departament")}</strong>
              </div>
              <div className="col-3 col-70-sm">
                <input
                  style={{ width: '100%' }}
                  className={openCall.reportedBy.length > 3 ? 'disabled-class input-text-control' : 'input-text-control'}
                  type="text"
                  name="departCont"
                  value={openCall.departamentoContacto || ""}
                  onChange={({ target }) => setOpenCall((val) => ({ ...val, departamentoContacto: target.value }))} />
              </div>
            </div>

          </div>

          {/* BOTONES */}
          <div className="row-flex j-content-space-around m-y-medium">
            <div>
              <button
                className={`btn-primary ${(isLoading || !ValidateForm) && "disabled"} `}
                type={(isLoading || !ValidateForm) ? "button" : "submit"}
                disabled={(isLoading || !ValidateForm)}>{isLoading ? t("newCallComp.loading") : t("newCallComp.save")}</button>
            </div>
            <div>
              <button type="reset" className={`btn-secondary ${isLoading ? "disabled" : ""}`} onClick={() => navigate(-1, { replace: true })}>{t("navigationMenu.backBtn")}</button>
            </div>
          </div>
        </form>

      </div>

      {
        // modal para abrir el filtro de los clientes
        showModal && (
          <div className="background-modal">
            <div className="container-select-customer">
              <div className="modal-header">
                <h3>Clientes</h3>
                <button type="button" className="btn-close" onClick={() => setShowModal(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                  </svg>
                </button>
              </div>
              <div className="modal-body">
                {/* fila */}
                <div className="row-flex">
                  <div className="col-7">
                    <strong>Nombre</strong>
                  </div>
                  <div className="col-80">
                    <input
                      className="input-text-control"
                      type="text"
                      name="searchCustom"
                      value={openCall.customer}
                      onChange={({ target }) => setOpenCall((val) => ({ ...val, customer: target.value }))}
                      autoFocus />
                  </div>
                </div>
                {/* fila */}
                {
                  customerFound && customerFound.map((cus) => (
                    <div key={cus.CodCliente} className="row-flex border-bottom hover-class">
                      <div className="col-80">
                        <strong>{cus.Nombre}</strong>
                      </div>
                      <div className="col-5 row-flex flex-end">
                        <button type="button" style={{ cursor: "pointer" }} onClick={() => onAddCustomer(cus.CodCliente)}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="var(--first-color)" className="bi bi-database-fill-add" viewBox="0 0 16 16">
                            <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0M8 1c-1.573 0-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4s.875 1.755 1.904 2.223C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777C13.125 5.755 14 5.007 14 4s-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1" />
                            <path d="M2 7v-.839c.457.432 1.004.751 1.49.972C4.722 7.693 6.318 8 8 8s3.278-.307 4.51-.867c.486-.22 1.033-.54 1.49-.972V7c0 .424-.155.802-.411 1.133a4.51 4.51 0 0 0-4.815 1.843A12.31 12.31 0 0 1 8 10c-1.573 0-3.022-.289-4.096-.777C2.875 8.755 2 8.007 2 7m6.257 3.998L8 11c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V10c0 1.007.875 1.755 1.904 2.223C4.978 12.711 6.427 13 8 13h.027a4.552 4.552 0 0 1 .23-2.002m-.002 3L8 14c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V13c0 1.007.875 1.755 1.904 2.223C4.978 15.711 6.427 16 8 16c.536 0 1.058-.034 1.555-.097a4.507 4.507 0 0 1-1.3-1.905" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  ))
                }
              </div>
              <div className="modal-footer j-content-space-around">
                <button type="button" className="btn-secondary" onClick={() => setShowModal(false)}>{t("customerView.btnClose")}</button>
              </div>
            </div>
          </div>

          // <div className="background-modal" onClick={() => setShowModal(false)}>
          //   <div className="container-modal-filter-customer" onClick={(event) => event.stopPropagation()}>
          //     <div>
          //       <input
          //         className="input-text-control"
          //         type="text"
          //         name="searchCustom"
          //         value={openCall.customer}
          //         onChange={({ target }) => setOpenCall((val) => ({ ...val, customer: target.value }))} />
          //     </div>

          //     <div className="m-y-small">
          //       {
          //         // lista de los clientes
          //         customerFound && customerFound.map((cus) => (
          //           <div key={cus.CodCliente} className="row-flex border-bottom j-content-space-around">
          //             <div className="col-80">
          //               <strong>{cus.Nombre}</strong>
          //             </div>
          //             <div className="col-7 row-flex flex-end">
          //               <button type="button" style={{ cursor: "pointer" }} onClick={() => onAddCustomer(cus.CodCliente)}>
          //                 <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="var(--first-color)" className="bi bi-database-fill-add" viewBox="0 0 16 16">
          //                   <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0M8 1c-1.573 0-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4s.875 1.755 1.904 2.223C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777C13.125 5.755 14 5.007 14 4s-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1" />
          //                   <path d="M2 7v-.839c.457.432 1.004.751 1.49.972C4.722 7.693 6.318 8 8 8s3.278-.307 4.51-.867c.486-.22 1.033-.54 1.49-.972V7c0 .424-.155.802-.411 1.133a4.51 4.51 0 0 0-4.815 1.843A12.31 12.31 0 0 1 8 10c-1.573 0-3.022-.289-4.096-.777C2.875 8.755 2 8.007 2 7m6.257 3.998L8 11c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V10c0 1.007.875 1.755 1.904 2.223C4.978 12.711 6.427 13 8 13h.027a4.552 4.552 0 0 1 .23-2.002m-.002 3L8 14c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V13c0 1.007.875 1.755 1.904 2.223C4.978 15.711 6.427 16 8 16c.536 0 1.058-.034 1.555-.097a4.507 4.507 0 0 1-1.3-1.905" />
          //                 </svg>
          //               </button>
          //             </div>
          //           </div>
          //         ))
          //       }
          //     </div>
          //   </div>
          // </div>
        )
      }
    </>
  );
};