
// imports
import { useState } from "react";

import { LangContext } from "./LangContext.jsx";



export const LangProvider = ({ children }) => {

  const LANGUAGE = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // state definition
  const [lang, setLang] = useState(LANGUAGE);

  const handleChangeLang = (newLang = "") => {
    setLang(newLang);
    localStorage.setItem("language", newLang);
  };

  return (
    <LangContext.Provider value={{ lang, handleChangeLang }}>
      {children}
    </LangContext.Provider>
  );
};