import { useEffect, useState, useContext } from "react";
import { getTheToken } from "../../../global-functions/globalFunctions.js";


import { useTranslation } from "react-i18next";


// context importation
import { NotificationContext } from "../../../context/NotificationContext.jsx";
import { useNavigate } from "react-router-dom";







export const useFetchData = (END_POINT = "", Method = "GET") => {

  // state definition
  const [dataServer, setDataServer] = useState([]);
  const [controller, setController] = useState(null); // para cancelar la peticion al servidor
  const [state, setState] = useState({ isLoading: false, dataVisible: false, unauthorized: false, notFound: false, showModal: false, exportado: false, questionAct: "", sending: false, reload: false });
  const [form, setForm] = useState({ devolver: false, guiaExport: "", fechaExport: "", paraExport: false, exportado: false, so: "", guiaDespa: "", carrier: "", fecDespacho: "", entrAlma: "", salidaAlma: "", comment: "", fleteImport: "", fleteExport: "", handlingCost: "", guiDespCliente: "", fecDespCliente: "", numWR: "", dimen: "", peso: "", carrClien: "", gestAdua: "", dua: "", entDoc: "", dateExchang: "", tasa: "" });
  const [otherInf, setOtherInf] = useState({ queEs: "", funcion: "", carFisica: "", carTecnica: "", numParte: "", descripcion: "", precio: 0, prof: 0 });


  const { firstInfo, tblDetSolRepuesto, tblDetSegRepuesto, EmpPerm, TasaDyn } = dataServer && dataServer;

  const [t] = useTranslation("global");

  const navigate = useNavigate();


  // notification context
  const { handleNotify } = useContext(NotificationContext);


  // funcion para cambiar el estado del form
  const onHandleChangeForm = (name = "", newVal = "") => {
    if (["fleteImport", "fleteExport", "handlingCost"].includes(name)) {
      if (isNaN(newVal.toString().replace(/[^\d.]/g, ""))) {
        return;
      }
    }
    setForm((val) => ({ ...val, [name]: newVal }));
  };


  // funcion para cancelar el boton de enviar
  const handleSendServer = (newVal = false) => {
    setState((val) => ({ ...val, sending: newVal }));
  };


  // funcion para recargar la data
  const handleReloadData = (newVal = false) => {
    setState((val) => ({ ...val, reload: newVal }));
  };


  // funcion para abrir el modal para guardar la informacion
  const onSaveInfoModal = () => {
    setState((val) => ({ ...val, questionAct: t("LogisticViewComp.seguro_guar_cambios"), showModal: true }));
  };


  // funcion para cerrar el modal
  const onCloseModal = () => {
    setState((val) => ({ ...val, showModal: false, questionAct: "" }));
  };


  // funcion para solicitar la data al servidor
  const fetchDataServ = async () => {

    const abortContr = new AbortController();

    setController(abortContr);

    try {
      setState((val) => ({ ...val, isLoading: true }));

      const response = await fetch(END_POINT, {
        method: Method,
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        signal: abortContr.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
        setState((val) => ({ ...val, dataVisible: true }));
      } if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      }


      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.error(error);
    }
  };



  // useEffetc para solicitar la data al servidor
  useEffect(() => {

    fetchDataServ();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [END_POINT]);


  // useEffect para cargar la información en el formulario
  useEffect(() => {

    if (tblDetSolRepuesto && tblDetSolRepuesto.length > 0) {

      const FecDesp = tblDetSolRepuesto[0]?.FecDespacho ? tblDetSolRepuesto[0]?.FecDespacho.slice(0, 10) : "";
      const FecExport = tblDetSolRepuesto[0]?.FecExportacion ? tblDetSolRepuesto[0]?.FecExportacion.slice(0, 10) : "";
      const FecDespCliente = tblDetSolRepuesto[0]?.FecDespCliente ? tblDetSolRepuesto[0]?.FecDespCliente.slice(0, 10) : "";
      const FecTasa = tblDetSolRepuesto[0]?.FecTasa ? tblDetSolRepuesto[0]?.FecTasa.slice(0, 10) : "";

      setForm((val) => ({
        ...val, devolver: tblDetSolRepuesto[0]?.Devolver, guiaExport: tblDetSolRepuesto[0]?.GuiExportacion ?? "", fechaExport: FecExport,
        paraExport: tblDetSolRepuesto[0]?.Exportar, exportado: tblDetSolRepuesto[0]?.Exportado, so: tblDetSolRepuesto[0]?.SO ?? "", guiaDespa: tblDetSolRepuesto[0]?.GuiDespacho ?? "",
        carrier: tblDetSolRepuesto[0]?.Carrier ?? "", fecDespacho: FecDesp, entrAlma: tblDetSolRepuesto[0]?.EntAlmacen ?? "", salidaAlma: tblDetSolRepuesto[0]?.NumSalida ?? "",
        fleteImport: tblDetSolRepuesto[0]?.FleteImport ?? "", fleteExport: tblDetSolRepuesto[0]?.FleteExport ?? "", handlingCost: tblDetSolRepuesto[0]?.HandlingCost ?? "",
        numWR: tblDetSolRepuesto[0]?.NumWarehouse ?? "", dimen: tblDetSolRepuesto[0]?.Dimensiones ?? "", peso: tblDetSolRepuesto[0]?.Peso ?? "", carrClien: tblDetSolRepuesto[0]?.CarrCliente ?? "",
        guiDespCliente: tblDetSolRepuesto[0]?.GuiDespCliente ?? "", fecDespCliente: FecDespCliente, gestAdua: tblDetSolRepuesto[0]?.GesAduanal ?? "", dua: tblDetSolRepuesto[0]?.DUA ?? "", entDoc: tblDetSolRepuesto[0]?.EntrDocumentos ?? "",
        dateExchang: FecTasa, tasa: tblDetSolRepuesto[0]?.Tasa
      }));

      setOtherInf((val) => ({
        ...val, queEs: tblDetSolRepuesto[0]?.QueEs, funcion: tblDetSolRepuesto[0]?.Funcion, carFisica: tblDetSolRepuesto[0]?.CarFisica, carTecnica: tblDetSolRepuesto[0]?.CarTecnica, numParte: tblDetSolRepuesto[0]?.NumParte, descripcion: tblDetSolRepuesto[0]?.Descripcion, precio: tblDetSolRepuesto[0]?.Precio ?? 0
      }));

    }

  }, [tblDetSolRepuesto]);


  // useEffect para colocar el perfil en el estado
  useEffect(() => {

    if (EmpPerm && EmpPerm.length > 0) {
      setOtherInf((val) => ({ ...val, prof: EmpPerm[0]?.CodPerfil }));
    }

  }, [EmpPerm]);


  // useEffect para recargar la informacion de las partes cuando se guarde
  useEffect(() => {

    if (state.reload) {
      fetchDataServ();
      setForm((val) => ({ ...val, comment: "" }));

      const timer = setTimeout(() => {
        handleReloadData();
      }, 2000);

      return () => {
        clearTimeout(timer);
      };
    }

  }, [state.reload]);


  // useEffect para mostrar la tasa
  useEffect(() => {
    if (TasaDyn && TasaDyn.length > 0 && form.dateExchang) {
      if (!state.reload) {
        const FindDate = TasaDyn.find((dat) => new Date(dat.FECHA).getTime() === new Date(form.dateExchang).getTime());

        if (FindDate) {
          setForm((val) => ({ ...val, tasa: FindDate?.TASA }));
        } else {
          handleNotify(true, t("RequestPartsView.consultar_tasa"), true);
          setForm((val) => ({ ...val, tasa: "" }));
        }
      }
    }
  }, [TasaDyn, form.dateExchang]);




  return {
    state,
    form,
    otherInf,
    tblDetSegRepuesto,
    firstInfo,
    onHandleChangeForm,
    onSaveInfoModal,
    onCloseModal,
    handleSendServer,
    handleReloadData
  };
};