// componente para seleccionar el cliente

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// react router dom imports
import { useLocation, useNavigate } from "react-router-dom";

// icons importation
import { AddDbIcon, Close } from "../icons/AppIcons.jsx";




export const SelectCustomerComp = ({ onClose, onAddCust, EndPoint, HttMethod, token }) => {

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the useLocation
  const location = useLocation();

  // GET the react translation
  const [t] = useTranslation("global");

  // state definition
  const [nombre, setNombre] = useState("");
  const [customers, setCustomers] = useState([]); // estado para colocar los clientes que vienen del servidor
  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor
  const [state, setState] = useState({ isLoading: false });


  const filterCustomers = customers && customers.filter((cus) => cus.Nombre.toLowerCase().includes(nombre.toLowerCase()));

  // funcion para colocar el cliente
  const onHandleAddCustomer = (codCli = "") => {
    const findCustomer = customers && customers.find((cus) => cus.CodCliente === codCli);

    onAddCust(findCustomer?.CodCliente, findCustomer?.Nombre);

    onClose();
  };


  // funcion para realizar el Fetch
  const fetchDataServer = async () => {
    const abortController = new AbortController();

    setController(abortController);
    try {
      setState((val) => ({ ...val, isLoading: true }));

      const response = await fetch(EndPoint, {
        method: HttMethod,
        headers: {
          "Authorization": token,
          "Content-Type": "application/json"
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setCustomers(data?.customers);
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para realizar la peticion al servidor
  useEffect(() => {
    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, []);



  return (
    <div className="background-modal">
      <div className="container-select-customer">
        <div className="modal-header">
          <h3>Clientes</h3>
          <button type="button" className="btn-close" onClick={onClose}>
            <Close />
          </button>
        </div>
        <div className="modal-body">
          {
            state.isLoading && (
              <strong>{t("PartModify.cargando_info")}</strong>
            )
          }
          {/* fila */}
          <div className="row-flex">
            <div className="col-7">
              <strong>Nombre</strong>
            </div>
            <div className="col-80">
              <input
                className="input-text-control"
                type="text"
                name="nameCustom"
                value={nombre}
                onChange={({ target }) => setNombre(target.value)}
                autoComplete="off" />
            </div>
          </div>
          {/* fila */}
          {
            filterCustomers && filterCustomers.map((cli) => (
              <div key={cli.CodCliente} className="row-flex hover-class border-bottom">
                <div className="col-80">
                  <strong>{cli.Nombre}</strong>
                </div>
                <div className="col-5 row-flex flex-end">
                  <button type="button" style={{ cursor: "pointer" }} onClick={() => onHandleAddCustomer(cli.CodCliente)}>
                    <AddDbIcon fillP="var(--first-color)" />
                  </button>
                </div>
              </div>
            ))
          }
        </div>
        <div className="modal-footer j-content-space-around">
          <button type="button" className="btn-secondary" onClick={onClose}>Cerrar</button>
        </div>
      </div>
    </div>
  );
};