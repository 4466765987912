import React, { useState, useContext, useEffect } from "react";

// REACT ROUTER DOM IMPORTATION
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

// COMPONENTS IMPORTATION
import { LoadingComp } from "../../components/Loading-comp/LoadingComp.jsx";
import { PartDetailModify } from "../../components/logistic-components/PartDetailModify.jsx";
import { Error403View } from "../error-403-view/Error403View.jsx";
import { ModalConfirm } from "../../components/modal-confirm/ModalConfirm.jsx";


// context importation
import { LangContext } from "../../context/LangContext.jsx";
import { NotificationContext } from "../../context/NotificationContext.jsx";


// URLS IMPORTATION
import { urls } from "../../api-urls/api-urls.js";

// FUNCTION GLOBAL
import { getTheToken, renderDate, renderTemperature } from "../../global-functions/globalFunctions.js";

// REACT TRANSLATION IMPORTATION
import { useTranslation } from "react-i18next";

// custom hook importation
import { useFetchSolPrice } from "../../components/customs/sol-precio/useFetchSolPrice.js";


export const SolPrecioView = () => {


  // GET the location
  const location = useLocation();

  // NAVIGATE
  const navigate = useNavigate();

  // GET the useParams
  const { id } = useParams();

  // GET the react translation
  const [t] = useTranslation("global");

  // lang importation
  const { lang } = useContext(LangContext);

  const { handleNotify } = useContext(NotificationContext);


  // STATE DECLARATION
  const [stateInt, setStateInt] = useState({ redirect: false, codSol: 0, codDet: 0, modalPart: false, reload: false });


  // custom hook
  const { state,
    form,
    parts,
    solRep,
    empPerm,
    handleListPrice,
    handleDiscount,
    handleItbis,
    handleChangeVal,
    handlePerformAction,
    handleChangeFormVal,
    handleCloseOpenModal,
    onReloadFromChild,
    handleClearForm,
    handleSendServer } = useFetchSolPrice(id, urls, getTheToken().token, lang);


  // // FUNCION PARA ABRIR EL MODAL Y ENVIARLE EL CodDetSolRepuesto
  const handleOpenModalPart = (CodSol = 0, CodDet = 0) => {
    setStateInt((val) => ({ ...val, codSol: CodSol, codDet: CodDet, modalPart: true }));
  };


  // funcion para cerrar el modal de las partes
  const onCloseModal = () => {
    setStateInt((val) => ({ ...val, modalPart: false }));
  };



  // handleSubmit
  const handleSubmit = async (event) => {
    event.preventDefault();

    const finalObj = { form, parts };

    try {
      // setState((val) => ({ ...val, update: true }));
      handleSendServer(true);
      const response = await fetch(`${urls.priceRequest}/${id}/search?language=${lang}`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(finalObj)
      });

      const data = await response.json();

      if (response.status === 200) {
        handleNotify(true, data?.msg, false, true);
        setStateInt((val) => ({ ...val, redirect: data?.Redirect }));
        handleCloseOpenModal();
        handleClearForm();
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      }
      
      handleSendServer(false);

    } catch (error) {
      console.log(error);
    }
  };



  // useEffect para redirigir a principal de logistica
  useEffect(() => {
    if (stateInt.redirect) {

      const timer = setTimeout(() => {
        navigate("/logistic-principal");
      }, 3000);

      return () => {
        clearTimeout(timer);
      }

    }
  }, [stateInt.redirect]);


  return (
    <>
      {/* Error 403 component */}
      {state.unauthorized && (<Error403View />)}
      {/* Loading component */}
      {state.isLoading && (<LoadingComp />)}
      {
        stateInt.modalPart && (<PartDetailModify
          employeePerm={empPerm}
          solRepuesto={stateInt.codSol}
          onReload={onReloadFromChild}
          codDetSolRepuesto={stateInt.codDet}
          onClose={onCloseModal} />)
      }
      {
        state.dataVisible && (
          <div className="container">
            <form onSubmit={handleSubmit}>
              <div style={{ boxShadow: 'var(--box-shadow-bottom)' }} className="p-1 b-radius">
                {/* PRIMERA FILA, solicitud, id servicio, modelo del equipo, serial */}
                <div className="row-flex">
                  {/* SOLICITUD */}
                  <div className="col-9 col-sm-30">
                    <strong>{t("RequestPartsView.soli")}</strong>
                  </div>
                  <div className="col-4 col-sm-70-mod">
                    <span style={{ fontWeight: 'bold', color: 'var(--first-color)' }}>{id}</span>
                  </div>
                  {/* id.Servicio */}
                  <div className="col-10 col-sm-30">
                    <strong>{t("RequestPartsView.id_serv")}</strong>
                  </div>
                  <div className="col-4 col-sm-70-mod">
                    <span>
                      <Link className="link-tag" to={`/service/service-call/${solRep?.CodServicio}`}>{solRep?.CodServicio}</Link>
                    </span>
                  </div>
                  {/* MODELO */}
                  <div className="col-10 col-sm-30">
                    <strong>{t("RequestPartsView.modelo")}</strong>
                  </div>
                  <div className="col-5 col-sm-70-mod">
                    <span>{solRep?.Modelo}</span>
                  </div>
                </div>

                {/* SEGUNDA FILA */}
                <div className="row-flex">
                  {/* FECHA */}
                  <div className="col-9 col-sm-30">
                    <strong>{t("RequestPartsView.fecha")}</strong>
                  </div>
                  <div className="col-4 col-sm-70-mod">
                    <span>{renderDate(solRep?.FecIngreso, lang)}</span>
                  </div>
                  {/* SERIAL */}
                  <div className="col-10 col-sm-30">
                    <strong>Serial</strong>
                  </div>
                  <div className="col-4 col-sm-70-mod">
                    <span>{solRep?.Serial}</span>
                  </div>
                  {/* FDO */}
                  <div className="col-10 col-sm-30">
                    <strong>FDO</strong>
                  </div>
                  <div className="col-5 col-sm-70-mod">
                    <span>{solRep?.FDO}</span>
                  </div>
                </div>

                {/* TERCERA FILA */}
                <div className="row-flex">
                  {/* CLIENTE */}
                  <div className="col-9 col-sm-30">
                    <strong>{t("RequestPartsView.cliente")}</strong>
                  </div>
                  <div className="col-58 col-sm-70-mod">
                    <Link className="link-tag" to={`/customers/customer-detail/${solRep?.CodCliente}`}>{solRep?.NomCliente}</Link>
                  </div>

                  {/* O/C */}
                  <div className="col-10 col-sm-30">
                    <strong>O/C</strong>
                  </div>
                  <div className="col-5 col-sm-70-mod">
                    <span>{solRep?.NumOrden}</span>
                  </div>
                </div>

                {/* CUARTA FILA */}
                <div className="row-flex">
                  {/* EQUIPO */}
                  <div className="col-9 col-sm-30">
                    <strong>{t("RequestPartsView.equip")}</strong>
                  </div>
                  <div className="col-58 col-sm-70-mod">
                    <Link className="link-tag" to={`/equipments/equipment-detail/${solRep?.CodEquipo}`}>{solRep?.NomEquipo}</Link>
                  </div>
                  {/* CONTRATO */}
                  <div className="col-10 col-sm-30">
                    <strong>{t("RequestPartsView.contr")}</strong>
                  </div>
                  <div className="col-5 col-sm-70-mod">
                    <span>{solRep?.NomContract}</span>
                  </div>
                  <div className="col-9 col-sm-30">
                    <strong>{t("RequestPartsView.prio")}</strong>
                  </div>
                  <div className="col-5 col-sm-70-mod">
                    <span className={renderTemperature(solRep?.Prioridad)}></span>
                  </div>
                </div>

                <div className="row-flex">
                  <div className="col-9 col-sm-30">
                    <strong>{t("RequestPartsView.ing_tec")}</strong>
                  </div>
                  <div className="col-4 col-sm-70-mod">
                    <span>{solRep?.NomEmpIngreso}</span>
                  </div>
                  <div className="col-10 col-sm-30">
                    <strong>{t("RequestPartsView.falla")}</strong>
                  </div>
                  <div className="flex-auto col-sm-70-mod">
                    <span style={{ color: 'var(--danger-color)', textDecoration: 'underline' }}>{solRep?.Falla}</span>
                  </div>
                </div>

                {/* ULTIMA FILA */}
                <div className="row-flex">
                  {/* ULTIMA ACCION */}
                  <div className="col-9 col-sm-30">
                    <strong>{t("RequestPartsView.ult_accion")}</strong>
                  </div>
                  <div className="flex-auto col-sm-70-mod">
                    <span style={{ textTransform: 'capitalize', color: 'green', textDecoration: 'underline' }}>{solRep?.LastComment}</span>
                  </div>
                </div>
              </div>

              {/* TIPO DE APROBACION */}
              <div className="row-flex m-y-small">
                <div className="col-9 col-sm-30">
                  <strong>{t("RequestPartsView.tip_aproba")}</strong>
                </div>
                <div className="col-4 col-sm-70-mod">
                  <select
                    name="IdTipAprobacion"
                    value={form.tipApro}
                    onChange={({ target }) => handleChangeFormVal("tipApro", target.value)}>
                    <option value=""></option>
                    <option value="0">Stock</option>
                    <option value="3">{t("RequestPartsView.designado")}</option>
                    <option value="6">{t("RequestPartsView.garan_ventas")}</option>
                    <option value="9">{t("RequestPartsView.export")}</option>
                    <option value="1">{t("RequestPartsView.activo_fij")}</option>
                    <option value="4">FMI/STB</option>
                    <option value="7">{t("RequestPartsView.garan_sopor")}</option>
                    <option value="10">{t("RequestPartsView.compr_local")}</option>
                    <option value="2">{t("RequestPartsView.contr")}</option>
                    <option value="5">Missing</option>
                    <option value="8">{t("RequestPartsView.gasto")}</option>
                  </select>
                </div>
              </div>


              {/* COMENTARIO */}
              <div className="row-flex">
                <div className="col-9 col-sm-30">
                  <strong>{t("RequestPartsView.comentario")}</strong>
                </div>
                <div className="col-88-89 col-sm-70-mod">
                  <textarea
                    className="textarea-description"
                    name="comment"
                    value={form.comentario}
                    onChange={({ target }) => handleChangeFormVal("comentario", target.value)} />
                </div>
              </div>
              {/* WORKFLOW */}
              <div className="row-flex">
                <div className="col-9 col-sm-30">
                  <strong>Workflow</strong>
                </div>
                <div className="col-4 col-sm-70-mod">
                  <input
                    className="input-text-control"
                    type="text"
                    name="workflow"
                    value={form.workFlow}
                    onChange={({ target }) => handleChangeFormVal("workFlow", target.value)}
                    autoComplete="off" />
                </div>
              </div>
              <hr className="m-y-small" />

              {/* TABLA PARA COLOCAR LAS PARTES */}
              <div className="table-container-mobile">
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th>#</th>
                      <th>{t("TableParts.canti")}</th>
                      <th>{t("TableParts.parte")}</th>
                      <th>{t("TableParts.descrip")}</th>
                      <th>{t("TableParts.precio_lista")}</th>
                      <th>{t("TableParts.descuento")}</th>
                      <th>{solRep?.CodDYN !== "GLOBL" ? "VAT" : "ITBIS"}</th>
                      <th>{t("TableParts.precio")}</th>
                      <th>{t("TableParts.devolver")}</th>
                      <th>{t("TableParts.dispo")}</th>
                      <th>{t("TableParts.coment")}</th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {
                      parts && parts.map((part) => (
                        <tr key={part.CodDetSolRepuesto}>
                          <td className="txt-center" style={{ width: '1rem' }}>
                            <button type="button" className="btn-link-tag" onClick={() => handleOpenModalPart(part.CodSolRepuesto, part.CodDetSolRepuesto)}>
                              {part.CodDetSolRepuesto}
                            </button>
                          </td>
                          <td className="txt-center">{part.Cantidad}</td>
                          <td className="col-7">
                            <Link className="link-tag" to={`/part-detail/${part.CodDetSolRepuesto}/${part.CodSolRepuesto}`}>{part.NumParte} {part.Alternativo ? (part.Alternativo) : ""}</Link>
                          </td>
                          <td className="col-3">{part.Descripcion}</td>
                          <td className="col-10">
                            <input
                              style={{ textAlign: 'right' }}
                              className="input-text-control"
                              type="text"
                              name={`precioLista-${part.CodDetSolRepuesto}`}
                              value={part.Lista}
                              onChange={({ target }) => handleListPrice(part.CodSolRepuesto, part.CodDetSolRepuesto, target.value)}
                              autoComplete="off" />
                          </td>
                          <td className="col-10">
                            <input
                              style={{ textAlign: 'right' }}
                              className="input-text-control"
                              type="text"
                              name={`descuento-${part.CodDetSolRepuesto}`}
                              value={part.Descuento}
                              onChange={({ target }) => handleDiscount(part.CodSolRepuesto, part.CodDetSolRepuesto, target.value)}
                              autoComplete="off" />
                          </td>
                          <td className="col-10">
                            <input
                              style={{ textAlign: 'right' }}
                              className="input-text-control"
                              type="text"
                              name={`itbis-${part.CodDetSolRepuesto}`}
                              value={part.Itbis}
                              onChange={({ target }) => handleItbis(part.CodSolRepuesto, part.CodDetSolRepuesto, target.value)} />
                          </td>
                          <td className="col-10">
                            <input
                              style={{ textAlign: 'right' }}
                              className="input-text-control input-width-sol-price"
                              type="text"
                              name={`precCal-${part.CodDetSolRepuesto}`}
                              value={part.Precio}
                              readOnly />
                          </td>
                          <td className="input-select-sol-price">
                            <select
                              name={`devolver-${part.CodDetSolRepuesto}`}
                              value={part.Devolver}
                              onChange={({ target }) => handleChangeVal(part.CodSolRepuesto, part.CodDetSolRepuesto, target.value, "Devolver")}>
                              <option value="1">{t("RequestPartsView.si")}</option>
                              <option value="0">No</option>
                            </select>
                          </td>
                          <td className="input-select-sol-price">
                            <select
                              name={`disponible-${part.CodDetSolRepuesto}`}
                              value={part.Disponible}
                              onChange={({ target }) => handleChangeVal(part.CodSolRepuesto, part.CodDetSolRepuesto, target.value, "Disponible")}>
                              <option value="1">{t("RequestPartsView.si")}</option>
                              <option value="0">No</option>
                            </select>
                          </td>
                          <td className="col-8">
                            <input
                              className="input-text-control"
                              type="text"
                              name={`comentario-${part.CodDetSolRepuesto}`}
                              value={part.BackOrder}
                              onChange={({ target }) => handleChangeVal(part.CodSolRepuesto, part.CodDetSolRepuesto, target.value, "BackOrder")}
                              placeholder={lang === "en" ? "Part's comments..." : "Comentarios de las partes..."}
                              autoComplete="off" />
                          </td>
                        </tr>
                      )
                      )
                    }
                  </tbody>
                </table>
              </div>

              {/* BOTONOS DE ACCION */}
              <div className="row-flex j-content-space-around m-y-medium">
                {/* BTN APROBAR */}
                <button type="button" className="btn-primary" onClick={() => handlePerformAction(1)}>{t("TableParts.aprobar")}</button>
                {/* BTN RECHAZAR */}
                <button type="button" className="btn-warning" onClick={() => handlePerformAction(2)}>{t("serviceCard.rechazar")}</button>
                {/* BTN COMENTAR */}
                <button type="button" className="btn-success" onClick={() => handlePerformAction(3)}>{t("RequestPartsView.comentario")}</button>
                {/* BTN CANCELAR */}
                <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>{t("navigationMenu.backBtn")}</button>
              </div>

              {/* Modal para confirmar si está seguro de que quiere enviar el formulario */}
              {
                state.showModal && (
                  <ModalConfirm onClose={handleCloseOpenModal} question={state.question} sending={state.sending} />
                )
              }

            </form>

          </div>
        )
      }
    </>
  );
};