// este componente es para renderizar los pendientes de operaciones, servicio 
// y que el admin los pueda visualizar

// react importation
import { useEffect, useState } from "react";

// global functions imports
import { getTheToken, renderDate, renderTempCard, renderTemperature } from "../../global-functions/globalFunctions";

// components import
import { LoadingComp } from "../Loading-comp/LoadingComp";
import { ServiceStatistics } from "./ServiceStatistics";


// react router dom import
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { urls } from "../../api-urls/api-urls";




export const ServiceProfile = ({ etapasCorrecArr }) => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);


  // GET the useNavigate()
  const navigate = useNavigate();



  // params definition
  const [params, setParams] = useSearchParams();



  // estado para programacion de visita
  // const [progVisita, setProgVisita] = useState([]); // estado para colocar la programacion de visita
  const [dataServ, setDataServ] = useState([]); // estado para colocar la data que viene del server
  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor
  const [state, setState] = useState({ isLoading: false, dataVisible: false, zona: params.get("area") ?? "", grupo: params.get("grp") ?? "" });




  // destructuring data server
  const { aproTecnica, solHerramienta, solRepuesto, solAproSoporte, aproAdministrativa, conducesPendRecibir, solPorDespachar, empPerm } = dataServ && dataServ;


  // funcion para cambiar la propiedad de show para que el usuario vea el comentario
  // const onChangeShowValue = (servCall) => {
  //   setProgVisita((val) => {
  //     const updateVal = val.map((calls) => {
  //       if (calls.CodServicio === servCall) {
  //         return { ...calls, Show: !calls.Show };
  //       }
  //       return calls;
  //     });
  //     return updateVal;
  //   });
  // };


  // funcion para cambiar la zona
  const onHandleChangeVal = (newVal = "", name = "", para = "") => {
    params.set("filt", 1);
    params.set(para, newVal);
    setParams(params);
    setState((val) => ({ ...val, [name]: newVal }));
  };


  // funcion para solicitar la informacion al servidor
  const fetchDataServ = async () => {
    const abortController = new AbortController();

    setController(abortController);

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.myTasks}?language=${language}&filtro=${params.get("filt") ?? "0"}&zona=${params.get("area") ?? ""}`, {
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServ(data);
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        return navigate(-1, { replace: true });
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };



  // useEffect para traer la data del servidor
  useEffect(() => {
    fetchDataServ();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [language, state.zona, state.grupo]);


  // useEffect para cargar la zona en el estado
  useEffect(() => {
    if (empPerm && empPerm.length > 0) {

      if (params.get("area") === null) {
        setState((val) => ({ ...val, zona: empPerm[0]?.CodZona }));
      }

      if (params.get("grp") === null) {
        setState((val) => ({ ...val, grupo: empPerm[0]?.CodGrupo ?? "" }));
      }

      if (![1, 3].includes(empPerm[0]?.CodPerfil)) {
        setState((val) => ({ ...val, dataVisible: false }));
        navigate("/home");
      }

    }

  }, [empPerm]);




  return (
    <div className="container display-data">
      {
        state.isLoading && (<LoadingComp />)
      }

      {
        state.dataVisible && (
          <>

            <div className="row-flex gap-medium m-y-medium d-col-rever">
              <ServiceStatistics area={state.zona} lang={language} token={getTheToken().token} grup={state.grupo} />

              {/* Card para los filtros */}
              <div className="card-shadow card-info-filter">
                <h5>Filtros</h5>
                <form>
                  <div className="row-flex">
                    <div className="col-3">
                      <strong>Zona</strong>
                    </div>
                    <div className="col-61-58">
                      <select
                        name="zona"
                        value={state.zona}
                        onChange={({ target }) => onHandleChangeVal(target.value, "zona", "area")}>
                        <option value=""></option>
                        <option value="1">República Dominicana</option>
                        <option value="6">Caribe</option>
                        <option value="7">Trinidad y Tobago</option>
                      </select>
                    </div>
                  </div>
                  <div className="row-flex">
                    <div className="col-3">
                      <strong>Grupo</strong>
                    </div>
                    <div className="col-61-58">
                      <select
                        name="grp"
                        value={state.grupo}
                        onChange={({ target }) => onHandleChangeVal(target.value, "grupo", "grp")}>
                        <option value=""></option>
                        <option value="1">Servicio</option>
                        <option value="3">Ventas</option>
                      </select>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            {/*  Aprobacion tecnica y Herramientas pendientes por aprobacion de salida */}
            <div className="row-flex">
              <div className="col-6">
                <h5 className="title-pendings">Aprobación técnica ({aproTecnica && aproTecnica.length})</h5>
              </div>
              <div className="col-6">
                <h5 className="title-pendings">Herramientas pendientes por aprobación de salida ({solHerramienta && solHerramienta.length})</h5>
              </div>
              <div className="col-6 border-bottom height-card" style={{ overflow: "auto" }}>
                {
                  aproTecnica && aproTecnica.map((apro) => (
                    <div key={apro.CodSolRepuesto} className={`row-flex m-y-small card-shadow ${renderTempCard(apro.Prioridad)}`}>
                      <div className="col-5">
                        <strong>Cliente</strong>
                      </div>
                      <div className="col-80">
                        <Link className="link-tag">{apro.NomCliente}</Link>
                      </div>
                      <div className="col-5">
                        <strong>Equipo</strong>
                      </div>
                      <div className="col-80">
                        <Link className="link-tag">{apro.Equipo}</Link>
                      </div>
                      <div className="col-5">
                        <strong>Solicitado por</strong>
                      </div>
                      <div className="col-80">
                        <span className="txt-muted">{apro.EmpSolicitante} - {renderDate(apro.Fecha, language)}</span>
                      </div>
                      <div className="col-5">
                        <strong>Zona</strong>
                      </div>
                      <div className="col-3">
                        <span className="txt-muted">{apro.Zona}</span>
                      </div>
                      <div className="col-3 row-flex flex-end">
                        <button type="button" className="btn-review" onClick={() => navigate(`/warehouse/technical-approval/technical-detail/${apro.CodSolRepuesto}`)}>Ver</button>
                      </div>
                    </div>
                  ))
                }
              </div>
              <div className="col-6 border-bottom height-card" style={{ overflow: "auto" }}>
                {
                  solHerramienta && solHerramienta.map((herr) => (
                    <div key={herr.CodSolHerramienta} className={`row-flex m-y-small card-shadow ${renderTempCard(herr.Prioridad)}`}>
                      <div className="col-5">
                        <strong>Cliente</strong>
                      </div>
                      <div className="col-80">
                        <Link className="link-tag">{herr.NomCliente}</Link>
                      </div>
                      <div className="col-5">
                        <strong>Equipo</strong>
                      </div>
                      <div className="col-80">
                        <Link className="link-tag">{herr.Equipo}</Link>
                      </div>
                      <div className="col-5">
                        <strong>Solicitado por</strong>
                      </div>
                      <div className="col-80">
                        <span className="txt-muted">{herr.EmpSolicita} - {renderDate(herr.Fecha, language)}</span>
                      </div>
                      <div className="col-5">
                        <strong>Zona</strong>
                      </div>
                      <div className="col-3">
                        <span className="txt-muted">{herr.Zona}</span>
                      </div>
                      <div className="col-3 row-flex flex-end">
                        <button type="button" className="btn-review" onClick={() => navigate(`/service/asset-approval-detail/${herr.CodSolHerramienta}`)}>Ver</button>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>

            {/* Solicitud de repuestos pendientes y Pendiente por aprobacion del soporte */}
            <div className="row-flex m-y-small">
              <div className="col-6">
                <h5 className="title-pendings">
                  {
                    // Si es de operaciones y es coordinador
                    (empPerm[0]?.CodPerfil === 3 && empPerm[0]?.Coordinador) && (
                      `Solicitudes pendientes por aprobación administrativa (${aproAdministrativa && aproAdministrativa.length})`
                    )
                  }
                  {
                    // Si es de servicio 
                    (empPerm[0]?.CodPerfil === 1) && (
                      `Solicitudes de repuestos pendientes (${solRepuesto && solRepuesto.length})`
                    )
                  }
                </h5>
              </div>
              <div className="col-6">
                <h5 className="title-pendings">Pendientes por aprobación del soporte ({solAproSoporte && solAproSoporte.length})</h5>
              </div>
              <div className="col-6 border-bottom height-card" style={{ overflow: "auto" }}>
                {
                  // Si es de operaciones y es coordinador se renderiza las solicitudes de aprobacion administrativa
                  (empPerm[0]?.CodPerfil === 3 && empPerm[0]?.Coordinador) && (
                    aproAdministrativa && aproAdministrativa.map((apro) => (
                      <div key={apro.CodSolRepuesto} className={`row-flex card-shadow m-y-small ${renderTempCard(apro.Prioridad)}`}>
                        <div className="col-5">
                          <strong>Solicitud</strong>
                        </div>
                        <div className="col-80">
                          <Link className="link-tag" to={`/warehouse/administrative-approval/approval-detail/${apro.CodSolRepuesto}`}>{apro.CodSolRepuesto}</Link>
                        </div>
                        <div className="col-5">
                          <strong>Cliente</strong>
                        </div>
                        <div className="col-80">
                          <Link className="link-tag">{apro.NomCliente}</Link>
                        </div>
                        <div className="col-5">
                          <strong>Equipo</strong>
                        </div>
                        <div className="col-80">
                          <Link className="link-tag">{apro.Equipo}</Link>
                        </div>
                        <div className="col-5">
                          <strong>Solicitado por</strong>
                        </div>
                        <div className="col-80">
                          <span className="txt-muted">{apro.EmpSolicita} - {renderDate(apro.FecIngreso, language)}</span>
                        </div>
                        <div className="col-5">
                          <strong>Zona</strong>
                        </div>
                        <div className="col-3">
                          <span className="txt-muted">{apro.Zona}</span>
                        </div>
                        <div className="col-3 row-flex flex-end">
                          <button type="button" className="btn-review" onClick={() => navigate(`/warehouse/administrative-approval/approval-detail/${apro.CodSolRepuesto}`)}>Ver</button>
                        </div>
                      </div>
                    ))
                  )
                }
                {
                  // Si es de servicio
                  (empPerm[0]?.CodPerfil === 1) && (
                    solRepuesto && solRepuesto.map((solRe) => (
                      <div key={solRe.CodSolRepuesto} className={`row-flex card-shadow m-y-small ${renderTempCard(solRe.Prioridad)}`}>
                        <div className="col-5">
                          <strong>Cliente</strong>
                        </div>
                        <div className="col-80">
                          <Link className="link-tag">{solRe.NomCliente}</Link>
                        </div>
                        <div className="col-5">
                          <strong>Equipo</strong>
                        </div>
                        <div className="col-80">
                          <Link className="link-tag">{solRe.Equipo}</Link>
                        </div>
                        <div className="col-5">
                          <strong>Solicitado por</strong>
                        </div>
                        <div className="col-80">
                          <span className="txt-muted">{solRe.EmpSolicita} - {renderDate(solRe.FecIngreso, language)}</span>
                        </div>
                        <div className="col-5">
                          <strong>Zona</strong>
                        </div>
                        <div className="col-3">
                          <span className="txt-muted">{solRe.Zona}</span>
                        </div>
                        <div className="col-3 row-flex flex-end">
                          <button type="button" className="btn-review">Ver</button>
                        </div>
                      </div>
                    ))
                  )
                }
              </div>
              <div className="col-6 border-bottom height-card" style={{ overflow: "auto" }}>
                {
                  solAproSoporte && solAproSoporte.map((aprSopo) => (
                    <div key={aprSopo.CodSolRepuesto} className={`row-flex card-shadow m-y-small ${renderTempCard(aprSopo.Prioridad)}`}>
                      <div className="col-5">
                        <strong>Cliente</strong>
                      </div>
                      <div className="col-80">
                        <Link className="link-tag">{aprSopo.NomCliente}</Link>
                      </div>
                      <div className="col-5">
                        <strong>Equipo</strong>
                      </div>
                      <div className="col-80">
                        <Link className="link-tag">{aprSopo.Equipo}</Link>
                      </div>
                      <div className="col-5">
                        <strong>Solicitado por</strong>
                      </div>
                      <div className="col-80">
                        <span className="txt-muted">{aprSopo.EmpSolicita} - {renderDate(aprSopo.FecIngreso, language)}</span>
                      </div>
                      <div className="col-5">
                        <strong>Zona</strong>
                      </div>
                      <div className="col-3">
                        <span className="txt-muted">{aprSopo.Zona}</span>
                      </div>
                      <div className="col-3 row-flex flex-end">
                        <button type="button" className="btn-review" onClick={() => navigate(`/logistic/zone-support-approval/approval-detail/${aprSopo.CodSolRepuesto}`)}>Ver</button>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>



            {/* Conduces pendientes por recibir por el especialista tecnico y solicitudes por despachar */}
            <div className="row-flex">
              <div className="col-6">
                <h5>Conduces pendientes por recibir por el especialista técnico</h5>
              </div>
              <div className="col-6">
                <h5>Solicitudes por despachar ({solPorDespachar && solPorDespachar.length})</h5>
              </div>
              <div className="col-6 border-bottom height-card">

                {/* {
                  ConducesPendientes && ConducesPendientes.map((condPen) => (

                  ))
                } */}
              </div>
              {
                solPorDespachar && solPorDespachar.length > 0 && (
                  <div className="col-6 border-bottom height-card" style={{ overflow: "auto" }}>

                    {
                      solPorDespachar && solPorDespachar.map((des) => (
                        <div key={des.NumOrden} className={`row-flex card-shadow m-y-small ${renderTempCard(des.Prioridad)}`}>
                          <div className="col-5">
                            <strong>Id Servicio</strong>
                          </div>
                          <div className="col-4">
                            <Link className="link-tag" to={`/service/service-call/${des.CodServicio}`}>{des.CodServicio}</Link>
                          </div>
                          <div className="col-5">
                            <strong>Solicitud</strong>
                          </div>
                          <div className="col-4">
                            <strong>{des.CodSolRepuesto}</strong>
                          </div>
                          <div className="col-5">
                            <strong>Cliente</strong>
                          </div>
                          <div className="col-80">
                            <Link className="link-tag">{des.NomCliente}</Link>
                          </div>
                          <div className="col-5">
                            <strong>Equipo</strong>
                          </div>
                          <div className="col-80">
                            <Link className="link-tag">{des.Equipo}</Link>
                          </div>
                          <div className="col-5">
                            <strong>Solicitado por</strong>
                          </div>
                          <div className="col-80">
                            <span className="txt-muted">{des.EmpSolicita} - {renderDate(des.Fecha, language)}</span>
                          </div>
                          <div className="col-5">
                            <strong>Zona</strong>
                          </div>
                          <div className="col-3">
                            <span className="txt-muted">{des.Zona}</span>
                          </div>
                          <div className="col-3 row-flex flex-end">
                            <button type="button" className="btn-review" onClick={() => navigate(`/warehouse/dispatch-instructions/${des.CodSolRepuesto}`)}>Ver</button>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                )
              }
            </div>

            <div className="row-flex m-y-small">
              <div className="col-6">
                <h5></h5>
              </div>
            </div>





          </>
        )
      }
    </div>
  );
};