// custom hook para las llegadas de partes de almacén

import { useEffect, useState } from "react";

// React router dom import
import { useNavigate } from "react-router-dom";
import { getTheToken } from "../../../global-functions/globalFunctions";


export const useFetchLlegPart = (END_POINT = "", Method = "", CodSolRep = "", Language = "") => {

  // state definition
  const [partes, setPartes] = useState([]);
  const [controller, setController] = useState(null);
  const [state, setState] = useState({ isLoading: false, dataVisible: false, question: "", showModal: false, notFound: false });
  const [form, setForm] = useState({ guiaLleg: "", entrAlm: "", comment: "", action: "" });


  // GET the useNavigate
  const navigate = useNavigate();


  // Funcion para cerrar el formulario
  const handleCloseModal = () => {
    setState((val) => ({ ...val, showModal: false }));
  };


  // Funcion para modificar los valores del formulario
  const handleChangeForm = (name = "", valP = "") => {
    setForm((val) => ({ ...val, [name]: valP }));
  };

  // Funcion para limpiar los inputs del form
  const handleClearForm = () => {
    setForm((val) => ({ ...val, guiaLleg: "", entrAlm: "", comment: "", action: "" }));
  };


  // Funcion para el manejo del formulario y confirmacion
  const handleSaveReturn = (act = "") => {

    setForm((val) => ({ ...val, action: act }));

    const QUESTION = {
      "1": "Está seguro de enviar las piezas a la siguiente etapa?",
      "2": "Está seguro de enviar las piezas a nacionalización?"
    };
    setState((val) => ({ ...val, showModal: true, question: QUESTION[act] }));
  };


  const handleChangeValParts = (codDet = 0, codSol = 0, newVal = "", modP = "") => {
    setPartes((val) => {
      return val.map((part) => {
        if (codDet === part.CodDetSolRepuesto && codSol === part.CodSolRepuesto) {
          if (modP === "serial") {
            return { ...part, Serial: newVal };
          }
          if (modP === "qty") {
            if (isNaN(newVal)) {
              return part;
            }
            if (+newVal > part.Cantidad) {
              return part;
            }
            return { ...part, CanLlego: newVal };
          }
        }
        return part;
      });
    });
  };


  const fetchDataServer = async () => {
    try {
      setState((val) => ({ ...val, isLoading: true }));

      const response = await fetch(END_POINT, {
        method: Method,
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        }
      });

      const data = await response.json();

      if (response.status === 200) {
        setPartes(data?.detSolRep);
        setForm((val) => ({ ...val, guiaLleg: data?.detSolRep[0]?.GuiDespacho ?? "", entrAlm: data?.detSolRep[0]?.EntAlmacen ?? "" }));
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 400) {
        // bad request
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        setState((val) => ({ ...val, dataVisible: false, unauthorized: true }));
      } else if (response.status === 500) {
        setState((val) => ({ ...val, dataVisible: false, serverError: true }));
      } else if (response.status === 404) {
        setState((val) => ({ ...val, notFound: false }));
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // consultar la informacion al servidor
  useEffect(() => {

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [CodSolRep, Language]);


  // montar la guia y la entrada de almacen en el estado
  // useEffect(() => {

  // }, partes);




  return {
    partes,
    state,
    form,
    handleChangeValParts,
    handleSaveReturn,
    handleChangeForm,
    handleClearForm,
    handleCloseModal
  }
};