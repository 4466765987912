// componente para las solicitudes de consumo

import { useState, useEffect, useContext } from "react";

import { useNavigate } from "react-router-dom";


// import context
import { NotificationContext } from "../../../context/NotificationContext.jsx";





export const useFetchSolConsumo = (EndPoint = "", lang = "", CodSol = "", token = "", CompId = 0, Method = "GET") => {

  // params (CompId) --> 1: SolConsumoDetailView.jsx

  // GET the notification context
  const { handleNotify } = useContext(NotificationContext);

  // PATRON PARA VALIDAR EL CORREO
  const patron = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;


  // state definition
  const [state, setState] = useState({ isLoading: false, dataVisible: false, msg: "", unauthorized: false, modalSave: false, loadingData: false, questionAction: "", modalDocs: false, modalConfirm: false, reload: false });
  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor
  const [form, setForm] = useState({
    solic: "", nomEtapa: "", tarjeta: "", titu: "", division: "", gerencia: "", consuFin: "", numItiner: "", monto: "", moneda: "", fecReque: "",
    concept: "", comentario: "", idEtapa: 0, idGeren: "", idDivi: "", nomMoneda: "", idTarj: "", fecVen: "", documento: false, action: "", empSoli: 0,
    lider: 0, empresa: "", tipGasto: "", nomEmpresa: "", nomTipGasto: "", fecFinHospe: "", EmpAutoriza: null, montFinal: "", copiaEmail: ""
  });
  const [stateData, setStateData] = useState({}); // estado para la data que viene del servidor
  // SolConsumoDetailView.jsx ---> solConsump, segSolConsump, cards, division, gerencia, empPerm, empresa

  const { solConsump, segSolConsump, cards, division, gerencia, empPerm, empresa } = stateData && stateData;


  // GET the useNavigate
  const navigate = useNavigate();

  // funcion para limpiar el formulario
  const handleClearForm = () => {
    setForm((val) => ({ ...val, action: "", comentario: "", copiaEmail: "" }));
  };


  // funcion para recargar la data desde el hijo
  const onReloadDataFromSon = (newVal = false) => {
    setState((val) => ({ ...val, reload: newVal }));
  };

  // funcion para formatear los numeros
  const handleFormatCurrency = (newVal = "", type = 0) => {

    const val = newVal.replace(/[^\d.]/g, ''); // eliminar caracteres no numericos excepto el .

    const parts = val.split(".");
    // formatear la parte entera del numero con comas
    parts[0] = parts[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    if (type === 1) {
      setForm((val) => ({ ...val, monto: parts.join(".") }));
    } else {
      setForm((val) => ({ ...val, montFinal: parts.join(".") }));
    }

  };


  // funcion para abrir y cerrar el modal de docs
  const handleCloseOpenDocsModal = () => {
    setState((val) => ({ ...val, modalDocs: !state.modalDocs }));
  };


  // funcion para cambiar el valor cuando se envie la data al servidor
  const handleSendData = (newVal = false) => {
    setState((val) => ({ ...val, loadingData: newVal }));
  };

  // funcion para abrir y cerrar el modal de guardar
  const handleCloseOpenSave = () => {
    setState((val) => ({ ...val, modalSave: !state.modalSave }));
  };


  // funcion para manejar las diferentes acciones con el boton guardar
  const handlePerfomAction = (id = 0) => {
    let FINAL_MSG = "";
    let Act = form.action;
    if (id === 1) { // guardar

      if (form.idEtapa > 1 && !form.action) {
        return handleNotify(true, "Debe seleccionar la acción que quiere realizar.", true);
      }

      if (form.idEtapa === 2 && !form.comentario.trim() && form.action === "2") { // si la etapa es 2 y quieren devolverlo deben indicar un comentario
        return handleNotify(true, "Debe indicar por qué devuelve la solicitud.", true);
      }

      if (form.idEtapa > 1 && !form.comentario.trim() && form.action === "3") {
        return handleNotify(true, "Debe indicar el comentario que desea realizar.", true);
      }

      if (form.idEtapa === 3 && !form.comentario.trim() && form.action === "2") {
        return handleNotify(true, "Debe indicar las razones por las cuales no cargó la factura.", true);
      }

      FINAL_MSG = "Está seguro que desea guardar los cambios?";

      if (form.idEtapa === 1) {
        Act = "1";
      }

    }

    if (id === 2) { // copiar la solicitud
      FINAL_MSG = "Está seguro que dese copiar esta solicitud?";
      Act = "10";
    }

    if (id === 3) { // enviar al supervisor
      FINAL_MSG = "Está seguro que desea enviar a su supervisor para aprobación?";
      Act = "2";
    }

    if (id === 4) { // eliminar la solicitud
      FINAL_MSG = "Está seguro que desea eliminar esta solicitud?";
      Act = "5";
    }

    if ([1, 3].includes(id) && form.idEtapa === 2 && form.action === "4") {
      if (!patron.test(form.copiaEmail)) {
        return handleNotify(true, "El correo no es válido.", true);
      }
    }


    setState((val) => ({ ...val, modalSave: true, questionAction: FINAL_MSG }));

    setForm((val) => ({ ...val, action: Act }));
  };


  // funcion para abrir y cerrar el modal de confirmacion
  const handleOpenCloseModalConfir = () => {
    setState((val) => ({ ...val, modalConfirm: !state.modalConfirm }));
  };


  // funcion para cambiar los valores del formulario
  const handleChangeFormVal = (name = "", newVal = "") => {
    setForm((val) => ({ ...val, [name]: newVal }));
  };


  // funcion para solicitar la informacion al servidor 
  const fetchDataServer = async () => {
    const abortController = new AbortController();

    setController(abortController);

    try {
      setState((val) => ({ ...val, isLoading: true }));

      const response = await fetch(EndPoint, {
        method: Method,
        headers: {
          "Authorization": token,
          "Content-Type": "application/json"
        },
        signal: abortController.signal
      });

      onReloadDataFromSon();

      const data = await response.json();

      if (response.status === 200) {
        setStateData(data);
        // setDataServer(data);
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        setState((val) => ({ ...val, unauthorized: true, dataVisible: false }));
      }


      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };




  // useEffect para obtener la data del servidor
  useEffect(() => {

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [lang, CodSol, CompId]);


  // useEffect para recargar la data
  useEffect(() => {
    if (state.reload) {
      fetchDataServer();

      return () => {
        if (controller) {
          controller.abort();
        }
      };
    }
  }, [state.reload]);



  // useEffect para cargar los datos en el estado
  useEffect(() => {
    if (CompId === 1) {

      if (solConsump && solConsump.length > 0) {

        const idMoneda = {
          1: "RD$",
          2: "US$",
          3: "TT$"
        };

        const tipGast = {
          1: "Compra",
          2: "Hospedaje"
        };

        const FEC_REQUE = solConsump[0]?.IdEtapa === 1 ? solConsump[0]?.FecRequerida.slice(0, 10) : solConsump[0]?.FecRequerida

        const FecFinHospe = solConsump[0]?.FecFinHospedaje ? solConsump[0]?.FecFinHospedaje.slice(0, 10) : "";

        setForm((val) => ({
          ...val, solic: solConsump[0]?.CodSolicitud, nomEtapa: solConsump[0]?.NomEtapa, idEtapa: solConsump[0]?.IdEtapa, tarjeta: solConsump[0]?.NumTarjeta,
          titu: solConsump[0]?.NomTitular, nomMoneda: idMoneda[solConsump[0]?.Moneda] ?? "", idTarj: solConsump[0]?.IdTarjeta, consuFin: solConsump[0]?.ConsuFinal,
          numItiner: solConsump[0]?.NumItinerario, monto: solConsump[0]?.MontoConsumo, concept: solConsump[0]?.Concepto, fecReque: FEC_REQUE, gerencia: solConsump[0]?.NomGerencia,
          division: solConsump[0]?.NomGrupo, fecVen: solConsump[0]?.FecExp, documento: solConsump[0]?.Documento, idDivi: solConsump[0]?.CodDivisionEmpr.toString() ?? "",
          idGeren: solConsump[0]?.CodGerencia.toString() ?? "", empSoli: solConsump[0]?.CodEmpSolicita, moneda: solConsump[0]?.Moneda, lider: solConsump[0]?.CodLider,
          empresa: solConsump[0]?.CodEmpresa.toString() ?? "", tipGasto: solConsump[0]?.IdTipGasto.toString() ?? "", nomEmpresa: solConsump[0]?.NomEmpresa,
          nomTipGasto: tipGast[solConsump[0]?.IdTipGasto] ?? "", fecFinHospe: FecFinHospe, EmpAutoriza: solConsump[0]?.CodEmpAutoriza, montFinal: solConsump[0]?.MontoFinal ?? ""
        }));
      }
    }
  }, [solConsump, CompId]);

  // useEffect para abrir el modal
  useEffect(() => {
    if (CompId === 1) {
      if (empPerm && ([2, 3].includes(form.idEtapa) && form.EmpAutoriza !== null && (form.lider === empPerm[0]?.CodEmpleado || [6, 7].includes(empPerm[0]?.CodPerfil)))) {
        handleOpenCloseModalConfir();
      }
    }
  }, [empPerm, form.idEtapa, CompId]);




  return {
    state,
    form,
    solConsump,
    segSolConsump,
    cards,
    division,
    gerencia,
    empresa,
    empPerm,
    handleOpenCloseModalConfir,
    handleChangeFormVal,
    handleSendData,
    handleCloseOpenSave,
    handlePerfomAction,
    handleCloseOpenDocsModal,
    onReloadDataFromSon,
    handleFormatCurrency,
    handleClearForm
  };
};