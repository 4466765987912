import React, { useEffect, useState, useContext } from "react";

// COMPONENTS IMPORTATION
import { ModalFilterLogisticReports } from "../../components/logistic-reports/ModalFilterLogisticReports.jsx";
import { Error403View } from "../error-403-view/Error403View.jsx";
import { LoadingComp } from "../../components/Loading-comp/LoadingComp.jsx";
import { ButtonSearch } from "../../components/buttonsComponents/ButtonSearch.jsx";
import { ReporteGastoPiezas } from "./ReporteGastoPiezas.jsx";



// REACT ROUTER DOM IMPORTATION
import { Link, useNavigate, useSearchParams } from "react-router-dom";

// URLS IMPORTATIONS
import { urls } from "../../api-urls/api-urls.js";

// GLOBAL FUNCTIONS
import { formatCurrency, getTheToken, renderDate } from "../../global-functions/globalFunctions.js";

// React translation import
import { useTranslation } from "react-i18next";


// context imports
import { LangContext } from "../../context/LangContext.jsx";



export const LogisticReportsView = () => {

  // GET the language context
  const { lang } = useContext(LangContext);

  // GET the react translation
  const [t] = useTranslation("global");

  // GET the useNavigate
  const navigate = useNavigate();


  // GET the useSearchParams
  const [params] = useSearchParams();


  // STATE DEFINITION
  const [listReport, setListReport] = useState([]); // estado para cargar la lista de los reportes
  const [state, setState] = useState({ modalFilt: false, piezasParaExport: false, unauthorized: false, isLoading: false, dataVisible: false, moreData: false, total: 0, msg: "", titleReport: "", reporte: 0 }); // este state es para abrir el modal de las proyecciones de ordenes por llegar
  const [currentPag, setCurrentPag] = useState(1);

  const PAGE_SIZE = 100;



  // funcion para abrir el modal del filtro de los reportes
  const onOpenCloseFilter = () => {
    setState((val) => ({ ...val, modalFilt: !state.modalFilt }));
  };


  // funcion para actualizar el estado desde el hijo
  const setNewStateFromChild = (newList = [], page = 0, total = 0, msg = "", titleReport = "", moreD = false, report = 0) => {
    setListReport(newList);
    setState((val) => ({ ...val, total, msg, titleReport, moreData: moreD, reporte: report }));
    setCurrentPag(page);
  };


  // funcion para cargar el reporte del servidor
  const loadMoreData = async () => {

    const END_POINT = `${urls.logisticReports}?language=${lang}&page=${currentPag}&pageSize=${PAGE_SIZE}&company=${params.get("nom_empr") ?? ""}&zone=${params.get("nom_area") ?? ""}&report=${params.get("num_report") ?? ""}&division=${params.get("nom_divi") ?? ""}&nom_supp=${params.get("nom_suppl") ?? ""}&nom_cust=${params.get("nom_cli") ?? ""}&line=${params.get("nom_lin") ?? ""}&modality=${params.get("nom_moda") ?? ""}&tip_equp=${params.get("tip_equip") ?? ""}&model=${params.get("nom_model") ?? ""}&from=${params.get("from") ?? ""}&to=${params.get("to") ?? ""}&workflow=${params.get("workflow") ?? ""}&nro_part=${params.get("num_part") ?? ""}&nom_etap=${params.get("nom_etap") ?? ""}`;

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(END_POINT, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token
        }
      });

      const data = await response.json();

      if (response.status === 200) {
        setCurrentPag(currentPag + 1);
        if (data?.reporte.length < PAGE_SIZE) {
          setCurrentPag(1);
        }
        setState((val) => ({ ...val, moreData: data?.reporte.length < PAGE_SIZE, total: data?.Total, titleReport: data?.Title, reporte: data?.Reporte }));

        setListReport([...listReport].concat(data?.reporte));
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        setState((val) => ({ ...val, moreData: true }));
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };



  // useEffect para cargar los datos del reporte con los query params
  useEffect(() => {
    if (params.get("num_report")) {

      loadMoreData();

    }
  }, []);





  return (
    <div className="container display-data">
      {
        // Error 403
        state.unauthorized && (<Error403View />)
      }
      {/* Loading component */}
      {
        // loading component
        state.isLoading && (<LoadingComp />)
      }

      {/* {
        // modal para el filtro del modal de piezas para exportar
        state.piezasParaExport && (<ModalFilterPiezasExport onOpenClose={onOpenPiezasParaExport} division={division} line={line} modality={modality} tipEquipment={tipEquipment} equipmentModel={equipmentModel} zone={zone} suppliers={suppliers} company={company} />)
      } */}

      {
        listReport && listReport.length > 0 && (
          <>
            {
              state.titleReport !== "" && (<h4 className="m-y-small">{state.titleReport}</h4>)
            }

            <p className="m-y-small">
              <strong className="border-record">{listReport.length} de {state.total} registros</strong>
            </p>

            {// partes para exportar, partes para devolver y /o exportar
              [11, 15].includes(state.reporte) && (
                <table className="table fade-animation">
                  <thead className="table-head">
                    <tr>
                      <th>Solicitud</th>
                      <th>Tipo de orden</th>
                      <th>Orden</th>
                      <th>Fecha</th>
                      <th>Cant</th>
                      <th>Nro. Parte</th>
                      <th>Descripción</th>
                      <th>Workflow</th>
                      <th>SO</th>
                      <th>NTD</th>
                      <th>Cliente</th>
                      <th>Zona</th>
                      <th>Provincia</th>
                      <th>Equipo</th>
                      <th>Serial</th>
                      <th># Reporte</th>
                      <th>Responsable</th>
                      <th>Contacto Devolución</th>
                      {
                        params.get("num_report") === "11" && ( // partes para exportar
                          <th>Ubicación</th>
                        )
                      }
                      {
                        params.get("num_report") === "15" && ( // partes para devolver y/o exportar
                          <>
                            <th>Por devolver</th>
                            <th>Exportar</th>
                          </>
                        )
                      }
                      <th>Comentario</th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {
                      listReport && listReport.map((lstR) => (
                        <tr key={`${lstR.CodDetSolRepuesto}-${lstR.CodSolRepuesto}`}>
                          <td className="txt-center">
                            <Link className="link-tag" to={`/part-detail/${lstR.CodDetSolRepuesto}/${lstR.CodSolRepuesto}`}>{lstR.CodSolRepuesto}</Link>
                          </td>
                          <td>{lstR.NomTipAprob}</td>
                          <td>{lstR.NumOrden}</td>
                          <td>{renderDate(lstR.FecOrden, lang)}</td>
                          <td className="txt-center">{lstR.Cantidad}</td>
                          <td>{lstR.NumParte}</td>
                          <td>{lstR.Descripcion}</td>
                          <td className="txt-center">{lstR.WorkFlow}</td>
                          <td className="txt-center">{lstR.SO}</td>
                          <td className="txt-center" style={{ color: "var(--money-color)", fontWeight: "bold" }}>{formatCurrency(lstR.Precio, "USD")}</td>
                          <td>{lstR.Cliente}</td>
                          <td>{lstR.Zona}</td>
                          <td>{lstR.Estado}</td>
                          <td>{lstR.NomModelo}</td>
                          <td className="txt-center">{lstR.Serial}</td>
                          <td className="txt-center">{lstR.NumReporte}</td>
                          <td>{lstR.NomEmpleado}</td>
                          <td>
                            <p>
                              <strong>Nombre: </strong> <span>{lstR.NomContacto}</span>
                            </p>
                            <p>
                              <strong>Celular:</strong> <span>{lstR.Celular}</span>
                            </p>
                            <p>
                              <strong>Correo:</strong> <span>{lstR.Correo}</span>
                            </p>
                          </td>
                          {
                            params.get("num_report") === "11" && ( // partes para exportar
                              <td>{lstR.Ubicacion}</td>
                            )
                          }
                          {
                            params.get("num_report") === "15" && ( // partes para devolver y/o exportar
                              <>
                                <td className="txt-center">{lstR.PorDevolver}</td>
                                <td className="txt-center">{lstR.NomExportar}</td>
                              </>
                            )
                          }
                          <td>
                            <strong>{renderDate(lstR.FecIngreso, lang)} </strong>
                            {lstR.Accion}
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              )
            }

            {
              state.reporte === 10 && (
                <table className="table fade-animation">
                  <thead className="table-head">
                    <tr>
                      <th>Orden</th>
                      <th>Tipo</th>
                      <th>Llamada</th>
                      <th>Zona</th>
                      <th>Cliente</th>
                      <th>Línea</th>
                      <th>Equipo</th>
                      <th>Nro. Parte</th>
                      <th>Descripción</th>
                      <th>Cant.</th>
                      <th>Precio</th>
                      <th>Total</th>
                      <th>Etapa</th>
                      <th>Carrier</th>
                      <th>Guía</th>
                      <th>Fecha Despacho (EDD)</th>
                      <th>Fecha Llegada (LLA)</th>
                      <th>Comentario</th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {
                      listReport && listReport.map((lstPro) => (
                        <tr key={`${lstPro.CodSolRepuesto}-${lstPro.CodDetSolRepuesto}`}>
                          <td className="txt-center">{lstPro.NumOrden}</td>
                          <td>{lstPro.NomTipAprob}</td>
                          <td className="txt-center">{lstPro.CodServicio}</td>
                          <td>{lstPro.NomZona}</td>
                          <td>{lstPro.Cliente}</td>
                          <td>{lstPro.Modalidad}</td>
                          <td>{lstPro.Modelo}</td>
                          <td>{lstPro.NumParte}</td>
                          <td>{lstPro.Descripcion}</td>
                          <td className="txt-center">{lstPro.Cantidad}</td>
                          <td className="txt-center" style={{ color: "var(--money-color)", fontWeight: "bold" }}>{formatCurrency(lstPro.Precio, "USD")}</td>
                          <td className="txt-center" style={{ color: "var(--money-color)", fontWeight: "bold" }}>{formatCurrency(lstPro.Precio * lstPro.Cantidad, "USD")}</td>
                          <td>{lstPro.NomEtapa}</td>
                          <td>{lstPro.Carrier !== "1" && (lstPro.Carrier)}</td>
                          <td>{lstPro.GuiDespacho}</td>
                          <td>{renderDate(lstPro.FecDespacho, lang)}</td>
                          <td>{renderDate(lstPro.FecLlegada, lang)}</td>
                          <td>
                            {
                              lstPro.Accion !== "" && (
                                <>
                                  <strong>{renderDate(lstPro.FecIngComm, lang)} </strong>
                                  {lstPro.Accion}
                                </>
                              )
                            }
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              )
            }

            {
              state.reporte === 20 && (
                <ReporteGastoPiezas report={listReport} lang={lang} />
              )
            }

            <div className="m-y-small row-flex aling-c justify-center">
              {state.moreData ? <span className="required">{t("serviceCard.no_mas_datos")}</span> : <button type="button" className="btn-primary" onClick={loadMoreData}>{t("serviceCard.ver_mas")}</button>}
            </div>
          </>
        )
      }

      {
        // MODAL PARA EL FILTRO DE PROYECCION DE ORDENES POR LLEGAR
        state.modalFilt && (<ModalFilterLogisticReports onClose={onOpenCloseFilter} onSetNewState={setNewStateFromChild} token={getTheToken().token} />)
      }

      <ButtonSearch onOpenFilter={onOpenCloseFilter} />
    </div>
  );
};