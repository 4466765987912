/*
--- Este componente es para agregar las partes a una solicitud, esto
--- funciona como un modal
*/

import React, { useEffect, useState, useContext } from "react";

// COMPONENTS IMPORTATION
import { ToastNotification } from "../toast-notification/ToastNotification";

// URLS IMPORTATIONS
import { urls } from "../../api-urls/api-urls.js";

// REACT ROUTER DOM IMPORTATION
import { useLocation, useNavigate } from "react-router-dom";

// GLOBAL FUNCTIONS
import { getTheToken } from "../../global-functions/globalFunctions.js";

// React global translation
import { useTranslation } from "react-i18next";


// context importation
import { LangContext } from "../../context/LangContext.jsx";
import { NotificationContext } from "../../context/NotificationContext.jsx";






export const AddPartComp = ({ solRepuesto, onClose, employeePerm, onUpdate }) => {


  // GET the context language
  const { lang } = useContext(LangContext);

  // GET the context notifify
  const { handleNotify } = useContext(NotificationContext);

  // GET the location
  const location = useLocation();

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the react translation
  const [t] = useTranslation("global");

  // state definition
  const [state, setState] = useState({ sending: false });
  const [stateInputs, setStateInputs] = useState({
    cantidad: "", parte: "", parteAnterior: "", descripcionEn: "", descripcionEs: "", preLista: 0, factor: 0,
    itbis: 0, multipli: 0, descuento: 0, preOrden: 0, stock: false, disponible: "", workFlow: "", infoDispo: "", queEs: "",
    funcion: "", caractFisicas: "", caractTecnicas: ""
  });


  // PERFILES
  const OPER_DEPART = 3; // perfil de operaciones
  const ADMIN_SYS = 6; // Perfil administrador del sistema
  const DIRECTOR = 7; // perfil de director
  const EMPLEADO_VIA = 0; // viatico de empleado
  const SUPER_VIA = 1; // Viatico de supervisor
  const GEREN_VIA = 2; // Viatico de gerente
  const DIRECTOR_VIA = 3; // Viatico de director

  const PERFIL_ALLOWED = [OPER_DEPART, DIRECTOR, ADMIN_SYS];
  const VIAT_ALLOWED = [EMPLEADO_VIA, SUPER_VIA, GEREN_VIA, DIRECTOR_VIA];


  // Expresion regular para evaluar si es un numero entero
  const evalInt = /^\d+$/;


  // ACTUALIZAR LA INFORMACION EN LA BASE DE DATOS
  const onAddNewPart = async (event) => {
    event.preventDefault();

    // Verificar cuales son los datos que se enviaran al servidor y validar su tipo
    if (!stateInputs.cantidad) { // la cantidad no puede estar vacia y tiene que ser un tipo entero y tiene que ser mayor a 0
      return handleNotify(true, t("PartModify.indicar_cant"), true);
    } else if (!evalInt.test(stateInputs.cantidad)) {
      return handleNotify(true, t("PartModify.cant_num"), true);
    } else if (Number(stateInputs.cantidad) <= 0) {
      return handleNotify(true, t("PartModify.cant_num_mayor_cero"), true);
    } else if (!stateInputs.parte.trim()) {
      return handleNotify(true, t("PartModify.parte_no_vacia"), true);
    } else if (stateInputs.descripcionEn.trim().length <= 4) {
      return handleNotify(true, t("PartModify.descrip_no_vacia"), true);
    } else if (stateInputs.queEs.trim().length <= 5) {
      return handleNotify(true, t("PartModify.que_es_error"), true);
    }

    try {
      setState((val) => ({ ...val, sending: true }));
      const response = await fetch(`${urls.partDetail}/${solRepuesto}/search?language=${lang}&action=1`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(stateInputs)
      });

      const data = await response.json();

      if (response.status === 201) {
        handleNotify(true, data?.msg, false, true);
        setStateInputs((val) => ({
          ...val, cantidad: "", parte: "", parteAnterior: "", descripcionEn: "", descripcionEs: "", preLista: 0, factor: 0,
          itbis: 0, multipli: 0, descuento: 0, preOrden: 0, stock: false, disponible: "", workFlow: "", infoDispo: "", queEs: "",
          funcion: "", caractFisicas: "", caractTecnicas: ""
        }));
        onUpdate(true);
      } else if (response.status === 404) {
        handleNotify(true, data?.msg, true);
      }

      setState((val) => ({ ...val, sending: false }));

    } catch (error) {
      console.log(error)
    }

  };


  // funcion para buscar la parte
  const onBlurSearchPart = async () => {
    const END_POINT = `${urls.partDetail}/${solRepuesto}/search?language=${lang}&action=2&numParte=${stateInputs.parte}`;

    try {
      const response = await fetch(END_POINT, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        }
      });

      const data = await response.json();

      if (response.status === 200) {
        const { parte } = data && data;

        setStateInputs((val) => ({
          ...val, parteAnterior: parte[0]?.Alternativo, descripcionEn: parte[0]?.Descripcion, descripcionEs: parte[0]?.DesCotizacion, preLista: parte[0]?.Lista, factor: parte[0]?.Factor, itbis: parte[0]?.Itbis,
          multipli: parte[0]?.Multiplicador, descuento: parte[0]?.Descuento, preOrden: parte[0]?.Precio, stock: parte[0]?.Stock ? "1" : "0", disponible: parte[0]?.Disponible ? "1" : "0", queEs: parte[0]?.QueEs,
          funcion: parte[0]?.Funcion, caractFisicas: parte[0]?.CarFisica, caractTecnicas: parte[0]?.CarTecnica
        }));
      }

    } catch (error) {
      console.log(error);
    }
  };




  return (
    <div className="background-modal">
      <form className="container-select-customer" onSubmit={onAddNewPart}>
        {/* FILA 1 */}
        <div className="modal-header">
          <h4 style={{ color: 'var(--first-color)' }}>Agregar partes a la solicitud {solRepuesto}</h4>
          <button type="button" className="btn-close" onClick={onClose}>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
            </svg>
          </button>
        </div>
        <div className="modal-body">
          <div className="row-flex">
            {/* CANTIDAD */}
            <div className="col-7 col-sm-8">
              <strong>{t("PartModify.cantidad")}</strong>
              <strong className="required">(*)</strong>
            </div>
            <div className="flex-auto col-70-sm">
              <input
                className="input-text-control"
                type="text"
                name="cantidad"
                value={stateInputs.cantidad}
                onChange={({ target }) => setStateInputs((val) => ({ ...val, cantidad: target.value }))} />
            </div>
          </div>

          {/* FILA 2 */}
          <div className="row-flex">
            {/* PARTE */}
            <div className="col-7 col-sm-8">
              <strong>{t("PartModify.parte")}</strong>
              <strong className="required">(*)</strong>
            </div>
            <div className="col-3 col-70-sm">
              <input
                className="input-text-control"
                type="text"
                name="parte"
                value={stateInputs.parte}
                onChange={({ target }) => setStateInputs((val) => ({ ...val, parte: target.value }))}
                onBlur={onBlurSearchPart} />
            </div>
            {/* NUMERO DE PARTE ANTERIOR */}
            <div className="col-7 col-sm-8">
              <strong>{t("PartModify.nro_part_anter")}</strong>
            </div>
            <div className="flex-auto col-70-sm">
              <input
                className="input-text-control"
                type="text"
                name="parteAnterior"
                value={stateInputs.parteAnterior}
                onChange={({ target }) => setStateInputs((val) => ({ ...val, parteAnterior: target.value }))} />
            </div>
          </div>

          {/* FILA 3 */}
          <div className="row-flex">
            {/* DESCRIPCION EN INGLES */}
            <div className="col-7 col-sm-8">
              <strong>{t("PartModify.desc_ingles")}</strong>
              <strong className="required">(*)</strong>
            </div>
            <div className="flex-auto col-70-sm">
              <textarea
                className="textarea-description"
                name="descripIngles"
                value={stateInputs.descripcionEn}
                onChange={({ target }) => setStateInputs((val) => ({ ...val, descripcionEn: target.value }))} />
            </div>
          </div>

          {/* Solo pueden ver operaciones y directores desde acá */}
          {/* FILA 5 */}
          {
            (PERFIL_ALLOWED.includes(employeePerm[0]?.CodPerfil) && VIAT_ALLOWED.includes(employeePerm[0]?.NivViatico)) && (
              <>
                {/* FILA 4 */}
                <div className="row-flex">
                  {/* DESCRIPCION EN ESPANOL */}
                  <div className="col-7 col-sm-8">
                    <strong>{t("PartModify.desc_espanol")}</strong>
                  </div>
                  <div className="flex-auto col-70-sm">
                    <textarea
                      className="textarea-description"
                      name="descripEspanol"
                      value={stateInputs.descripcionEs}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, descripcionEs: target.value }))} />
                  </div>
                </div>

                <div className="row-flex">
                  {/* PRECIO DE LISTA */}
                  <div className="col-7 col-sm-8">
                    <strong>{t("PartModify.precio_ntd")}</strong>
                  </div>
                  <div className="col-3 col-70-sm">
                    <input
                      className="input-text-control"
                      type="text"
                      name="preList"
                      value={stateInputs.preLista}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, preLista: target.value }))} />
                  </div>
                  {/* FACTOR */}
                  <div className="col-8 col-sm-8">
                    <strong>Factor</strong>
                  </div>
                  <div className="col-8 col-sm-8">
                    <input
                      className="input-text-control"
                      type="text"
                      name="factor"
                      value={stateInputs.factor}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, factor: target.value }))} />
                  </div>
                  {/* ITBIS */}
                  <div className="col-8 col-sm-8">
                    <strong>ITBIS</strong>
                  </div>
                  <div className="col-8 flex-grow col-sm-8">
                    <input
                      className="input-text-control"
                      type="text"
                      name="itbis"
                      value={stateInputs.itbis}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, itbis: target.value }))} />
                  </div>
                </div>

                {/* FILA 6 */}
                <div className="row-flex">
                  {/* MULTIPLICADOR */}
                  <div className="col-7 col-sm-8">
                    <strong>{t("PartModify.multiplic")}</strong>
                  </div>
                  <div className="col-3 col-sm-8">
                    <input
                      className="input-text-control"
                      type="text"
                      name="multipli"
                      value={stateInputs.multipli}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, multipli: target.value }))} />
                  </div>
                  {/* DESCUENTO */}
                  <div className="col-8 col-sm-8">
                    <strong>{t("PartModify.desc")}</strong>
                  </div>
                  <div className="flex-auto col-sm-8">
                    <input
                      className="input-text-control"
                      type="text"
                      name="discount"
                      value={stateInputs.descuento}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, descuento: target.value }))} />
                  </div>
                </div>

                {/* FILA 7 */}
                <div className="row-flex">
                  {/* PRECIO DE COMPRA */}
                  <div className="col-7 col-sm-8">
                    <strong>{t("PartModify.precio_orden")}</strong>
                  </div>
                  <div className="col-3 col-sm-8">
                    <input
                      className="input-text-control"
                      type="text"
                      name="preOrd"
                      value={stateInputs.preOrden}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, preOrden: target.value }))} />
                  </div>
                  {/* EN STOCK */}
                  <div className="col-8 col-sm-8">
                    <strong>Stock</strong>
                  </div>
                  <div className="col-8 col-sm-8">
                    <select
                      name="stockAction"
                      value={stateInputs.stock}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, stock: target.value }))}>
                      <option value="0">{t("PartModify.si")}</option>
                      <option value="1">No</option>
                    </select>
                  </div>
                  {/* DISPONIBLE */}
                  <div className="col-8 col-sm-8">
                    <strong>{t("PartModify.disponible")}</strong>
                  </div>
                  <div className="col-8 flex-grow col-sm-8">
                    <select
                      name="disponibleAction"
                      value={stateInputs.disponible}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, disponible: target.value }))}>
                      <option value="0">{t("PartModify.si")}</option>
                      <option value="1">No</option>
                    </select>
                  </div>
                </div>

                {/* FILA 8 */}
                <div className="row-flex">
                  {/* WORKFLOW */}
                  <div className="col-7 ">
                    <strong>WorkFlow</strong>
                  </div>
                  <div className="flex-auto">
                    <input
                      className="input-text-control"
                      type="text"
                      name="workFlow"
                      value={stateInputs.workFlow}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, workFlow: target.value }))} />
                  </div>
                </div>

                {/* FILA 9 */}
                <div className="row-flex">
                  {/* INFORMACION DISPONIBILIDAD */}
                  <div className="col-7">
                    <strong>{t("PartModify.infor_disponi")}</strong>
                  </div>
                  <div className="flex-auto">
                    <input
                      className="input-text-control"
                      type="text"
                      name="availability"
                      value={stateInputs.infoDispo}
                      onChange={({ target }) => setStateInputs((val) => ({ ...val, infoDispo: target.value }))}
                      placeholder={lang === "en" ? "Write the availability information of the supplier." : "Coloque aquí la información de disponibilidad en el suplidor."} />
                  </div>
                </div>
              </>
            )
          }

          {/* Hasta acá */}

          {/* FILA 10 */}
          <div className="row-flex">
            {/* Que es? */}
            <div className="col-7">
              <strong>{t("PartModify.que_es")}</strong>
              <strong className="required">(*)</strong>
            </div>
            <div className="flex-auto">
              <input
                className="input-text-control"
                type="text"
                name="queEs"
                value={stateInputs.queEs}
                onChange={({ target }) => setStateInputs((val) => ({ ...val, queEs: target.value }))}
                placeholder={t("PartModify.que_es_hold")} />
            </div>
          </div>

          {/* FILA 11 */}
          <div className="row-flex">
            {/* SU FUNCION, QUE HACE */}
            <div className="col-7">
              <strong>{t("PartModify.func")}</strong>
            </div>
            <div className="flex-auto">
              <input
                className="input-text-control"
                type="text"
                name="function"
                value={stateInputs.funcion}
                onChange={({ target }) => setStateInputs((val) => ({ ...val, funcion: target.value }))}
                placeholder={lang === "en" ? "Write the functionality here, this is very helpful for customs." : "Coloque cuál es la funcionalidad de esta parte, esto ayuda para la nacionalización..."} />
            </div>
          </div>

          {/* FILA 12 */}
          <div className="row-flex">
            {/* CARACTERISTICAS FISICAS */}
            <div className="flex-auto">
              <strong>{t("PartModify.carac_fisi")}</strong>
            </div>
          </div>

          {/* FILA 13 */}
          <div className="row-flex">
            {/* INPUT */}
            <div className="flex-auto">
              <input
                className="input-text-control"
                type="text"
                name="caracFisi"
                value={stateInputs.caractFisicas}
                onChange={({ target }) => setStateInputs((val) => ({ ...val, caractFisicas: target.value }))} />
            </div>
          </div>

          {/* FILA 14 */}
          <div className="row-flex">
            {/* CARACTERISTICAS TECNICAS */}
            <div className="flex-auto">
              <strong>{t("PartModify.carac_tec")}</strong>
            </div>
          </div>

          {/* FILA 15 */}
          <div className="row-flex">
            {/* INPUT */}
            <div className="flex-auto">
              <input
                className="input-text-control"
                type="text"
                name="caracTec"
                value={stateInputs.caractTecnicas}
                onChange={({ target }) => setStateInputs((val) => ({ ...val, caractTecnicas: target.value }))} />
            </div>
          </div>

        </div>

        <div className="modal-footer j-content-space-around">
          <button type={state.sending ? "button" : "submit"} className={`btn-primary ${state.sending ? "disabled" : ""}`}>
            {state.sending ? t("serviceCard.guardando") : t("PartModify.guardar")}
          </button>
          <button type="reset" className="btn-secondary" onClick={onClose}>{t("PartModify.cerrar")}</button>
        </div>

      </form>
    </div>
  );
};