// custom hook para solicitar la data de la solicitud de partes

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


export const useFetchReqParts = (CodServ = "", lang = "", token = "", urls = {}) => {

  // state definition
  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor
  const [state, setState] = useState({ isLoading: false, serverError: false, unathorized: false, dataVisible: false, sending: false });
  const [form, setForm] = useState({ tipSol: "", observ: "", priority: "" });
  const [solState, setSolState] = useState({});


  // GET the useNavigate
  const navigate = useNavigate();


  // funcion para modificar los valores del formulario
  const handleChangeFormVal = (name = "", newVal = "") => {
    setForm((val) => ({ ...val, [name]: newVal }));
  };

  // funcion para modificar el enviar al servidor
  const handleSendServ = (newVal = false) => {
    setState((val) => ({ ...val, sending: newVal }));
  };

  // funcion para cambiar la temperatura
  const handleChangeTemp = (newVal = "") => {
    setForm((val) => ({ ...val, priority: newVal }));
  };


  // Funcion para traer la data del servidor
  const fetchDataServer = async () => {
    const abortCont = new AbortController();

    setController(abortCont);

    try {
      setState((val) => ({ ...val, isLoading: true }));

      const response = await fetch(`${urls.requestDetail}/${CodServ}/search?initData=yes&language=${lang}`, {
        method: "POST",
        headers: {
          "Authorization": token,
          "Content-Type": "application/json"
        },
        signal: abortCont.signal
      });

      const { servDetail } = await response.json();

      if (response.status === 200) {
        setSolState(servDetail[0]);
        setForm((val) => ({ ...val, priority: servDetail[0]?.Prioridad.toString() ?? "", tipSol: servDetail[0]?.TipSol }));
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 401) {
        // localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 400) {
        // bad request
      } else if (response.status === 403) {
        // no autorizado
        setState((val) => ({ ...val, unathorized: true, dataVisible: false }));
      } else if (response.status === 500) {
        // error del servidor
        setState((val) => ({ ...val, serverError: true }));
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (err) {
      console.log(err);
    }
  };


  // useEffect para traer la data del servidor
  useEffect(() => {

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [CodServ, lang]);




  return {
    state,
    form,
    solState,
    handleChangeFormVal,
    handleSendServ,
    handleChangeTemp
  };
};