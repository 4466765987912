// componente para cargar archivos al servidor

import { useState, useEffect } from "react";

import { ToastNotification } from "../../components/toast-notification/ToastNotification.jsx";



export const UploadFiles = ({ onClose, END_POINT = "", Method = "", token = "", FileAccept = "" }) => {

  // state definition
  const [selectedFile, setSelectedFile] = useState(null);
  const [notify, setNotify] = useState({ show: false, msg: "", error: false, great: false });


  // Funcion para cargar el archivo al estado 
  const handleFileChange = ({ target }) => {
    const file = target.files[0];
    setSelectedFile(file);
  };


  // Manejo del envio de documentos al servidor
  const onUploadDocument = async (event) => {
    event.preventDefault();

    if (selectedFile) {

      const formData = new FormData();

      formData.append('file', selectedFile);

      const response = await fetch(END_POINT, {
        method: Method,
        headers: {
          "Authorization": token,
          // "Content-Type": "multipart/form-data"
        },
        body: formData
      });

      const data = await response.json();

      if (response.status === 200) {
        // onClose();
        setNotify((val) => ({ ...val, show: true, msg: data?.msg, great: true }));
        setSelectedFile(null);
      } else if ([400, 404, 403].includes(response.status)) {
        setNotify((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
      } else if (response.status === 413) {
        // el archivo es muy pesado no se puede procesar
        setNotify((val) => ({ ...val, show: true, msg: "El archivo que intenta subir excede los 10 mb", error: true }));
      }

    } else { // no hay nada para enviar
      setNotify((val) => ({ ...val, show: true, msg: "No hay archivo seleccionado.", error: true }));
    }
  };


  // useEffect para limpiar la notificacion del estado
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotify((val) => ({ ...val, show: false, msg: "", error: false, great: false }));
    }, 5000);

    return () => {
      clearTimeout(timer);
    };

  }, [notify.show]);


  return (
    <div className="background-modal" onClick={onClose}>
      {
        notify.show && (<ToastNotification success={notify.great} error={notify.error} msg={notify.msg} />)
      }
      <div className="container-modal-upload-docs" onClick={(event) => event.stopPropagation()}>
        <form onSubmit={onUploadDocument} encType="multipart/form-data">
          <div className="row-flex">
            <div className="col-6"><strong>Documento a subir</strong></div>
            <div className="col-6 text-hidden-over">
              <input
                type="file"
                name="file"
                accept={FileAccept}
                onChange={handleFileChange} />
            </div>
          </div>

          <div className="row-flex j-content-space-around m-y-small">
            <button type="submit" className="btn-primary">Aceptar</button>
            <button type="reset" className="btn-secondary" onClick={onClose}>Cancelar</button>
          </div>
        </form>
      </div>
    </div>
  );
};