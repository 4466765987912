import React, { useEffect, useState, useContext } from "react";

// ----> Imports for React Router
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";

// ----> Import translation Hook
import { useTranslation } from "react-i18next";


// ----> Imports for styles
import "../../views/service/ServiceView.css";

// ----> COMPONENTS IMPORTATIONS
import { LoadingComp } from "../../components/Loading-comp/LoadingComp.jsx";
import { Error500Comp } from "../../components/errors/Error500Comp.jsx";
import { Error403View } from "../error-403-view/Error403View.jsx";
import { ButtonSearch } from "../../components/buttonsComponents/ButtonSearch.jsx";
import { ServiceFilter } from "../../components/serviceComponents/ServiceFilter.jsx";


// context importation
import { LangContext } from "../../context/LangContext.jsx";
import { NotificationContext } from "../../context/NotificationContext.jsx";



import { urls } from "../../api-urls/api-urls.js";


// ----> GLOBAL FUNCTIONS
import { getTheToken, renderDate, renderServiceType } from "../../global-functions/globalFunctions.js";

// CUSTOM HOOK IMPORTATION
import useCustomServiceComp from "../../customs-hooks/service-view/useCustomServiceComp.js";





export const ServiceView = ({ grupo }) => {

  // lang context
  const { lang } = useContext(LangContext);

  // notification context
  const { handleNotify } = useContext(NotificationContext);


  // get the useNavigate
  const navigate = useNavigate();

  // useNavigate
  const location = useLocation();

  // CUSTOM HOOK IMPORTATION
  const { openFilter, onOpenFilter, serverError, setServerError, unauthorized, setUnAuthorized, dataVisible, setDataVisible, setOpenFilter } = useCustomServiceComp();


  // useSearchParams
  const [params] = useSearchParams();

  // STATE DEFINITION
  const [isLoading, setIsLoading] = useState(false);
  const [moreData, setMoreData] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [controller, setController] = useState(null); // Estado para setear el controlador en dado caso de que se aborte la request al server
  const [pageSize] = useState(20);
  const [callList, setCallList] = useState([]);
  const [total, setTotal] = useState(0);


  // useRef

  // const loadingRef = useRef(null);

  // DESTRUCTURING DATA SERVER
  // const { serviceCalls } = dataServer && dataServer;


  // console.log(filterInputs)


  // ----> Destructuring useTranslation()
  const [t] = useTranslation("global");

  // const isLastPage = callList.length < pageSize; // ultima pagina

  // useEffect para setear las paginacion
  useEffect(() => {
    setCurrentPage(1);
    setCallList([]);

  }, [grupo]);




  // funcion para actualizar el estado de las llamadas
  const onUpdateState = (lstCalls = [], currentPa = 0, total = 0, moreD = false) => {
    setCallList(lstCalls);
    setCurrentPage(currentPa);
    setTotal(total);
    setMoreData(moreD);
  };


  // funcion para traer la data del servidor
  // FETCH DATA SERVER
  const fetchDataServer = async () => {
    const abortController = new AbortController();

    setController(abortController);


    try {
      setIsLoading(true);
      const response = await fetch(`${urls.calls}?language=${lang}&empresa=${params.get("empr") ?? ""}&zona=${params.get("area") ?? ""}&cliente=${params.get("nom_cus") ?? ""}&responsable=${params.get("respon") ?? ""}&division=${params.get("nom_div") ?? ""}&linea=${params.get("nom_line") ?? ""}&modalidad=${params.get("nom_mod") ?? ""}&reportes=${params.get("num_rep") ?? ""}&tipServ=${params.get("tip_serv") ?? ""}&etapa=${params.get("nom_step") ?? ""}&serviceId=${params.get("id_serv") ?? ""}&page=${currentPage}&pageSize=${pageSize}&grupo=${params.get("grp") ?? ""}&filt=${params.get("filt") ?? "0"}&sort=${params.get("sort_by") ?? ""}&order=${params.get("order_by") ?? ""}&action=`, {
        headers: { "Authorization": getTheToken().token },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        setUnAuthorized(true);
        setDataVisible(false);
      } else if (response.status === 404) {
        setDataVisible(true);
        handleNotify(true, data?.msg, true);
      } else if (response.status === 500) {
        // ocurrio un error en el servidor
        setServerError(true);
      } else if (response.status === 200) {
        setCurrentPage(currentPage + 1);
        if (data?.serviceCalls.length < pageSize) {
          setCurrentPage(1);
        }
        setTotal(data?.total);
        setCallList([...callList].concat(data?.serviceCalls));
        setMoreData(data?.serviceCalls.length < pageSize);
        setDataVisible(true);
      }

      setIsLoading(false);

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para llamar la funcion al servidor
  useEffect(() => {
    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [lang, grupo]);


  // useEffect para setear el more data
  useEffect(() => {
    if (callList && callList.length === total) {
      setMoreData(true);
    }
  }, [callList])




  return (
    <>
      {isLoading && (<LoadingComp />)}
      {serverError && (<Error500Comp />)}
      {unauthorized && (<Error403View />)}
      {/* OPEN FILTER */}
      {
        dataVisible && (
          <>
            {/* <TopNavbarMobile onNewEvent={onOpenFilter} /> */}
            {
              openFilter && (
                <ServiceFilter onClose={onOpenFilter} lang={lang} setNewState={onUpdateState} />
              )
            }

            <div className="display-data container">

              {/* BOTON PARA ABRIR EL FILTRO DE BUSQUEDA */}
              {
                params.get("filt_vksh") === null && (
                  <ButtonSearch onOpenFilter={onOpenFilter} />
                )
              }
              <div>
                <>
                  {
                    callList && callList.length > 0 ? (
                      <>
                        <strong>{callList.length} {lang === "en" ? "Services of " : "Servicios de "}{total}</strong>
                        <div className="table-container-mobile">
                          <table className="table">
                            <thead>
                              <tr className="table-head">
                                <th>ID</th>
                                <th>T</th>
                                <th className="col-10">{t("serviceCard.date")}</th>
                                <th className="col-7">{t("serviceCard.equipment")}</th>
                                <th className="col-7">{t("serviceCard.customer")}</th>
                                <th className="col-7">{t("serviceCard.problem")}</th>
                                <th className="col-9">{t("serviceCard.responsible")}</th>
                                <th>{t("serviceCard.step")}</th>
                                <th>{t("serviceCard.serviceType")}</th>
                                <th>Acción</th>
                                <th>{t("serviceCard.comment")}</th>
                              </tr>
                            </thead>
                            <tbody className="table-body">
                              {
                                callList && callList.map(call => (
                                  <tr key={call.CodServicio}>
                                    <td className="sticky-cell">
                                      {
                                        grupo === 1 ? <Link className="link-tag" to={`/service/service-call/${call.CodServicio}`}>{call.CodServicio}</Link>
                                          : <Link className="link-tag" to={`/commercial/service/service-call/${call.CodServicio}`}>{call.CodServicio}</Link>
                                      }
                                      <span className=""></span>
                                    </td>
                                    <td className="txt-center">{call.TipContrato}</td>
                                    <td style={{ textAlign: 'center' }}>{renderDate(call.FecIngreso, lang)}</td>
                                    <td>
                                      <Link className="link-tag" to={`/equipments/equipment-detail/${call.CodEquipo}`}>
                                        {call.NomTipEquipo}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link className="link-tag" to={`/customers/customer-detail/${call.CodCliente}`}>
                                        {call.NomCliente}
                                      </Link>
                                    </td>
                                    <td style={{ textTransform: 'capitalize' }}>{call.Problema ? call.Problema.toLowerCase() : ''}</td>
                                    <td>{call.NomEmpleado}</td>
                                    <td>{call.NomEtapa}</td>
                                    <td>{renderServiceType(call.IdTipServicio, lang)}</td>
                                    <td>{renderDate(call.FecAccion, lang)}</td>
                                    <td style={{ textTransform: 'capitalize' }}>{call.Accion ? call.Accion.toLowerCase() : ''}</td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </table>
                        </div>
                      </>
                    ) : (<div><strong style={{ fontSize: '0.8rem' }}>{lang === "en" ? "No results for what you are looking for" : "No hay resultados para lo que estás buscando"} &#128064;</strong></div>)
                  }
                </>
              </div>

              {/* Botones de adelante y atras */}
              {
                callList && callList.length > 0 && (
                  <div className="row-flex align-c justify-center m-y-small">
                    {
                      moreData ? <span className="required">{t("serviceCard.no_mas_datos")}</span> : <button className="btn-primary" onClick={fetchDataServer}>{t("serviceCard.ver_mas")}</button>
                    }
                  </div>
                )
              }
            </div>
          </>
        )
      }
    </>
  );
};