import React, { useEffect, useState, useContext } from "react";


// components importation
import { Error404Comp } from "../../components/errors/Error404Comp";
import { Error403View } from "../error-403-view/Error403View";
import { ToastNotification } from "../../components/toast-notification/ToastNotification";
import { LoadingComp } from "../../components/Loading-comp/LoadingComp.jsx";
import { ModalConfirm } from "../../components/modal-confirm/ModalConfirm";



// React router dom importation
import { Link, useNavigate, useParams } from "react-router-dom";

// Url importation
import { urls } from "../../api-urls/api-urls";
import { formatCurrency, getTheToken, renderDate } from "../../global-functions/globalFunctions";
import { useTranslation } from "react-i18next";
import { useFetchAdminisAppro } from "../../components/customs/warehouse/useFetchAdminisAppro";


// lang context
import { LangContext } from "../../context/LangContext.jsx";



export const AdministrativeApprovalsDetail = () => {

  // GET the translation of react
  const [t] = useTranslation("global");

  const { lang } = useContext(LangContext);


  // GET the params
  const { codSolRep } = useParams();


  // GET the useNavigate
  const navigate = useNavigate();

  // useFetch
  const { form,
    state,
    partes,
    solState,
    notify,
    handleChangeForm,
    handleCloseOpenModal,
    handleComment,
    handleSaveInfo,
    handleReturnRequest,
    handleChangeValParts,
    handleNotify,
    handleClearNotify,
    handleClearForm,
    handleSendServer } = useFetchAdminisAppro(codSolRep, urls, getTheToken().token, lang);


  // Menejador del submit para enviar al backend
  const onHandleSubmit = async (event) => {
    event.preventDefault();

    const finalObj = { form, partes };

    console.log("Se sometio el")

    try {
      // Enviar la solicitud al backend
      handleSendServer(true);
      const response = await fetch(`${urls.administrativeAppro}/${codSolRep}/search?language=${lang}`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(finalObj)
      });

      const data = await response.json();

      if (response.status === 200) {
        handleCloseOpenModal();
        handleNotify(data?.msg, false, true, true);
        handleClearForm();
      }

      handleSendServer(false);

      console.log("Se sometió el formulario");

    } catch (error) {
      console.log(error);
    }
  };



  // useEffect para limpiar las notificaciones del estado
  useEffect(() => {
    const timer = setTimeout(() => {
      handleClearNotify();
    }, 6500);

    return () => {
      clearTimeout(timer);
    }
  }, [notify.show]);


  // useEffect para redireccionar
  useEffect(() => {
    if (notify.succes) {
      const timer = setTimeout(() => {
        navigate(-1, { replace: true });
      }, 5000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [notify.succes]);




  return (
    <>
      {
        state.isLoading && (<LoadingComp />)
      }
      {/* Unauthorized comp */}
      {state.unauthorized && (<Error403View />)}
      {/* Component notification */}
      {notify.show && (<ToastNotification success={notify.great} error={notify.error} msg={notify.msg} />)}
      {state.error404 && (<Error404Comp msgPrin={t("RequestPartsView.error_404")} navigateTo={"/warehouse/administrative-approval"} />)}
      {
        state.dataVisible && (
          <div className="container display-data">

            <form onSubmit={onHandleSubmit}>
              {/* FILA */}
              <div className="row-flex">
                {/* Solicitud */}
                <div className="col-7">
                  <strong>{t("RequestPartsView.soli")}</strong>
                </div>
                <div className="col-7">
                  <h4>{codSolRep}</h4>
                </div>
                {/* Id Servicio */}
                <div className="col-8">
                  <strong>{t("RequestPartsView.id_serv")}</strong>
                </div>
                <div className="col-8">
                  <Link className="link-tag" to={`/service/service-call/${solState?.CodServicio}`}>{solState?.CodServicio}</Link>
                </div>
                {/* Modelo */}
                <div className="col-10">
                  <strong>{t("RequestPartsView.modelo")}</strong>
                </div>
                <div className="col-7"><span>{solState?.Modelo}</span></div>
                {/* Serial */}
                <div className="col-10">
                  <strong>Serial</strong>
                </div>
                <div className="col-7">
                  <span>{solState?.Serial}</span>
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Fecha */}
                <div className="col-7">
                  <strong>{t("RequestPartsView.fecha")}</strong>
                </div>
                <div className="col-39-29">
                  <span>{renderDate(solState?.FecIngSol, lang)}</span>
                </div>
                {/* Suplidor */}
                <div className="col-10">
                  <strong>{t("RequestPartsView.supli")}</strong>
                </div>
                <div className="col-7">
                  <span>{solState?.Suplidor}</span>
                </div>
                {/* Garantia */}
                <div className="col-10">
                  <strong>Garantía</strong>
                </div>
                <div className="col-7">
                  <span style={{ color: (solState?.FecGarantia && solState?.FecGarantia < solState?.FecIngSol) && "#ff0000" }}>{renderDate(solState?.FecGarantia, lang)}</span>
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Cliente */}
                <div className="col-7">
                  <strong>{t("RequestPartsView.cliente")}</strong>
                </div>
                <div className="col-39-29 text-hidden-over">
                  <Link className="link-tag" to={`/customers/customer-detail/${solState?.CodCliente}`}>{solState?.NomCliente}</Link>
                </div>
                {/* FDO */}
                <div className="col-10">
                  <strong>FDO</strong>
                </div>
                <div className="col-7">
                  <span>{solState?.FDO}</span>
                </div>
                {/* O/C */}
                <div className="col-10">
                  <strong>O/C</strong>
                </div>
                <div className="col-7">
                  <span>{solState?.NumOrden}</span>
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Equipo */}
                <div className="col-7">
                  <strong>{t("RequestPartsView.equip")}</strong>
                </div>
                <div className="col-61-58">
                  <Link className="link-tag" to={`/equipments/equipment-detail/${solState?.CodEquipo}`}>{solState?.Equipo}</Link>
                </div>
                {/* Contrato */}
                <div className="col-10">
                  <strong>{t("RequestPartsView.contr")}</strong>
                </div>
                <div className="col-7">
                  {
                    (solState?.FecEOL) && (<span>Equipo EOL</span>)
                  }
                  <span style={{ color: solState?.IdTipContrato === 0 ? "#ff0000" : "" }}>{solState?.NomContract}</span>
                  {/* <span>{solRep && solRep[0]?.contract}</span>
                  {solRep && solRep[0]?.fecEOL !== null && (<strong className="required"> {language === "en" ? "Equipment EOL" : "Equipo EOL"}</strong>)} */}
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Ingeniero tecnico */}
                <div className="col-7">
                  <strong>{t("RequestPartsView.ing_tec")}</strong>
                </div>
                <div className="col-39-29">
                  <span>{solState?.NomEmpleado}</span>
                </div>
                {/* Falla */}
                <div className="col-10">
                  <strong>{t("RequestPartsView.falla")}</strong>
                </div>
                <div className="col-36-58">
                  <span style={{ textDecoration: 'underline' }} className="required">{solState?.Falla}</span>
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Ultima acción */}
                <div className="col-7">
                  <strong>{t("RequestPartsView.ult_accion")}</strong>
                </div>
                <div className="col-83-87">
                  <span style={{ textDecoration: 'underline' }} className="active-class">{solState?.UltComentario}</span>
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Tipo de aprobación */}
                <div className="col-7">
                  <strong>Tipo de aprobación</strong>
                </div>
                <div className="col-4">
                  <select
                    name="tipAproba"
                    value={form.tipApro}
                    onChange={({ target }) => handleChangeForm("tipApro", target.value)}>
                    <option value=""></option>
                    <option value="0">Stock</option>
                    <option value="3">Designado</option>
                    <option value="6">Garantía ventas</option>
                    <option value="9">Exportaciones</option>
                    <option value="1">Activo fijo</option>
                    <option value="4">FMI/STB</option>
                    <option value="7">Garantía soporte</option>
                    <option value="10">Compra local</option>
                    <option value="2">Contrato</option>
                    <option value="5">Missing</option>
                    <option value="8">Gasto</option>
                  </select>
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex m-y-small">
                {/* Seleccionar acción */}
                <div className="col-7">
                  <strong>Seleccionar acción</strong>
                </div>
                <div className="col-4">
                  <select
                    name="action"
                    value={form.action}
                    onChange={({ target }) => handleChangeForm("action", target.value)}>
                    <option value="0"></option>
                    <option value="1">Cotización</option>
                    <option value="2">Nota de entrega</option>
                    <option value="3">Orden de compra</option>
                    <option value="4">Solicitud de precios</option>
                  </select>
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                <div className="col-7">
                  <strong>Comentario</strong>
                </div>
                <div className="col-83-87">
                  <textarea
                    className="textarea-description"
                    name="comment"
                    value={form.comment}
                    onChange={({ target }) => handleChangeForm("comment", target.value)}
                    placeholder={t("RequestPartsView.si_tiene_comen")} />
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Workflow */}
                <div className="col-7">
                  <strong>Workflow</strong>
                </div>
                <div className="col-4">
                  <input
                    className="input-text-control"
                    type="text"
                    name="workflow"
                    value={form.workflow}
                    onChange={({ target }) => handleChangeForm("workflow", target.value)} />
                </div>
              </div>

              {/* TABLA PARA MOSTRAR LAS PARTES */}
              <div className="table-container-mobile m-y-medium">
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th>#</th>
                      <th>{t("TableParts.canti")}</th>
                      <th>{t("TableParts.parte")}</th>
                      <th>{t("TableParts.descrip")}</th>
                      <th>{t("TableParts.precio")}</th>
                      <th>{t("TableParts.devolver")}</th>
                      <th>{t("TableParts.coment")}</th>
                      <th>{t("TableParts.aprobar")}</th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {
                      partes && partes.map((part, index) => (
                        <tr key={part.CodDetSolRepuesto}>
                          <td className="txt-center">{index + 1}</td>
                          <td className="txt-center">{part.Cantidad}</td>
                          <td><Link className="link-tag" to={`/part-detail/${part.CodDetSolRepuesto}/${part.CodSolRepuesto}`}>{part.NumParte}</Link></td>
                          <td>{part.Descripcion}</td>
                          <td style={{ color: 'var(--money-color)', fontWeight: 'bold' }} className="col-10 txt-center">{formatCurrency(part.Lista, 'USD')}</td>
                          <td>
                            <select
                              name={`devolver-${part.CodDetSolRepuesto}`}
                              value={part.Devolver}
                              onChange={({ target }) => handleChangeValParts(part.CodSolRepuesto, part.CodDetSolRepuesto, target.value, 1)}>
                              <option value="1">{t("RequestPartsView.si")}</option>
                              <option value="0">No</option>
                            </select>
                          </td>
                          <td className="col-4">{part.UltComentario}</td>
                          <td className="col-10 txt-center">
                            <input
                              className="input-control-checkbox"
                              type="checkbox"
                              name={`aprovv-${part.CodDetSolRepuesto}`}
                              checked={part.Aprobar}
                              onChange={({ target }) => handleChangeValParts(part.CodSolRepuesto, part.CodDetSolRepuesto, target.checked)} />
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>

              {/* Botones de acción */}
              <div className="row-flex m-y-medium j-content-space-around">
                <button type="button" className="btn-success" onClick={handleComment}>Comentar</button>
                <button type="button" className="btn-primary" onClick={handleSaveInfo}>Guardar</button>
                <button type="button" className="btn-warning" onClick={handleReturnRequest}>Rechazar</button>
                <button type="button" className="btn-danger">Cerrar</button>
                <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>Atrás</button>
              </div>

              {/* Modal para confirmar si está seguro de que quiere enviar el formulario */}
              {
                state.showModal && (<ModalConfirm onClose={handleCloseOpenModal} question={state.question} sending={state.sending} />
                )
              }
            </form>
          </div>
        )
      }
    </>
  );
};