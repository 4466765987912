// componente para seleccionar la temperatura

import { useState, useEffect } from "react";


// global function import
import { ColorTemper } from "../../global-functions/globalFunctions.js";

// icons importation
import { CheckMark } from "../icons/AppIcons.jsx";


export const TemperSelect = ({ updateParent, value = null }) => {

  const [arrColor, setArrColor] = useState(ColorTemper);

  // funcion para seleccionar el color
  const handleSelectColor = (newVal = "") => {
    updateParent(newVal);
    setArrColor((val) => {
      return val.map((col) => {
        if (newVal === col.value) {
          return { ...col, selected: true }
        }
        return { ...col, selected: false }
      });
    });
  };


  // useEffect por si hay valor por las props
  useEffect(() => {

    if (value) {
      setArrColor((val) => {
        return val.map((col) => {
          if (value === col.value) {
            return { ...col, selected: true }
          }
          return { ...col, selected: false }
        });
      });
    }

  }, [value]);



  return (
    <div className="box-temperature">
      <ul className="list-color-picker">
        {
          arrColor.map((col) => (
            <li key={col.value} style={{ backgroundColor: col.color }} onClick={() => handleSelectColor(col.value)}>
              {
                col.selected && (<CheckMark fillP={col.fillP} heightP="20" widthP="20" />)
              }
            </li>
          ))
        }
      </ul>
    </div>
  );
};