
import React, { useContext } from "react";

/*
--- Este componente es para crear la solicitudes de partes u otra solicitudes,
--- solamente para esto, cuando se guarda luego se redirige a la url /request-part-detail/:codSolR 
*/

// COMPONENTS IMPORTATIONS
import { LoadingComp } from "../../components/Loading-comp/LoadingComp.jsx";
import { Error500Comp } from "../../components/errors/Error500Comp.jsx";
import { TemperSelect } from "../../components/serviceComponents/TemperSelect.jsx";

// REACT ROUTER DOM IMPORTATION
import { Link, useNavigate, useParams } from "react-router-dom";

// URL Importation
import { urls } from "../../api-urls/api-urls.js";

// REACT TRANSLATION
import { useTranslation } from "react-i18next";

// GLOBAL FUNCTION IMPORTATION
import { getTheToken, renderDate } from "../../global-functions/globalFunctions.js";


// context imports
import { LangContext } from "../../context/LangContext.jsx";
import { NotificationContext } from "../../context/NotificationContext.jsx";


// custom hook
import { useFetchReqParts } from "../../components/customs/service/useFetchReqParts.js";





export const RequestPartsView = () => {

  // language context
  const { lang } = useContext(LangContext);

  // notification context
  const { handleNotify } = useContext(NotificationContext);

  // useNavigate
  const navigate = useNavigate();

  // get the params
  const { idCall } = useParams();

  // custom hook import
  const { state, form, solState, handleChangeFormVal, handleSendServ, handleChangeTemp } = useFetchReqParts(idCall, lang, getTheToken().token, urls);

  // GET the react translation
  const [t] = useTranslation("global");


  // Funcion para crear la solicitud
  const onCreateRequest = async (event) => {
    event.preventDefault();

    // Validar que se hayan seleccionado los campos
    if (!form.tipSol) {
      return handleNotify(true, t("RequestPartsView.selec_tip_sol"), true);
    }

    try {
      // Enviar datos al formilario
      handleSendServ(true);
      const response = await fetch(`${urls.requestDetail}/${idCall}/search?initData=no&language=${lang}`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-type": "application/json"
        },
        body: JSON.stringify(form)
      });

      const data = await response.json();

      // Verificar el estatus de la respuesta del servidor
      if (response.status === 201) {
        // se creó la solicitud y debe ser redireccionado
        handleNotify(true, data?.msg, false, true);
        navigate(`/request-part-detail/${data?.codSolRep}`);
      } else {
        handleNotify(true, data?.msg, true);
      }

      handleSendServ();

    } catch (error) {
      console.log(error)
    }
  };



  return (
    <>
      {/* Server error components */}
      {state.serverError && (<Error500Comp />)}
      {/* Loading component */}
      {state.isLoading && (<LoadingComp />)}
      {
        state.dataVisible && (
          <div className="container display-data">
            <form onSubmit={onCreateRequest}>
              {/* FILA 1 */}
              <div className="row-flex">
                {/* SOLICITUD */}
                <div className="col-8 col-sm-20">
                  <strong>{t("RequestPartsView.soli")}</strong>
                </div>
                <div className="col-4 col-sm-30">
                  <h3></h3>
                </div>
                {/* ID SERVICIO */}
                <div className="col-10 col-sm-20">
                  <strong>{t("RequestPartsView.id_serv")}</strong>
                </div>
                <div className="col-8 col-sm-30">
                  <Link className="link-tag" to={`/service/service-call/${idCall}`}>{idCall}</Link>
                </div>
                {/* MODELO */}
                <div className="col-10 col-sm-20">
                  <strong>{t("RequestPartsView.modelo")}</strong>
                </div>
                <div className="col-4 col-sm-30">
                  <span>{solState?.Modelo}</span>
                </div>
              </div>

              {/* FILA 2 */}
              <div className="row-flex">
                {/* FECHA */}
                <div className="col-8 col-sm-20">
                  <strong>{t("RequestPartsView.fecha")}</strong>
                </div>
                <div className="col-4 col-sm-30">
                  <span>{renderDate(solState?.Today, lang)}</span>
                </div>
                {/* INSTALACION */}
                <div className="col-10 col-sm-20">
                  <strong>{t("RequestPartsView.instala")}</strong>
                </div>
                <div className="col-8 col-sm-30">
                  <span></span>
                </div>
                {/* SERIAL */}
                <div className="col-10 col-sm-20">
                  <strong>Serial</strong>
                </div>
                <div className="col-4 col-sm-30">
                  <span>{solState?.Serial}</span>
                </div>
              </div>

              {/* FILA 3 */}
              <div className="row-flex">
                {/* SUPLIDOR */}
                <div className="col-8 col-sm-20">
                  <strong>{t("RequestPartsView.supli")}</strong>
                </div>
                <div className="col-4 col-sm-80">
                  <span></span>
                </div>
              </div>

              {/* FILA 4 */}
              <div className="row-flex">
                {/* TIPO DE SOLICITUD */}
                <div className="col-8 col-sm-20">
                  <strong>{t("TipSol.tip_sol")}</strong><strong className="required">(*)</strong>
                </div>
                <div className="col-4 col-sm-30">
                  <select
                    name="tipoSolicitud"
                    value={form.tipSol}
                    onChange={({ target }) => handleChangeFormVal("tipSol", target.value)}>
                    <option value=""></option>
                    <option value="0">{t("TipSol.coti")}</option>
                    <option value="1">{t("TipSol.garan")}</option>
                    <option value="2">{t("TipSol.contra_perfo")}</option>
                    <option value="3">Missing in Shipment</option>
                    <option value="4">Dead on Arrival</option>
                    <option value="5">{t("TipSol.ord_compr")}</option>
                  </select>
                </div>
                {/* GON */}
                <div className="col-10 col-sm-20">
                  <strong>GON</strong>
                </div>
                <div className="col-8 col-sm-30">
                  <span>{solState?.FDO}</span>
                </div>
                {/* O/C */}
                <div className="col-10 m-left-auto-comp-serv col-sm-20">
                  <strong>O/C</strong>
                </div>
                <div className="col-4 col-sm-80">
                  <span>{solState?.NumOrden}</span>
                </div>
              </div>

              {/* FILA 5 */}
              <div className="row-flex">
                {/* CLIENTE */}
                <div className="col-8 col-sm-20">
                  <strong>{t("RequestPartsView.cliente")}</strong>
                </div>
                <div className="col-46-7 col-sm-80 text-hidden-over">
                  <Link className="link-tag" to={`/customers/customer-detail/${solState?.CodCliente}`}>{solState?.NomCliente}</Link>
                </div>
                {/* PRIORIDAD */}
                <div className="col-10 m-left-auto-comp-serv col-sm-20">
                  <strong>{t("RequestPartsView.prio")}</strong>
                </div>
                <div className="col-4 col-sm-80">
                  <TemperSelect updateParent={handleChangeTemp} value={form.priority} />
                </div>
              </div>

              {/* FILA 6 */}
              <div className="row-flex">
                {/* EQUIPO */}
                <div className="col-8 col-sm-20">
                  <strong>{t("RequestPartsView.equip")}</strong>
                </div>
                <div className="col-46-7 col-sm-80">
                  <Link className="link-tag" to={`/equipments/equipment-detail/${solState?.CodEquipo}`}>{solState?.Equipo}</Link>
                </div>
                {/* CONTRATO */}
                <div className="col-10 m-left-auto-comp-serv col-sm-20">
                  <strong>{t("RequestPartsView.contr")}</strong>
                </div>
                <div className="col-4 col-sm-80">
                  <span>{solState?.NomContract}</span>
                  {
                    solState?.FecEOL !== null && <strong className="required">{t("RequestPartsView.equip_eol")}</strong>
                  }
                </div>
              </div>

              {/* FILA 7 */}
              <div className="row-flex">
                {/* INGENIERO TECNICO */}
                <div className="col-8 col-sm-20">
                  <strong>{t("RequestPartsView.ing_tec")}</strong>
                </div>
                <div className="col-4 col-sm-80">
                  <span>{solState?.NomEmpleado}</span>
                </div>
                {/* CARPETA */}
                <div className="col-10 col-sm-20">
                  <strong>{t("RequestPartsView.carp")}</strong>
                </div>
                <div className="col-8 col-sm-80">
                  <span></span>
                </div>
                {/* FALLA */}
                <div className="col-10 m-left-auto-comp-serv col-sm-20">
                  <strong>{t("RequestPartsView.falla")}</strong>
                </div>
                <div className="col-4 col-sm-80">
                  <textarea
                    className="textarea-description"
                    name="falla"
                    value={solState?.Problema}
                    readOnly />
                </div>
              </div>

              {/* FILA 8 */}
              <div className="row-flex">
                {/* ETAPA */}
                <div className="col-8 col-sm-20">
                  <strong>{t("RequestPartsView.eta")}</strong>
                </div>
                <div className="col-4 col-sm-80">
                  <span>{t("RequestPartsView.sol_rep_eta")}</span>
                </div>
                <div className="col-10 m-left-auto-comp-serv col-sm-20">
                  <strong>{t("RequestPartsView.obser")}</strong>
                </div>
                <div className="col-4 col-sm-80">
                  <textarea
                    className="textarea-description"
                    name="observacion"
                    value={form.observ}
                    onChange={({ target }) => handleChangeFormVal("observ", target.value)}
                    placeholder={t("RequestPartsView.obser_place_hold")} />
                </div>
              </div>

              {/* BOTONES DE ACCION */}
              <div className="row-flex j-content-space-around m-y-small">
                <button type={state.sending ? "button" : "submit"} className={`btn-primary ${state.sending ? "disabled" : ""}`}>{t("RequestPartsView.guar")}</button>
                <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>{t("navigationMenu.backBtn")}</button>
              </div>

            </form>
          </div>
        )
      }
    </>
  );
};