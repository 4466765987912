import React, { useEffect, useContext } from "react";

// Components importation
import { LoadingComp } from "../../components/Loading-comp/LoadingComp.jsx";
import { ToastNotification } from "../../components/toast-notification/ToastNotification.jsx";
import { Error403View } from "../error-403-view/Error403View.jsx";
import { ModalConfirm } from "../../components/modal-confirm/ModalConfirm.jsx";
import { Folder } from "../../components/icons/AppIcons.jsx";
import { UploadFiles } from "../../components/uploads/UploadFiles.jsx";


// React router dom importation
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

// Url importation
import { urls } from "../../api-urls/api-urls.js";

// Global functions importation
import { getTheToken, renderDate } from "../../global-functions/globalFunctions.js";

// useTranslation
import { useTranslation } from "react-i18next";


// custom hook import
import { useFetchAproSoporte } from "../../components/customs/warehouse/useFetchAproSoporte.js";


// lang context import
import { LangContext } from "../../context/LangContext.jsx";





export const AproSoporteDetail = () => {

  // GET the react translation
  const [t] = useTranslation("global");

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the useParams
  const { codSolRep } = useParams();

  // GET the language context
  const { lang } = useContext(LangContext);

  // custom hook
  const { state, form, notify, partes, solState, empPerm, handleChangeForm, handleSendModal, handleReturnModal, handleUpdateData, handleOpenCloseModal, handleOpenCloseFilesModal, handleClearForm, handleNotify } = useFetchAproSoporte(codSolRep, getTheToken().token, urls, lang);

  const END_POINT_FILE = `${urls.supportApproval}/${codSolRep}/search?language=${lang}`;



  // Manejo del submit del formulario
  const onSendRequest = async (event) => {
    event.preventDefault();

    const finalObj = { form, partes };

    try {
      handleUpdateData(true);
      const response = await fetch(`${urls.supportApproval}/${codSolRep}/search?language=${lang}`, {
        method: "PUT",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(finalObj)
      });

      console.clear();

      const data = await response.json();

      if (response.status === 200) {
        handleNotify(true, data?.msg, false, true, true);
        handleClearForm();
        handleOpenCloseModal();
      } else if ([404, 403].includes(response.status)) {
        handleOpenCloseModal();
        handleNotify(true, data?.msg, true);
      }

      handleUpdateData(false);

    } catch (error) {
      console.log(error);
    }
  };



  // useEffect para limpiar las notificaciones
  useEffect(() => {
    const timer = setTimeout(() => {
      handleNotify(false, "", false, false, false);
    }, 6000);

    return () => {
      clearTimeout(timer);
    }
  }, [notify.show]);


  // useEffect para redireccionar a la ventana principal de servicio
  useEffect(() => {
    if (notify.succes) {
      const timer = setTimeout(() => {
        // redireccionar a principal servicio
        navigate("/my-tasks/service");
      }, 5000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [notify.succes]);


  return (
    <>
      {
        state.unauthorized && (
          <Error403View />
        )
      }
      {
        // notification component
        notify.show && (<ToastNotification success={notify.great} error={notify.error} msg={notify.msg} />)}
      {
        // loading component
        state.isLoading && (<LoadingComp />)
      }
      {
        state.dataVisible && (
          <div className="container display-data">

            <form onSubmit={onSendRequest}>
              {/* FILA */}
              <div className="row-flex">
                {/* Solicitud */}
                <div className="col-7 col-sm-20">
                  <strong>{t("RequestPartsView.soli")}</strong>
                </div>
                <div className="col-7 col-sm-8">
                  <h4>{codSolRep}</h4>
                </div>
                {/* ID Servicio */}
                <div className="col-7 col-sm-20">
                  <strong>{t("RequestPartsView.id_serv")}</strong>
                </div>
                <div className="col-7 col-sm-35">
                  <Link className="link-tag" to={`/service/service-call/${solState?.CodServicio}`}>{solState?.CodServicio}</Link>
                </div>
                {/* Modelo */}
                <div className="col-7 col-sm-20">
                  <strong>{t("RequestPartsView.modelo")}</strong>
                </div>
                <div className="col-4 col-sm-45">
                  <span>{solState?.Modelo}</span>
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Serial */}
                <div className="col-7 col-sm-20">
                  <strong>{t("RequestPartsView.fecha")}</strong>
                </div>
                <div className="col-7 col-sm-80">
                  <span>{renderDate(solState?.FecIngSol, lang)}</span>
                </div>
                {/* Serial */}
                <div className="col-7 col-sm-20">
                  <strong>Serial</strong>
                </div>
                <div className="col-7 col-sm-80">
                  <span>{solState?.Serial}</span>
                </div>

                {/* Suplidor */}
                <div className="col-7 col-sm-20">
                  <strong>{t("RequestPartsView.supli")}</strong>
                </div>
                <div className="col-4 col-sm-80 text-hidden-over">
                  <span>{solState?.Suplidor}</span>
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Cliente */}
                <div className="col-7 col-sm-20">
                  <strong>{t("RequestPartsView.cliente")}</strong>
                </div>
                <div className="col-48-87 col-sm-80 text-hidden-over">
                  <Link className="link-tag" to={`/customers/customer-detail/${solState?.CodCliente}`}>{solState?.NomCliente}</Link>
                </div>
                {/* FDO */}
                <div className="col-7 col-sm-20">
                  <strong>FDO</strong>
                </div>
                <div className="col-4 col-sm-80">
                  <span></span>
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Equipo */}
                <div className="col-7 col-sm-20">
                  <strong>{t("RequestPartsView.equip")}</strong>
                </div>
                <div className="col-48-87 col-sm-80">
                  <Link className="link-tag" to={`/equipments/equipment-detail/${solState?.CodEquipo}`}>{solState?.Equipo}</Link>
                </div>
                {/* OC */}
                <div className="col-7 col-sm-20">
                  <strong>O/C</strong>
                </div>
                <div className="col-4 col-sm-80">
                  <span></span>
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Tecnico */}
                <div className="col-7 col-sm-20">
                  <strong>{t("RequestPartsView.ing_tec")}</strong>
                </div>
                <div className="col-28-58 col-sm-80">
                  <span>{solState?.NomEmpleado}</span>
                </div>
                {/* Contrato */}
                <div className="col-7 col-sm-20">
                  <strong>{t("RequestPartsView.contr")}</strong>
                </div>
                <div className="col-7 col-sm-80">
                  <span>{solState?.NomContract}</span>
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Falla */}
                <div className="col-7 col-sm-20">
                  <strong>{t("RequestPartsView.falla")}</strong>
                </div>
                <div className="col-28-58 col-sm-80">
                  <span style={{ color: 'red', textDecoration: 'underline' }}>{solState?.Falla}</span>
                </div>
                {/* Observacion */}
                <div className="col-7 col-sm-20">
                  <strong>{t("RequestPartsView.obser")}</strong>
                </div>
                <div className="col-39-29 col-sm-80">
                  <span>{solState?.ObsSolicitud}</span>
                </div>
              </div>

              {/* FILA */}
              {
                empPerm && (empPerm[0]?.CodEmpleado === state.empSolicita || empPerm[0]?.CodPerfil === 6) && (
                  <div className="row-flex">
                    <div className="col-7 col-sm-20">
                      <strong>Cargar aprobación</strong>
                    </div>
                    <div className="col-10 col-70-sm">
                      <button type="button" className="btn-docs" onClick={handleOpenCloseFilesModal}>
                        <Folder />
                      </button>
                    </div>
                    <div className="col-7">
                      {
                        solState?.Soporte && (<strong className="active-class">Archivo cargado</strong>)
                      }
                    </div>
                  </div>
                )
              }


              {/* FILA */}
              <div className="row-flex">
                {/* Comentario */}
                <div className="col-7 col-sm-20">
                  <strong>{t("RequestPartsView.comentario")}</strong>
                </div>
                <div className="col-82-16 col-sm-80 flex-grow">
                  <textarea
                    className="textarea-description"
                    name="comentario"
                    value={form.comment}
                    onChange={({ target }) => handleChangeForm("comment", target.value)}
                    placeholder={t("RequestPartsView.si_tiene_comen")} />
                </div>
              </div>

              {/* TABLA PARA MOSTRAR LAS PARTES */}
              <div className="table-container-mobile m-y-small">
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th>#</th>
                      <th>{t("TableParts.canti")}</th>
                      <th>{t("TableParts.parte")}</th>
                      <th>{t("TableParts.descrip")}</th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {
                      partes && partes.map((part, index) => (
                        <tr key={part.CodDetSolRepuesto}>
                          <td style={{ textAlign: 'center' }}>{index + 1}</td>
                          <td style={{ textAlign: 'center' }}>{part.Cantidad}</td>
                          <td>
                            <Link className="link-tag" to={`/part-detail/${part.CodDetSolRepuesto}/${part.CodSolRepuesto}`}>{part.NumParte}</Link>
                          </td>
                          <td>{part.Descripcion}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>

              {/* Botones de accion */}
              <div className="row-flex m-y-medium j-content-space-around">
                {
                  empPerm && (empPerm[0]?.CodEmpleado === state.empSolicita || empPerm[0]?.CodPerfil === 6) && (
                    <>
                      <button type="button" className="btn-primary" onClick={handleSendModal}>{t("RequestPartsView.enviar")}</button>
                      <button type="button" className="btn-warning" onClick={handleReturnModal}>{t("RequestPartsView.devol")}</button>
                    </>
                  )
                }
                <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>{t("navigationMenu.backBtn")}</button>
              </div>

              {/* Modal para confirmar si está seguro de que quiere enviar el formulario */}
              {
                state.showModal && (
                  <ModalConfirm onClose={handleOpenCloseModal} question={state.question} sending={state.sending} />
                )
              }
            </form>

            {
              // Modal para subir los documentos
              state.fileModal && (
                <UploadFiles
                  onClose={handleOpenCloseFilesModal}
                  END_POINT={END_POINT_FILE}
                  Method="POST"
                  token={getTheToken().token}
                  FileAccept=".pdf" />
              )
            }
          </div>
        )
      }
    </>
  );
};